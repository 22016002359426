/**
 * Helper functions for handling location and domain-related operations
 */

import { RequestHelper } from '.';
import { AffiliateMapper } from '../utils/AffiliateMapper';

/**
 * Interface defining location-related helper functions
 */
interface LocationHelper {
    /**
     * Gets the host identifier from the current URL
     * Priority order:
     * 1. URL theme parameter
     * 2. URL aid (affiliate ID) parameter
     * 3. Subdomain (e.g., "carcraft" from carcraft.carfinance.co.uk)
     * 
     * Handles special domain suffixes:
     * - -form (e.g., carcraft-form.carfinance.co.uk)
     * - -exp (e.g., carcraft-exp.carfinance.co.uk)
     * - -rc (e.g., carcraft-rc.carfinance.co.uk)
     * - -qa (e.g., carcraft-qa.carfinance.co.uk)
     * 
     * @returns {string} The host identifier
     */
    getHost(): string;

    /**
     * Gets the subdomain from the current URL
     * @returns {string} The subdomain (e.g., "carcraft" from carcraft.carfinance.co.uk)
     */
    getSubdomain(): string;

    isLocalHost(): boolean;

    isCompareTheMarketHost(): boolean;

    isSocialHost(): boolean;
}

export type LocationHelperType = LocationHelper;

const locationHelper: LocationHelper = {
    getHost(): string {
        const domainName: string = location.host;
        let host: string = domainName.split('.')[0];

        // Check for special domain suffixes
        if(domainName.includes('-form.'))
            host = domainName.split('.')[0].replace('-form', '');

        if(domainName.includes('-exp.'))
            host = domainName.split('.')[0].replace('-exp', '');

        if(domainName.includes('-rc.'))
            host = domainName.split('.')[0].replace('-rc', '');

        if(domainName.includes('-qa.'))
            host = domainName.split('.')[0].replace('-qa', '');

        // Check for theme or aid in URL
        const aid = RequestHelper.getQueryVariable('aid');
        const theme = RequestHelper.getQueryVariable('theme');

        if (theme) {
            const config = AffiliateMapper.getAffiliateConfig(theme);
            if (config && config.systemName) {
                host = config.systemName;
            }
        } else if (aid) {
            const config = AffiliateMapper.getAffiliateConfig(aid);
            if (config && config.systemName) {
                host = config.systemName;
            }
        } else {
            // If no URL parameters, check if the subdomain matches an affiliate config
            host =  this.isLocalHost() ? 'default' : host;

            const config = AffiliateMapper.getAffiliateConfig(host);
            if (config && config.systemName) {
                host = config.systemName;
            }
        }

        if (window.location.href.indexOf('carfinance.comparethemarket.com') > -1) {
            host = 'comparethemarket';
        }

        if (window.location.href.indexOf('finance.confused.com') > -1) {
            host = 'confused';
        }
        
        return host;
    },

    getSubdomain(): string {
        const domainName: string = location.host;
        const subdomain: string = domainName.split('.')[0];
        return subdomain;
    },
    isLocalHost(): boolean {
        const host = window.location.hostname;
        return (
          host.includes('localhost') ||
          host.includes('127.0.0.1') ||
          /^10\./.test(host) ||
          /^172\.(1[6-9]|2[0-9]|3[0-1])\./.test(host) ||
          /^192\.168\./.test(host) ||
          window.location.href.indexOf('.azurestaticapps.net') > -1 ||
          window.location.href.indexOf('.azurewebsites.net') > -1 ||
          ['apply', 'new-apply'].includes(window.location.hostname.split('.')[0])
        );
    },

    isCompareTheMarketHost(): boolean {
        const host = this.getHost();
        return host.startsWith('comparethemarket') || 
               host.startsWith('comparethemarketcarfinance');
    },

    isSocialHost(): boolean {
        const socialHosts = [
            'social',
            'ecrm',
            'tiktok',
            'social-premier',
            'social-vans',
            'social-bad-credit'
        ];
        return socialHosts.includes(this.getHost());
    }
};

export default locationHelper;