<template>
  <div class="w-full flex justify-center max-md:px-5 mt-5 mb-4"
    :class="isDefaultTheme ? 'mt-5' : 'mt-0'"
  >
    <div class="w-full">
      <div class="h-1.5 bg-gray-200">
        <div
          class="h-1.5 transition-all duration-300 ease-in-out"
          :class="secondaryColorClass"
          :style="{ width: progress + '%' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useConfigStore } from '@/stores/modules/configStore';

const configStore = useConfigStore();
const isDefaultTheme = computed(() => !configStore.isDefaultTheme);

const secondaryColorClass = computed(() => {
  const div = document.createElement('div');
  div.className = 'bg-secondary';
  document.body.appendChild(div);
  const computedColor = getComputedStyle(div).backgroundColor;
  document.body.removeChild(div);
  
  // Check if the computed color is white (rgb(255, 255, 255))
  return computedColor === 'rgb(255, 255, 255)' ? 'bg-primary' : 'bg-secondary';
});

defineProps<{
  progress: number
}>();
</script>

