/**
 * @fileoverview Theme store module for managing application theming
 * Handles theme detection from URL parameters and application-wide theme state
 */

import { defineStore } from 'pinia'
import type { ThemeState } from '@/types/themeTypes';
import { AffiliateMapper } from '@/utils/AffiliateMapper'

/**
 * Pinia store for theme management
 * Provides centralized state management for application theming
 * and handles theme detection from URL parameters
 * 
 * @store theme
 */
export const useThemeStore = defineStore('theme', {
  /**
   * Initial state of the theme store
   * @returns {ThemeState} The theme store's initial state with null theme
   */
  state: (): ThemeState => ({
    currentTheme: null
  }),
  
  actions: {
    /**
     * Initializes theme from URL parameters and applies it to document root
     * Detects theme parameter from URL and updates application-wide theme
     * 
     * @throws {Error} When URL parameter parsing fails
     */
    initializeTheme() {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const theme = AffiliateMapper.getThemeFromUrl(urlParams)
        
        if (theme) {
            console.log(`✅ Theme found: "${theme}"`);
            this.currentTheme = theme
            document.documentElement.setAttribute('data-theme', theme)
        } else {
            console.log('ℹ️ No theme parameter found in URL');
        }
        
      } catch (error) {
        console.error('Theme Detection Error:', error)
      }
    }
  }
}) 