export type RequestHelperType = {
    parseQueryParams(queryString: string | null | undefined, variable: string): string | null;
    getQueryVariable(variable: string): string | null;
    getQueryVariableFromUrl(url: string, variable: string): string | null;
};

export default {
    /**
     * Parses a query string and returns the value for a specific parameter
     * @param queryString The query string to parse (without the leading '?')
     * @param variable The parameter name to search for
     * @returns The parameter value or null if not found
     */
    parseQueryParams(queryString: string | null | undefined, variable: string): string | null {
        if (!queryString || !variable) return null;
        
        try {
            const params = new URLSearchParams(queryString);
            return params.get(variable);
        } catch (error) {
            console.warn('Error parsing query string:', error);
            return null;
        }
    },

    /**
     * Gets a query parameter from the current window location
     */
    getQueryVariable(names: string | string[]): string | null {
        const nameArray = Array.isArray(names) ? names : [names];
        for (const name of nameArray) {
            const value = this.parseQueryParams(window.location.search.substring(1), name);
            if (value) return value;
        }
        return null;
    },

    /**
     * Gets a query parameter from a provided URL string
     */
    getQueryVariableFromUrl(url: string, variable: string): string | null {
        try {
            const urlObject = new URL(url);
            return this.parseQueryParams(urlObject.searchParams.toString(), variable);
        } catch (error) {
            console.warn('Invalid URL provided:', error);
            return null;
        }
    }
} as const;