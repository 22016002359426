import type { PiniaPluginContext } from 'pinia';
import { RegExHelper } from "@/helpers";
import { useApplicationStore } from '@/stores/modules/applicationStore';

let timeOutId: number | null = null;

export function partialSubmissionPlugin({ store }: PiniaPluginContext) {
  store.$onAction(({
    name,
    after,
    onError,
    args
  }) => {
    
    after(() => {
        if ( name === 'updateFormValue' ) {
            const { firstName, lastName, email, contactNumber } = store.$state;
            const isEmailValid = RegExHelper.checkValidEmail(email);
            const isPhoneValid = RegExHelper.checkPhoneNumberFormat(contactNumber);
    
            const formArg = args[0];
            if (firstName && lastName && 
                (isEmailValid || isPhoneValid) &&
                formArg[1] !== 'dateOfBirth' && 
                formArg[1] !== undefined
            ) {
                if (timeOutId) {
                    clearTimeout(timeOutId);
                }
                timeOutId = setTimeout(() => {
                    const applicationStore = useApplicationStore();
                    if (!applicationStore.cancelPartialSubmission) {
                        applicationStore.submitPartialApplication();
                    }
                    timeOutId = null;
                }, 1000);
            }
        }
    });
  });
} 