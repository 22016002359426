/**
 * @fileoverview Device store module for managing device-specific information
 * Handles client IP address, user agent details, and device context tracking
 */

import { defineStore } from 'pinia';
import { useQuery } from '@tanstack/vue-query';
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import type { DeviceState } from '@/types/deviceTypes';
import urlConfig from '@/factories/urls-factory';
import { useConfigStore } from '@/stores/modules/configStore';

/**
 * Pinia store for device information management
 * Provides centralized state management for device-specific data
 * and handles API communication for IP address retrieval
 * 
 * @store device
 */
export const useDeviceStore = defineStore('device', {
  /**
   * Initial state of the device store
   * @returns {DeviceState} The device store's initial state with null values
   */
  state: (): DeviceState => ({
    remoteAddress: null,
    userAgent: null,
    deviceContextId: null
  }),

  actions: {
    /**
     * Retrieves and sets device information including IP address and user agent
     * Generates a unique device context ID for session tracking
     * 
     * @throws {Error} When API request fails
     */
    async setDeviceInfo() {
      try {
        const configStore = useConfigStore();
        const environment = configStore.isProduction ? 'production' : 'replica';

        const query = useQuery({
          queryKey: ['clientIp'],
          queryFn: async () => {
            try {
              const response = await fetch(`${urlConfig[environment].apiBaseUrl}/ip-address`);
              if (!response.ok) {
                // If request fails, return null instead of throwing
                return null;
              }
              return response.json();
            } catch (error) {
              // Handle network errors by returning null
              console.warn('Failed to fetch IP address:', error);
              return null;
            }
          }
        });

        await query.suspense();

        // Update to handle null case
        this.remoteAddress = query.data.value || 'unknown';
        this.userAgent = navigator.userAgent;
        this.deviceContextId = `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: string) =>
          (Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4).toString(16)
        );
      } catch (error) {
        handleStoreError('device', 'setDeviceInfo', error, StoreErrorType.NETWORK);
      }
    }
  }
}); 