/**
 * Store Persistence Utilities
 * 
 * This module handles the persistence of store states to browser storage:
 * - Form state is persisted in localStorage (survives browser restarts)
 * 
 * @module storePersistence
 */

import { watch } from 'vue';
import type { WatchStopHandle } from 'vue';
import { useRootStore } from '@/stores/rootStore';
import { handleStoreError } from '@/utils/storeErrorHandling';

/**
 * Constants for storage keys used in local and session storage
 */
const STORAGE_KEYS = {
  FORM: 'form-state',
  PROGRESS: 'progress-state',
  ADDRESS: 'address-state',
} as const;

/**
 * Helper function to safely execute storage operations with error handling
 * @template T The expected return type of the operation
 * @param {() => T} operation - The storage operation to execute
 * @param {string} errorContext - Context description for error handling
 * @returns {T | null} The result of the operation or null if it fails
 */
function safeStorageOperation<T>(
  operation: () => T,
  errorContext: string
): T | null {
  try {
    return operation();
  } catch (error) {
    handleStoreError('persistence', errorContext, error);
    return null;
  }
}

/**
 * Validates that the stored state is a valid object
 * @param {unknown} state - The state to validate
 * @returns {boolean} True if the state is a valid object, false otherwise
 */
function isValidState(state: unknown): boolean {
  return state !== null && typeof state === 'object';
}

/**
 * Restores previously persisted state from storage into the stores
 * Form state is restored from localStorage
 */
export function restorePersistedState() {
  const { form, progress, address } = useRootStore();
  
  // Restore form state from localStorage
  const savedFormState = safeStorageOperation(
    () => JSON.parse(localStorage.getItem(STORAGE_KEYS.FORM) || 'null'),
    'form-state-restoration'
  );
  
  if (savedFormState && isValidState(savedFormState)) {
    form.$state = savedFormState;
  }

  // Restore progress state from localStorage
  const savedProgressState = safeStorageOperation(
    () => JSON.parse(localStorage.getItem(STORAGE_KEYS.PROGRESS) || 'null'),
    'progress-state-restoration'
  );

  if (savedProgressState && isValidState(savedProgressState)) {
    progress.$state = savedProgressState;
  }
  
  // Restore progress state from localStorage
  const savedAddressState = safeStorageOperation(
    () => JSON.parse(localStorage.getItem(STORAGE_KEYS.ADDRESS) || 'null'),
    'address-state-restoration'
  );

  if (savedAddressState && isValidState(savedAddressState)) {
    address.$state = savedAddressState;
  }

}

/**
 * Sets up watchers to automatically persist store states to storage
 * - Form state is persisted to localStorage
 * 
 * @returns {() => void} A cleanup function that stops all watchers when called
 * 
 * @example
 * const cleanup = setupStorePersistence();
 * // ... later when cleaning up
 * cleanup();
 */
export function setupStorePersistence(): () => void {
  const { form, progress, address } = useRootStore();
  const watchers: WatchStopHandle[] = [];

  // Persist form state
  watchers.push(
    watch(
      () => form.$state,
      (state) => {
        safeStorageOperation(
          () => localStorage.setItem(STORAGE_KEYS.FORM, JSON.stringify(state)),
          'form-state-persistence'
        );
      },
      { deep: true }
    )
  );

  watchers.push(
    watch(
      () => progress.$state,
      (state) => {
        safeStorageOperation(
          () => localStorage.setItem(STORAGE_KEYS.PROGRESS, JSON.stringify(state)),
          'progress-state-persistence'
        );
      },
      { deep: true }
    )
  )

  watchers.push(
    watch(
      () => address.$state,
      (state) => {
        safeStorageOperation(
          () => localStorage.setItem(STORAGE_KEYS.ADDRESS, JSON.stringify(state)),
          'address-state-persistence'
        );
      },
      { deep: true }
    )
  )

  // Return cleanup function
  return () => watchers.forEach(stop => stop());
} 