import type { TrackData, Context, PersonalDetailsData } from '@/types/segmentTypes';
import type { PiniaPluginContext } from 'pinia';
import type { UTMParameters } from '@/types/utmTypes';
import { useProgressStore } from '@/stores/modules/progressStore';
import { useUTMStore } from '@/stores/modules/utmStore';
import { useFormStore } from '@/stores/modules/formStore';
import { RequestHelper } from '@/helpers';
import { phoneNormalised } from '@/helpers/phone-normalization-helper';
import { CookieHelper, StringHelper, HashHelper } from '@/helpers';
import { VehicleType as VEHICLE_TYPE } from '@/constants';
import { useConfigStore } from '@/stores/modules/configStore';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from "@sentry/vue";

declare global {
    interface Window {
        google_tag_manager?: { [key: string]: any };
        dataLayer?: any[];
        segmentLoaded?: boolean;
        analytics?: any;
    }
}

// Update GTM constants
const GTM_TIMEOUT_MS = 5000; // Reduced from 10000ms to 5000ms
const GTM_CONTAINER_ID = 'GTM-5DS545J';
const GTM_MAX_RETRIES = 2; // Reduced from 5 to 2
const GTM_RETRY_DELAY = 1000; // Reduced from 2000ms to 1000ms
const GTM_RETRY_BACKOFF = 1.2; // Reduced backoff factor from 1.5 to 1.2
const GTM_CONTAINER_CHECK_INTERVAL = 300; // Reduced from 500ms to 300ms
const GTM_CONTAINER_MAX_CHECKS = 10; // Reduced from 20 to 10

// Add GTM container check function with better error handling
const waitForGTMContainer = async (): Promise<boolean> => {
    // First quick check if GTM is already loaded
    if (typeof window.google_tag_manager !== 'undefined' && 
        typeof window.google_tag_manager[GTM_CONTAINER_ID] !== 'undefined' &&
        window.dataLayer) {
        console.log('✅ GTM container already available');
        return true;
    }

    return new Promise((resolve) => {
        let attempts = 0;
        let lastError: Error | null = null;

        const checkContainer = () => {
            try {
                if (typeof window.google_tag_manager === 'undefined') {
                    lastError = new Error('google_tag_manager not defined');
                    throw lastError;
                }

                if (typeof window.google_tag_manager[GTM_CONTAINER_ID] === 'undefined') {
                    lastError = new Error(`GTM container ${GTM_CONTAINER_ID} not found`);
                    throw lastError;
                }

                if (!window.dataLayer) {
                    lastError = new Error('dataLayer not initialized');
                    throw lastError;
                }

                console.log('✅ GTM container found and initialized');
                resolve(true);
                return;
            } catch (error) {
                lastError = error instanceof Error ? error : new Error(String(error));
            }

            attempts++;
            if (attempts >= GTM_CONTAINER_MAX_CHECKS) {
                console.warn('⚠️ GTM container not found after maximum attempts', {
                    lastError: lastError?.message,
                    attempts,
                    totalTime: attempts * GTM_CONTAINER_CHECK_INTERVAL
                });
                resolve(false);
                return;
            }

            setTimeout(checkContainer, GTM_CONTAINER_CHECK_INTERVAL);
        };

        checkContainer();
    });
};

/**
 * Checks if the Segment analytics library is loaded and available in the window object.
 * @returns Promise<boolean> Resolves to true when Segment is loaded, rejects after 60 seconds timeout
 */
const checkSegmentLoaded = (): Promise<boolean> => {
    console.log('🔍 Checking if Segment is loaded...');
    return new Promise((resolve, reject) => {
        let checkingInterval: ReturnType<typeof setInterval> | null = null;
        const TIMEOUT_MS = 60000;
        const CHECK_INTERVAL_MS = 500;

        const timeoutId = setTimeout(() => {
            if (checkingInterval) {
                clearInterval(checkingInterval);
                window.segmentLoaded = false;
                reject(new Error('Segment failed to load within timeout period'));
            }
        }, TIMEOUT_MS);

        checkingInterval = setInterval(() => {
            if (window.analytics) {
                console.log('✅ Segment loaded successfully');
                if (checkingInterval) clearInterval(checkingInterval);
                clearTimeout(timeoutId);
                window.segmentLoaded = true;
                resolve(true);
            }
        }, CHECK_INTERVAL_MS);
    });
};

/**
 * Integration rules mapping for different analytics services.
 * Format: [cookieCategory, defaultEnabled]
 * - cookieCategory: 'necessary' | 'advertising' | null (null means always use defaultEnabled)
 * - defaultEnabled: boolean
 */
const INTEGRATION_RULES = {
    'All': [null, true],
    'Braze': ['necessary', true],
    'Braze Cloud Mode (Actions)': ['necessary', true],
    'Braze Web Device Mode (Actions)': ['necessary', true],
    'Facebook Conversions API (Actions)': ['advertising', false],
    'Google Ads Conversions': ['advertising', false],
    'Google Ads Remarketing Lists': ['advertising', false],
    'Google AdWords New': ['advertising', false],
    'Personas Facebook Custom Audiences': ['advertising', false],
};

/**
 * Builds integration configuration based on user consent preferences
 * @param context Context object containing user consent preferences
 * @returns Record of integration names and their enabled status
 */
const buildIntegrations = (context: Context): Record<string, boolean> => {
    return Object.entries(INTEGRATION_RULES).reduce((acc, [integration, [category, defaultValue]]) => {
        const isEnabled = category === null ? defaultValue : 
            context.consent.categoryPreferences[category as keyof typeof context.consent.categoryPreferences] === true || defaultValue;
        return { ...acc, [integration]: isEnabled };
    }, {});
};

/**
 * Tracks the initial start of the application form
 * @param state Current state containing UTM parameters
 * @param obj Object containing form GUID and vehicle type
 */
const trackApplicationFormStart = (
    state: { utmStore?: { utmParameters?: UTMParameters } }, 
    obj: { guid: string; vehicleType?: string }
) => {
    console.log('📝 Tracking form start:', { state, obj });
    if (!window.analytics) {
        console.warn('⚠️ Analytics not available for form start tracking');
        return;
    }

    const trackData: TrackData = {
        "loan_submission_guid": obj.guid,
        "vehicle_type": obj.vehicleType ? obj.vehicleType.toLowerCase() : "car"
    };

    const cookieString = document.cookie;
    const context: Context = {
        consent: {
            categoryPreferences: {
                analytics: CookieHelper.getCookieValue(cookieString, 'cookieyes-analytics') === 'yes' || false,
                advertising: CookieHelper.getCookieValue(cookieString, 'cookieyes-advertisement') === 'yes' || false,
                functional: CookieHelper.getCookieValue(cookieString, 'cookieyes-functional') === 'yes' || false,
                performance: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || false,
                necessary: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || true,
            }
        }
    };

    const integrations = buildIntegrations(context);

    const utmData: Partial<UTMParameters> = {};
    if (state?.utmStore?.utmParameters) {
        utmData.gclid = state.utmStore.utmParameters.gclid || undefined;
        utmData.fbclid = state.utmStore.utmParameters.fbclid || undefined;

        ['campaign', 'content', 'medium', 'source', 'term', 'type'].forEach(field => {
            const key = `utm_${field}`;
            const value = state.utmStore?.utmParameters?.[field as keyof UTMParameters];
            utmData[key as keyof UTMParameters] = value ? StringHelper.toLowerCaseAndTrim(value) : undefined;
        });
    }

    ['ttclid', 'msclkid', 'wbraid', 'gbraid', 'utm_id', 'fbclid'].forEach(param => {
        utmData[param as keyof UTMParameters] = RequestHelper.getQueryVariable(param) || undefined;
    });

    const identifyObj = {
        cust_last_campaign_content: utmData.utm_content || null,
        cust_last_campaign_medium: utmData.utm_medium || null,
        cust_last_campaign_name: utmData.utm_campaign || null,
        cust_last_campaign_source: utmData.utm_source || null,
        cust_last_campaign_term: utmData.utm_term || null,
        cust_last_campaign_id: utmData.utm_id || null,
        cust_last_campaign_type: utmData.utm_type || null,
        cust_last_campaign_fbclid: utmData.fbclid || null,
        cust_last_campaign_gbraid: utmData.gbraid || null,
        cust_last_campaign_gclid: utmData.gclid || null,
        cust_last_campaign_msclkid: utmData.msclkid || null,
        cust_last_campaign_ttclid: utmData.ttclid || null,
        cust_last_campaign_wbraid: utmData.wbraid || null,
    };

    const anonymousId = CookieHelper.getCookieValue(cookieString, 'ajs_anonymous_id');
    window.analytics.page("application", "Application Form Started", trackData, { ...context, integrations }, { anonymousId });
    window.analytics.identify(identifyObj, { ...context, integrations }, { anonymousId });
};

/**
 * Tracks page views within the application
 * @param state Current state containing UTM parameters
 * @param guid Application GUID
 * @param pageName Name of the current page
 * @returns Promise<void>
 */
const trackPage = async (
    state: { utmStore?: { utmParameters?: UTMParameters } }, 
    guid: string, 
    pageName: string
): Promise<void> => {
    console.log('📄 Tracking page view:', { state, guid, pageName });
    if (!window.analytics || !window.segmentLoaded) {
        console.warn('⚠️ Analytics not available for page tracking');
        return;
    }

    const trackData: TrackData = {
        "loan_submission_guid": guid,
        "loan_application_step": pageName.toLowerCase()
    };

    const cookieString = document.cookie;
    const context: Context = {
        consent: {
            categoryPreferences: {
                analytics: CookieHelper.getCookieValue(cookieString, 'cookieyes-analytics') === 'yes' || false,
                advertising: CookieHelper.getCookieValue(cookieString, 'cookieyes-advertisement') === 'yes' || false,
                functional: CookieHelper.getCookieValue(cookieString, 'cookieyes-functional') === 'yes' || false,
                performance: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || false,
                necessary: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || true,
            }
        }
    };

    const integrations = buildIntegrations(context);
    const anonymousId = CookieHelper.getCookieValue(cookieString, 'ajs_anonymous_id');
    const userId = CookieHelper.getCookieValue(cookieString, 'ajs_user_id');

    return new Promise<void>((resolve) => {
        if (userId) {
            console.log('🔍 Tracking with userId:', { userId, anonymousId });
            window.analytics.page("application", "Application Page Change", trackData, { ...context, integrations }, { anonymousId }, { userId });
        } else {
            console.log('🔍 Tracking without userId:', { anonymousId });
            window.analytics.page("application", "Application Page Change", trackData, { ...context, integrations }, { anonymousId });
        }
        resolve();
    });
};

const pushToDataLayer = async (dataLayerPayload: any): Promise<void> => {
    if (!window.dataLayer) {
        console.warn('⚠️ dataLayer not available');
        return;
    }

    const dataLayer = window.dataLayer; // Store reference to avoid undefined checks

    return new Promise<void>((resolve) => {
        let timeoutId: number;
        let didTimeout = false;
        let retryCount = 0;

        const attemptPush = async () => {
            try {
                // Wait for GTM container to be ready
                const gtmReady = await waitForGTMContainer();
                if (!gtmReady) {
                    if (retryCount < GTM_MAX_RETRIES) {
                        retryCount++;
                        const delay = Math.min(GTM_RETRY_DELAY * Math.pow(GTM_RETRY_BACKOFF, retryCount - 1), 10000);
                        console.log(`⏳ Waiting for GTM to load, retry ${retryCount}/${GTM_MAX_RETRIES} in ${delay}ms`);
                        setTimeout(attemptPush, delay);
                        return;
                    } else {
                        console.warn('⚠️ GTM container not found after retries');
                        Sentry.captureMessage('GTM container not found after retries', {
                            level: 'warning',
                            tags: {
                                gtmContainer: GTM_CONTAINER_ID,
                                error: 'Container not loaded after retries',
                                retryCount: retryCount.toString()
                            },
                            extra: {
                                dataLayerPayload,
                                lastDataLayerEvents: dataLayer.slice(-3),
                                browserInfo: {
                                    userAgent: navigator.userAgent || '',
                                    vendor: navigator.vendor || '',
                                    platform: navigator.platform || ''
                                }
                            }
                        });
                        resolve();
                        return;
                    }
                }

                const startTime = Date.now();
                try {
                    dataLayer.push({
                        ...dataLayerPayload,
                        eventCallback: function(id: string) {
                            if (id === GTM_CONTAINER_ID && !didTimeout) {
                                const duration = Date.now() - startTime;
                                console.log(`✅ GTM container ${id} finished processing in ${duration}ms`);
                                clearTimeout(timeoutId);
                                resolve();
                            }
                        },
                        eventTimeout: GTM_TIMEOUT_MS
                    });
                } catch (error) {
                    console.error('❌ Error pushing to dataLayer:', error);
                    Sentry.captureException(error, {
                        tags: {
                            gtmContainer: GTM_CONTAINER_ID,
                            context: 'dataLayer.push',
                            retryCount: retryCount.toString()
                        },
                        extra: {
                            dataLayerPayload,
                            error: error instanceof Error ? error.message : String(error)
                        }
                    });
                    resolve(); // Resolve anyway to not block the flow
                }
            } catch (error) {
                console.error('❌ Error in GTM push attempt:', error);
                Sentry.captureException(error, {
                    tags: {
                        gtmContainer: GTM_CONTAINER_ID,
                        context: 'attemptPush',
                        retryCount: retryCount.toString()
                    },
                    extra: {
                        dataLayerPayload,
                        error: error instanceof Error ? error.message : String(error)
                    }
                });
                resolve();
            }
        };

        // Set timeout for the entire operation
        timeoutId = setTimeout(() => {
            didTimeout = true;
            console.warn(`⚠️ GTM tracking timeout after ${GTM_TIMEOUT_MS}ms`);
            
            Sentry.captureMessage('GTM tracking timed out', {
                level: 'warning',
                tags: {
                    gtmContainer: GTM_CONTAINER_ID,
                    dataLayerLength: dataLayer.length.toString(),
                    eventName: dataLayerPayload.event,
                    retryCount: retryCount.toString()
                },
                extra: {
                    dataLayerPayload,
                    lastDataLayerEvents: dataLayer.slice(-3),
                    timeoutDuration: GTM_TIMEOUT_MS,
                    browserInfo: {
                        userAgent: navigator.userAgent || '',
                        vendor: navigator.vendor || '',
                        platform: navigator.platform || ''
                    }
                }
            });
            resolve();
        }, GTM_TIMEOUT_MS);

        // Start the first attempt
        attemptPush();
    });
};

/**
 * Tracks the completion of personal details section
 * @param state Current state containing subdomain
 * @param obj Personal details data object
 * @returns Promise that resolves when tracking is complete
 */
const trackPersonalDetailsCompletedEvent = async (
    state: { subdomain?: string }, 
    obj: PersonalDetailsData
): Promise<void> => {
    const progressStore = useProgressStore();
    progressStore.resetTrackingComplete(); // Reset at start of tracking
    
    console.log('👤 Tracking personal details completion:', { state, obj });
    if (!window.analytics) {
        console.warn('⚠️ Analytics not available for personal details tracking');
        return;
    }

    const eventName = "Loan Application Submitted";
    const email_address = StringHelper.toLowerCaseAndTrim(obj.email);
    const submission_hostname = state.subdomain ? 
        StringHelper.toLowerCaseAndTrim(state.subdomain + ".carfinance247.co.uk") : 
        StringHelper.toLowerCaseAndTrim(window.location.host);
    
    const tracksData: TrackData = {
        email: email_address,
        loan_submission_guid: obj.guid,
        phone_number_e164: obj.phone_number_normalised.startsWith('+') ? 
            obj.phone_number_normalised : 
            '+' + obj.phone_number_normalised,
        submission_hostname,
        vehicle_type: Object.keys(VEHICLE_TYPE).find(key => 
            VEHICLE_TYPE[key as keyof typeof VEHICLE_TYPE] === obj.vehicle_type_id
        )?.toLowerCase() || ''
    };

    const cookieString = document.cookie;
    const context: Context = {
        consent: {
            categoryPreferences: {
                analytics: CookieHelper.getCookieValue(cookieString, 'cookieyes-analytics') === 'yes' || false,
                advertising: CookieHelper.getCookieValue(cookieString, 'cookieyes-advertisement') === 'yes' || false,
                functional: CookieHelper.getCookieValue(cookieString, 'cookieyes-functional') === 'yes' || false,
                performance: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || false,
                necessary: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || true,
            }
        }
    };

    const integrations = buildIntegrations(context);
    let hash_result;
    try {
        if (!email_address) {
            console.warn('⚠️ Empty email address provided for hashing');
            hash_result = '';
        } else {
            hash_result = await HashHelper.sha256(email_address);
            console.log('✅ Successfully hashed email:', { email_address, hash_result });
        }
    } catch (error) {
        console.error('Failed to generate hash:', error);
        hash_result = email_address || '';
    }

    const identifyObj = {
        user_id: hash_result,
        date_of_birth: obj.date_of_birth,
        email_address,
        email: email_address,
        first_name: StringHelper.toLowerCaseAndTrim(obj.first_name),
        last_name: StringHelper.toLowerCaseAndTrim(obj.last_name),
        phone_number_normalised: obj.phone_number_normalised,
        postcode: StringHelper.toLowerCaseAndTrimAndRemoveAllSpaces(obj.postcode),
        submission_hostname,
        _gclid: obj.utm?.gclid || '',
        phone: obj.phone_number,
        phone_number_e164: tracksData.phone_number_e164,
        loan_application_status: 'submitted',
        cust_last_loan_application_step: 'contact-details-p2',
        cust_last_loan_application_submission_hostname: submission_hostname,
        cust_last_loan_application_loan_submission_guid: obj.guid || null,
    };

    const anonymousId = CookieHelper.getCookieValue(cookieString, 'ajs_anonymous_id');
    const userId = CookieHelper.getCookieValue(cookieString, 'ajs_user_id');

    return new Promise<void>((resolve) => {
        const promises: Promise<void>[] = [];

        // Segment tracking promise
        promises.push(new Promise<void>((segmentResolve) => {
            if (userId) {
                console.log('🔍 Tracking with userId:', { userId, anonymousId });
                window.analytics.track(eventName, tracksData, { ...context, integrations }, { anonymousId }, { userId });
                window.analytics.identify(hash_result, identifyObj, { ...context, integrations }, { anonymousId }, { userId }, () => {
                    console.log('✅ Segment tracking complete (with userId)');
                    segmentResolve();
                });
            } else {
                console.log('🔍 Tracking without userId:', { anonymousId });
                window.analytics.track(eventName, tracksData, { ...context, integrations }, { anonymousId });
                window.analytics.identify(hash_result, identifyObj, { ...context, integrations }, { anonymousId }, () => {
                    console.log('✅ Segment tracking complete (without userId)');
                    segmentResolve();
                });
            }
        }));

        // GTM tracking promise
        if (window.dataLayer && Array.isArray(window.dataLayer)) {
            const dataLayerPayload = {
                ...tracksData,
                category: "application",
                event: eventName
            };
            console.log('📊 Pushing to dataLayer:', dataLayerPayload);

            promises.push(
                new Promise<void>((resolve) => {
                    pushToDataLayer(dataLayerPayload).then(() => {
                        console.log('✅ Data layer push for personal details completed');
                        resolve();
                    }).catch(error => {
                        console.error('❌ Error in data layer push:', error);
                        Sentry.captureException(error, {
                            tags: {
                                gtmContainer: GTM_CONTAINER_ID,
                                context: 'personalDetails.trackEvent'
                            }
                        });
                        resolve(); // Resolve anyway to not block the flow
                    });
                })
            );
        }

        // Wait for all tracking to complete
        Promise.all(promises).finally(() => {
            progressStore.setTrackingComplete();
            resolve();
        });
    });
};

// Track if Segment check has been initiated
let isSegmentCheckInitiated = false;
let segmentLoadPromise: Promise<boolean> | null = null;

const initializeSegment = () => {
    const environment = import.meta.env.PROD ? 'production' : 'replica';
    const segmentWriteKey = environment === 'production' 
        ? 'KuVyis8zpJQhSetIVMoK04XzvB1bA1QC'
        : 'lpoFRMifdbFdurOPEBJjD5bTbJpgttW3'

    window.analytics = AnalyticsBrowser.load({ 
        writeKey: segmentWriteKey,
        ...(environment !== 'production' && {
            integrations: {
                'All': true,
                'Braze': true,
                'Braze Cloud Mode (Actions)': true,
                'Braze Web Device Mode (Actions)': true,
                'Google Analytics 4 Cloud': true,
                'Google Analytics 4 Web': true,
                'Webhooks': true,
                'Criteo': true,
                'Bing Ads': true,
                'CM360 Offline Conv - VBB - Appr - Dealt (Personas - Prod)': true,
                'CM360 Offline Conv - VBB - Est. Rev (Personas - Prod)': true,
                'CM360 Offline Conversions - v2 - Prod (Personas - Prod)': true,
                'Facebook Conversions API (Actions)': true,
                'Facebook Pixel': true,
                'Google Ads Conversions': true,
                'Google Ads Remarketing Lists': true,
                'Google AdWords New': true,
                'Lead Broker Declined list (Personas - Prod)': true,
                'Personas Facebook Custom Audiences': true,
                'TikTok Conversions': true
            }
        })
    });
};

/**
 * Pinia plugin for Segment analytics integration
 * 
 * This plugin handles:
 * - Initialization of Segment analytics
 * - Tracking form starts
 * - Tracking page changes
 * - Tracking form completions
 * 
 * It listens to store actions and triggers appropriate tracking events based on:
 * - Progress store's setPageName action (form start and page tracking)
 * - Progress store's completeForm action (personal details completion)
 * 
 * @param param0 Pinia plugin context
 */
export function segmentPlugin({ store }: PiniaPluginContext) {
    if (!isSegmentCheckInitiated) {
        console.log('🔌 Initialising Segment plugin');
        isSegmentCheckInitiated = true;
        initializeSegment();
        segmentLoadPromise = checkSegmentLoaded().catch(error => {
            console.error('❌ Segment load error:', error);
            return false;
        });
    }

    store.$onAction(async ({
        name,
        after,
        args
    }) => {
        // Wait for segment to load before processing any actions
        if (segmentLoadPromise) {
            await segmentLoadPromise;
        }

        if (!window.segmentLoaded) {
            console.warn('⚠️ Skipping tracking - Segment not loaded yet');
            return;
        }

        console.log('🎯 Store action:', { storeId: store.$id, actionName: name });
        const progressStore = useProgressStore();
        const configStore = useConfigStore();
        const utmParameters: Partial<UTMParameters> = {};
        const utmStore = useUTMStore();
        Object.keys(utmStore.$state.utmParameters).forEach(key => {
            const value = utmStore.$state.utmParameters[key as keyof typeof utmStore.$state.utmParameters];
            utmParameters[key as keyof typeof utmStore.$state.utmParameters] = value || undefined;
        });

        if (store.$id === 'progress' && name === 'setPageName') {
            console.log('📄 Progress store setPageName triggered', { 
                hasStarted: progressStore.hasStarted,
                pageName: args[0],
                previousPage: progressStore.previousPage,
                GUID: configStore.GUID
            });

            // Track form start before state changes
            if (!progressStore.previousPage) {
                console.log('🎯 Form not started, initiating tracking');
                const trackData = {
                    guid: configStore.GUID as string,
                    vehicleType: RequestHelper.getQueryVariable('vehicleType') || 
                                RequestHelper.getQueryVariable('vehicle_type') || 
                                'car'
                };
                
                const stateWithUtm = { utmStore: { utmParameters: utmParameters as unknown as UTMParameters }};
                trackApplicationFormStart(stateWithUtm, trackData);
            }

            // Track page change immediately
            console.log('📄 Tracking page change:', {
                GUID: configStore.GUID,
                pageName: args[0]
            });

            try {
                await trackPage(
                    { utmStore: { utmParameters: utmParameters as unknown as UTMParameters }}, 
                    configStore.GUID as string, 
                    args[0]
                );
            } catch (error) {
                console.error('❌ Error in page tracking:', error);
            }

            console.log('⚡ Action handler complete');
        }

        if (store.$id === 'progress' && name === 'completeForm') {
            console.log('📊 Starting tracking in plugin');
            return new Promise<void>((resolve) => {
                after(async () => {
                    const formStore = useFormStore();
                    console.log('🔄 Running tracking events...');

                    const requiredFields = {
                        guid: configStore.GUID,
                        email: formStore.$state.email,
                        firstName: formStore.$state.firstName,
                        lastName: formStore.$state.lastName,
                        contactNumber: formStore.$state.contactNumber,
                        vehicleTypeId: formStore.$state.vehicleTypeId || 40
                    };

                    const missingFields = Object.entries(requiredFields)
                        .filter(([key, value]) => !value && key !== 'vehicleTypeId')
                        .map(([key]) => key);

                    if (missingFields.length > 0) {
                        console.warn('⚠️ Missing required form data for tracking:', missingFields);
                        Sentry.captureMessage('Missing required form data for tracking', {
                            level: 'warning',
                            extra: {
                                missingFields,
                                formState: requiredFields
                            }
                        });
                        resolve();
                        return;
                    }

                    // Format date of birth with type checking
                    const dateOfBirth = formStore.$state.dateOfBirth && 
                        typeof formStore.$state.dateOfBirth === 'object' && 
                        'toISOString' in formStore.$state.dateOfBirth ? 
                            (formStore.$state.dateOfBirth as Date).toISOString().split('T')[0] : // Format as YYYY-MM-DD
                            (formStore.$state.dateOfBirth || '');

                    // Type assertion for required fields
                    const trackingData = {
                        guid: requiredFields.guid as string,
                        email: requiredFields.email as string,
                        date_of_birth: dateOfBirth, // Use the formatted value directly
                        first_name: requiredFields.firstName as string,
                        last_name: requiredFields.lastName as string,
                        phone_number: requiredFields.contactNumber as string,
                        phone_number_normalised: phoneNormalised(requiredFields.contactNumber as string),
                        postcode: formStore.$state.addresses[0]?.postcode || '',
                        vehicle_type_id: requiredFields.vehicleTypeId as number,
                        utm: formStore.$state.utmParameters,
                        net_monthly_income: formStore.$state.netMonthlyIncome,
                        city: formStore.$state.addresses[0]?.town || ''
                    };

                    await trackPersonalDetailsCompletedEvent({ subdomain: undefined }, trackingData);
                    console.log('✨ Tracking complete, resolving promise', trackingData);
                    resolve();
                });
            });
        }
    });
}