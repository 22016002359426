/**
 * @fileoverview Root store composition module
 * Provides centralized access to all application stores through a single composable
 */

import { useAddressStore } from './modules/addressStore';
import { useFormStore } from './modules/formStore';
import { useVehicleStore } from './modules/vehicleStore';
import { useConfigStore } from './modules/configStore';
import { useUTMStore } from './modules/utmStore';
import { useProgressStore } from './modules/progressStore';
import { useDeviceStore } from './modules/deviceStore';
import { useApplicationStore } from './modules/applicationStore';
import { useThemeStore } from './modules/themeStore';

/**
 * Root store composable that provides access to all application stores
 * Centralizes store access and maintains type safety for store instances
 * 
 * Available stores:
 * - address: Address and location management
 * - form: Form state and validation
 * - vehicle: Vehicle details and configuration
 * - config: Application configuration
 * - utm: Marketing and tracking parameters
 * - progress: Progress tracking
 * - device: Device information store instance
 * - application: Application submission store instance
 * - theme: Theme store instance
 * 
 * @returns {Object} Composed object containing all store instances
 * @property {ReturnType<typeof useAddressStore>} address - Address store instance
 * @property {ReturnType<typeof useFormStore>} form - Form store instance
 * @property {ReturnType<typeof useVehicleStore>} vehicle - Vehicle store instance
 * @property {ReturnType<typeof useConfigStore>} config - Configuration store instance
 * @property {ReturnType<typeof useUTMStore>} utm - UTM parameters store instance
 * @property {ReturnType<typeof useProgressStore>} progress - Progress tracking store instance
 * @property {ReturnType<typeof useDeviceStore>} device - Device information store instance
 * @property {ReturnType<typeof useApplicationStore>} application - Application submission store instance
 * @property {ReturnType<typeof useThemeStore>} theme - Theme store instance
 * 
 * @example
 * const store = useRootStore();
 * store.form.updateFormValue(['John', 'firstName']);
 * await store.application.submitApplication();
 */
export const useRootStore = () => {
  return {
    address: useAddressStore(),
    form: useFormStore(),
    vehicle: useVehicleStore(),
    config: useConfigStore(),
    utm: useUTMStore(),
    progress: useProgressStore(),
    device: useDeviceStore(),
    application: useApplicationStore(),
    theme: useThemeStore()
  };
}; 