/**
 * @fileoverview Configuration store module for managing application-wide settings
 * This Pinia store manages core application configuration including:
 * - Dealer and affiliate settings and relationships
 * - Domain and subdomain configuration
 * - Session tracking (GUID)
 * - Application behavior flags and environment settings
 */

import { defineStore } from 'pinia';
import { LocationHelper, RequestHelper } from "@/helpers";
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import type { DealerConfig, ConfigState, AffiliateConfig } from '@/types/configTypes';
import { AffiliateMapper } from '@/utils/AffiliateMapper';
import { URLS } from '@/factories'
import type { DotCMSPageComponent } from '@/types/global';

/**
 * Configuration store for centralized state management
 * @store config
 * 
 * Key features:
 * - Manages dealer and affiliate relationships and configurations
 * - Handles domain/subdomain settings
 * - Controls application behavior flags
 * - Manages session tracking via GUID
 * - Handles environment-specific settings
 * 
 * @example
 * const configStore = useConfigStore();
 * await configStore.setIsDealer();
 * await configStore.setAffiliateConfig();
 */
export const useConfigStore = defineStore('config', {
  /**
   * Initial state of the configuration store
   * @returns {ConfigState} The configuration store's initial state
   * 
   * @property {boolean} isDealer - Indicates if the current context is a dealer
   * @property {object|null} dealer - Dealer information object
   * @property {string|null} dealerName - Name of the current dealer
   * @property {number|null} dealerAffiliateUserId - Unique identifier for dealer affiliate
   * @property {AffiliateConfig} affiliateConfig - Affiliate-specific configuration
   * @property {DealerConfig} dealerConfig - Dealer-specific configuration
   * @property {string|null} subdomain - Current application subdomain
   * @property {string|null} host - Current application host
   * @property {boolean} noTransition - Flag to disable transitions
   * @property {boolean} noBankDetails - Flag to hide bank details
   * @property {string|null} GUID - Unique session identifier
   * @property {boolean} isProduction - Environment indicator
   * @property {object|null} dealerDetails - Dealer details object
   */
  state: (): ConfigState => ({
    isDealer: false,
    did: null,
    dealer: null,
    dealerName: null,
    dealerAffiliateUserId: null,
    affiliateConfig: {
      companyName: '',
      prePopulateForm: true,
      affiliateUserId: null,
      theme: '',
      useDefaultLang: true,
      systemName: '',
      loanAmount: 0,
      logo: '',
      logoWidth: 0,
      logoHeight: 0,
      contents: {},
      themeColors: {}
    } as AffiliateConfig,
    dealerConfig: {
      dealerCarSearchStatusId: null,
      dealerGroupId: null,
      dealerId: null,
      dealerSetupStatusId: null,
      dealerTypeId: null,
      emailAddress: '',
      ltdCompanyNumber: '',
      name: '',
      phoneNumber1: '',
      siteAddress: {
        buildingName: '',
        buildingNumber: '',
        dealerAddressId: null,
        locality: '',
        postcode: '',
        streetName: '',
        subBuilding: '',
        town: ''
      },
      webSiteUrl: ''
    } as DealerConfig,
    subdomain: null,
    host: null,
    noTransition: false,
    noBankDetails: false,
    GUID: null,
    isProduction: import.meta.env.PROD,
    dealerDetails: null,
    isPoweringLender: false
  }),

  actions: {
    /**
     * Sets dealer status based on URL parameters
     * Extracts and validates dealer affiliate ID (daid) and dealer name (dname)
     * 
     * @throws {Error} When dealer affiliate ID is invalid or cannot be parsed
     * @example
     * // URL: https://example.com?daid=123&dname=ExampleDealer
     * await configStore.setIsDealer();
     * // Results in:
     * // isDealer: true
     * // dealerAffiliateUserId: 123
     * // dealerName: "ExampleDealer"
     */
    setIsDealer() {
      try {
        const daid = RequestHelper.getQueryVariable('daid');
        const dname = RequestHelper.getQueryVariable('dname');
        const did = RequestHelper.getQueryVariable('did');

        this.did = did || null;
        this.dealerName = dname || null;
        if (daid) {
          const daidNumber = Number(daid);
          if (isNaN(daidNumber)) {
            throw new Error('Invalid dealer affiliate ID');
          }

          this.isDealer = true;
          this.dealerAffiliateUserId = daidNumber;
          this.dealerName = dname || '';
        }
      } catch (error) {
        handleStoreError('config', 'setIsDealer', error, StoreErrorType.VALIDATION);
      }
    },

    setIsPoweringLender() {
      try {
        // campaign=startlinefinance
        // utm_campaign=startline

        const campaign = RequestHelper.getQueryVariable('campaign');
        const utm_campaign = RequestHelper.getQueryVariable('utm_campaign');

        var campaignName = campaign || utm_campaign;
        if (campaignName === 'startlinefinance' || campaignName === 'startline') {
          this.isPoweringLender = true;
        }
      } catch (error) {
        handleStoreError('config', 'setIsPoweringLender', error, StoreErrorType.VALIDATION);
      }
    },

    async prepareAffiliateConfig() {
      try {
        await AffiliateMapper.initAffiliateConfig();
      } catch (error) {
        handleStoreError('config', 'prepareAffiliateConfig', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Updates affiliate configuration settings based on the current host
     * Retrieves and validates affiliate configuration using AffiliateMapper
     * 
     * @throws {Error} When affiliate configuration cannot be retrieved or is invalid
     * @example
     * await configStore.setAffiliateConfig();
     */
    setAffiliateConfig() {
      try {
        const host = LocationHelper.getHost();
        const affiliateConfig = AffiliateMapper.getAffiliateConfig(host);
        if (affiliateConfig) {
          this.affiliateConfig = affiliateConfig;
        }
      } catch (error) {
        handleStoreError('config', 'setAffiliateConfig', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Updates dealer configuration settings with provided config object
     * 
     * @param {DealerConfig} config - Dealer configuration object
     * @throws {Error} When company name is missing or configuration is invalid
     * @example
     * await configStore.setDealerConfig({
     *   companyName: "Example Dealer",
     *   prePopulateForm: true,
     *   affiliateUserId: 123,
     *   theme: "default"
     * });
     */
    setDealerConfig(config: DealerConfig) {
      try {
        this.dealerConfig = config;
      } catch (error) {
        handleStoreError('config', 'setDealerConfig', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Sets the application host from the current location
     * Uses LocationHelper to determine the current host
     * 
     * @throws {Error} When host cannot be determined or is invalid
     * @example
     * await configStore.setHost();
     * // host: "example.com"
     */
    setHost() {
      try {
        const host = LocationHelper.getHost();
        if (!host) {
          throw new Error('Unable to determine host');
        }
        this.host = host;
      } catch (error) {
        handleStoreError('config', 'setHost', error, StoreErrorType.UNKNOWN);
      }
    },

    /**
     * Updates the application subdomain based on current location
     * Uses LocationHelper to extract subdomain information
     * 
     * @example
     * await configStore.setSubdomain();
     * // For URL: https://sub.example.com
     * // subdomain: "sub"
     */
    setSubdomain() {
      this.subdomain = LocationHelper.getSubdomain();
    },

    /**
     * Generates and sets a new GUID for session tracking
     * Uses timestamp and random values to ensure uniqueness
     * Only generates new GUID if one doesn't already exist
     * 
     * @throws {Error} When GUID generation fails
     * @example
     * await configStore.setGUID();
     * // GUID: "550e8400-e29b-41d4-a716-446655440000"
     * 
     * Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
     * Where:
     * - x is a random hexadecimal digit
     * - 4 is fixed (version)
     * - y is either 8, 9, a, or b
     */
    setGUID() {
      try {
        if (this.GUID) return;

        let timestamp = new Date().getTime();
        timestamp += performance.now();

        const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (t) => {
          const r = (timestamp + 16 * Math.random()) % 16 | 0;
          timestamp = Math.floor(timestamp / 16);
          return (t === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });

        this.GUID = guid;
      } catch (error) {
        handleStoreError('config', 'setGUID', error);
      }
    },

    /**
     * Sets the noTransition flag based on URL query parameter
     * If 'noTransition' query parameter exists and is truthy, transitions will be disabled
     * 
     * @example
     * // URL: https://example.com?noTransition=true
     * configStore.setNoTransition();
     * // Results in noTransition: true
     */
    setNoTransition() {
      try {
        const noTransition = RequestHelper.getQueryVariable('noTransition');
        if (noTransition) {
          this.noTransition = true;
        }
      } catch (error) {
        handleStoreError('config', 'setNoTransition', error, StoreErrorType.VALIDATION);
      }
    },

    async initialiseDealer(did: string, toDealerGetMyQuote: string) {
      if (!did) {
        throw new Error('Dealer ID is required');
      }

      if(toDealerGetMyQuote) {
        return;
      }

      try {
        this.did = did;
        const dealerConfig = !this.dealerName 
          ? await this.getByDid(did)
          : { 
              name: this.dealerName,
              dealerId: did,
              // Initialize other required DealerConfig fields with defaults
              dealerCarSearchStatusId: null,
              dealerGroupId: null,
              dealerSetupStatusId: null,
              dealerTypeId: null,
              emailAddress: '',
              ltdCompanyNumber: '',
              phoneNumber1: '',
              siteAddress: {
                buildingName: '',
                buildingNumber: '',
                dealerAddressId: null,
                locality: '',
                postcode: '',
                streetName: '',
                subBuilding: '',
                town: ''
              },
              webSiteUrl: ''
            } as DealerConfig;

        this.setDealerConfig(dealerConfig);
      } catch (error) {
        handleStoreError('config', 'initialiseDealer', error, StoreErrorType.VALIDATION);
        throw error;
      }
    },

    async getByDid(did: string) {
      const requestURL = this.isProduction ? URLS.production.apiBaseUrl : URLS.replica.apiBaseUrl;
      const url = requestURL + `/dealer-details?dealerId=${did}`;
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000);

      try {
        const response = await fetch(url, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
          }
        });
        
        clearTimeout(timeoutId);

        if (response.ok) {
          const data = await response.json() as DotCMSPageComponent;
          if (!data) {
            throw new Error('No content found');
          }
          return data;
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        clearTimeout(timeoutId);
        handleStoreError('config', 'getByDid', error, StoreErrorType.NETWORK);
        throw error;
      }
    },

  },
  getters: {
    isDefaultTheme (state) {
      if(state.affiliateConfig?.affiliateUserId == 0) return null;
      return state.affiliateConfig?.companyName
    }
  }
}); 