import * as Sentry from "@sentry/vue";

/**
 * Adds a style element to prevent FOUC (Flash of Unstyled Content) during script loading
 */
export function addFoucPrevention() {
  const style = document.createElement('style');
  style.id = 'fouc-prevention';
  style.textContent = `
    /* Prevent content shift/flicker during script loading */
    html.loading-scripts * {
      transition: none !important;
    }
    /* Prevent layout shifts during script initialization */
    html.loading-scripts .dynamic-content {
      opacity: 0.98;
      visibility: visible !important;
    }
  `;
  document.head.appendChild(style);
  document.documentElement.classList.add('loading-scripts');
}

/**
 * Removes the FOUC prevention
 */
export function removeFoucPrevention() {
  document.documentElement.classList.remove('loading-scripts');
  const style = document.getElementById('fouc-prevention');
  if (style) {
    setTimeout(() => {
      style.remove();
    }, 100); // Reduced delay to 100ms for faster transition back
  }
}

type ScriptConfig = {
  url: string;
  name: string;
  status: 'pending' | 'loaded' | 'failed';
  timeout?: number;
  retries?: number;
  checkLoaded?: () => boolean;
};

declare global {
  interface Window {
    google_tag_manager?: { [key: string]: any };
    clarity?: Function;
  }
}

/**
 * Configuration for external third-party scripts that need to be loaded
 * Each entry contains the script URL, a descriptive name for logging, and loading status
 */
export const EXTERNAL_SCRIPTS: ScriptConfig[] = [
  { 
    url: '/gtm.js', 
    name: 'Google Tag Manager', 
    status: 'pending',
    timeout: 5000, // Reduced timeout for faster app response
    retries: 2, // Reduced retries for faster app response
    checkLoaded: () => {
      return typeof window.google_tag_manager !== 'undefined' && 
             typeof window.google_tag_manager['GTM-5DS545J'] !== 'undefined';
    }
  },
  { 
    url: '/clarity.js', 
    name: 'Microsoft Clarity', 
    status: 'pending',
    timeout: 4000, // Reduced timeout
    retries: 1, // Reduced retries
    checkLoaded: () => {
      return typeof window.clarity === 'function';
    }
  },
  { url: '/webtrends.js', name: 'Webtrends', status: 'pending', timeout: 3000, retries: 1 }
];

const searchParams = new URLSearchParams(window.location.search || '' );
if(window.location.hostname.indexOf('comparethemarketcarfinance') > -1 || searchParams.get('theme') === 'comparethemarketcarfinance' || 
    window.location.hostname.indexOf('carfinance.comparethemarket.com') > -1 || searchParams.get('theme') === 'comparethemarket') {
  // const productionDomain = 'carfinance247.co.uk';
  // const env = ( window.location.hostname.endsWith(productionDomain)|| window.location.hostname.indexOf('carfinance.comparethemarket.com') > -1 ) ?
  //     'prod' : 'dev';
  const env = import.meta.env.PROD ? 'prod' : 'dev';
  EXTERNAL_SCRIPTS.push({ url: `/tealium/setting.js`, name: 'Hotjar', status: 'pending' });
  EXTERNAL_SCRIPTS.push({ url: `/affiliates/comparethemarket/tealium-${env}.js`, name: 'Hotjar', status: 'pending' });
}

/**
 * Loads an external JavaScript file dynamically with timeout and retry logic
 * @param url - The URL of the script to load
 * @param retries - Number of retry attempts (default: 2)
 * @param timeout - Timeout in milliseconds (default: 5000)
 * @returns Promise that resolves when the script is loaded or rejects on error
 */
function loadScript(url: string, retries = 2, timeout = 5000): Promise<void> {
  const scriptConfig = EXTERNAL_SCRIPTS.find(s => s.url === url);
  const effectiveTimeout = scriptConfig?.timeout || timeout;
  const effectiveRetries = scriptConfig?.retries || retries;
  const checkLoaded = scriptConfig?.checkLoaded;

  const loadWithTimeout = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      // Check if script is already loaded
      const existingScript = document.querySelector(`script[src="${url}"]`);
      if (existingScript) {
        if (checkLoaded && !checkLoaded()) {
          // If the script exists but container not initialized, consider it a success anyway
          // This prevents blocking the app for GTM container initialization
          console.info(`Script ${url} exists but container not initialized. Continuing...`);
          resolve();
          return;
        }
        console.info(`Script ${url} already exists in DOM`);
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      
      // Prioritize non-render blocking loading for all scripts
      if (!url.includes('gtm.js')) {
        // Use "defer" for non-GTM scripts to ensure they don't block rendering
        script.defer = true;
      }
      
      // Add resource hints for faster loading
      // Add preconnect for the script domain
      const scriptDomain = new URL(script.src, window.location.origin).origin;
      const preconnect = document.createElement('link');
      preconnect.rel = 'preconnect';
      preconnect.href = scriptDomain;
      document.head.appendChild(preconnect);
      
      const startTime = Date.now();
      const timeoutId = setTimeout(() => {
        script.remove();
        preconnect.remove();
        const duration = Date.now() - startTime;
        // Don't reject for timeout, just log it and continue
        console.warn(`Script load timed out after ${duration}ms: ${url}, but continuing...`);
        resolve(); // Resolve anyway to prevent blocking the app
      }, effectiveTimeout);
      
      script.onload = () => {
        clearTimeout(timeoutId);
        const duration = Date.now() - startTime;
        
        // Even if container check fails, don't block the application
        if (checkLoaded && !checkLoaded()) {
          console.warn(`Script ${url} loaded but container not initialized after ${duration}ms. Continuing anyway.`);
          resolve();
          return;
        }
        
        console.info(`Script ${url} loaded successfully in ${duration}ms`);
        resolve();
      };
      
      script.onerror = (event) => {
        clearTimeout(timeoutId);
        preconnect.remove();
        // Don't remove the script on error - it might still load later
        const duration = Date.now() - startTime;
        console.warn(`Failed to load script ${url} after ${duration}ms. Continuing anyway.`);
        resolve(); // Resolve anyway to prevent blocking the app
      };
      
      document.head.appendChild(script);
    });
  };

  const attempt = async (attemptsLeft: number): Promise<void> => {
    try {
      await loadWithTimeout();
    } catch (error) {
      if (attemptsLeft > 0) {
        const delay = Math.min(1000 * (effectiveRetries - attemptsLeft + 1), 5000); // Reduced backoff
        console.warn(`Retrying script load for ${url} in ${delay}ms, attempts left: ${attemptsLeft}`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return attempt(attemptsLeft - 1);
      }
      // Don't throw error, just log it and continue
      console.warn(`All retries failed for ${url}, but continuing application execution.`);
    }
  };

  return attempt(effectiveRetries);
}

/**
 * Helper function to load a script and handle error reporting
 * @param script - The script configuration to load
 * @param isProd - Whether the application is running in production mode
 * @returns A promise that resolves when the script is loaded
 */
export async function loadScriptWithErrorHandling(script: ScriptConfig, isProd: boolean): Promise<void> {
  const startTime = Date.now();
  try {
    await loadScript(script.url);
    script.status = 'loaded';
    const duration = Date.now() - startTime;
    console.info(`Successfully loaded ${script.name} in ${duration}ms`);
  } catch (error) {
    script.status = 'failed';
    const duration = Date.now() - startTime;
    console.warn(`Failed to load ${script.name} after ${duration}ms:`, error);
    
    if (isProd) {
      Sentry.captureException(error, {
        level: 'warning',
        tags: {
          scriptName: script.name,
          loadDuration: duration.toString(),
          networkStatus: navigator.onLine ? 'online' : 'offline'
        },
        extra: {
          scriptName: script.name,
          url: script.url,
          error: error instanceof Error ? {
            message: error.message,
            stack: error.stack
          } : error,
          retryAttempts: script.retries || 2,
          timeout: script.timeout || 8000,
          browserInfo: {
            userAgent: navigator.userAgent,
            vendor: navigator.vendor,
            platform: navigator.platform
          },
          performance: {
            navigationTiming: performance?.timing || null
          }
        }
      });
    }
  }
}

/**
 * Loads all external scripts and handles error reporting
 * @param isProd - Whether the application is running in production mode
 */
export async function loadExternalScripts(isProd: boolean): Promise<void> {
  // Add FOUC prevention before loading scripts
  addFoucPrevention();
  
  try {
    // Reorder scripts to prioritize GTM first, then Clarity
    const orderedScripts = [...EXTERNAL_SCRIPTS].sort((a, b) => {
      if (a.url.includes('gtm.js')) return -1;
      if (b.url.includes('gtm.js')) return 1;
      if (a.url.includes('clarity.js')) return -1;
      if (b.url.includes('clarity.js')) return 1;
      return 0;
    });
    
    // Load GTM and Clarity scripts sequentially first
    for (const script of orderedScripts) {
      // Only process GTM and Clarity in this first loop
      if (!script.url.includes('gtm.js') && !script.url.includes('clarity.js')) {
        continue;
      }
      
      await loadScriptWithErrorHandling(script, isProd);
    }
    
    // Load remaining scripts in parallel after GTM and Clarity are loaded
    const remainingScripts = orderedScripts.filter(script => 
      !script.url.includes('gtm.js') && 
      !script.url.includes('clarity.js') && 
      script.status === 'pending'
    );
    
    if (remainingScripts.length > 0) {
      const results = await Promise.allSettled(
        remainingScripts.map(async (script) => {
          await loadScriptWithErrorHandling(script, isProd);
        })
      );
    }

    if (isProd) {
      Sentry.addBreadcrumb({
        category: 'scripts',
        message: 'External scripts loading complete',
        level: 'info',
        data: {
          scriptStatuses: EXTERNAL_SCRIPTS.map(script => ({
            name: script.name,
            status: script.status
          }))
        }
      });
    }
  } finally {
    // Remove FOUC prevention after all scripts are loaded or failed
    removeFoucPrevention();
  }
} 