/**
 * @fileoverview Vehicle store module for Pinia state management
 * 
 * This store handles:
 * - Vehicle data management from multiple sources (URL parameters, PistonHeads API, Vehicle API)
 * - Cloudinary image optimization and URL management
 * - Vehicle identification and details parsing
 * - Support for different data formats based on affiliate configuration
 */

import { defineStore } from 'pinia';
import { RequestHelper } from "@/helpers";
import { CloudinaryService } from "@/services";
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import type { Vehicle, VehicleState } from '@/types/vehicleTypes';
import { LocationHelper, ObjectHelper } from "@/helpers";
import { URLS } from '@/factories';
import { useConfigStore } from '@/stores/modules/configStore';
import type { DotCMSPageComponent } from '@/types/global';

/**
 * Vehicle store for centralized vehicle information management
 * 
 * Features:
 * - Multi-source vehicle data handling (URL params, PistonHeads, Vehicle API)
 * - Automatic Cloudinary image optimization
 * - Support for different vehicle data formats
 * - Error handling with type-specific responses
 * 
 * @store vehicle
 */
export const useVehicleStore = defineStore('vehicle', {
  /**
   * Initial state of the vehicle store
   * @returns {VehicleState} The vehicle store's initial state with default empty values
   */
  state: (): VehicleState => ({
    vehicleId: null,
    cloudinaryVehicleImageUrl: null,
    vehicle: {
      vehicleId: null,
      colour: '',
      fuelType: '',
      make: '',
      mileage: 0,
      model: '',
      price: 0,
      registrationYear: '',
      transmission: '',
      vrm: '',
      trim: '',
      imageUrl: '',
      dealerInfoUrl: '',
      vdpUrl: '',
    },
    vehicleList: [],
    fullVehicleList: [],
    isFullVehicleSearchDataLoaded: false
  }),

  actions: {
    /**
     * Fetches vehicle data from PistonHeads API endpoint
     * 
     * @returns {Promise<Object>} Normalized vehicle data object
     * @throws {Error} When API request fails or response is invalid
     */
    async getPistonHeadsVehicle() {
      try {
        const advertId = RequestHelper.getQueryVariable('advertid') || RequestHelper.getQueryVariable('advertId');
        if (!advertId) {
          console.log('No advertId found in query parameters');
          return {};
        }
        
        const configStore = useConfigStore();
        const apiUrl = configStore.isProduction 
          ? URLS.production.apiBaseUrl 
          : URLS.replica.apiBaseUrl;

        const response = await fetch(
          `${apiUrl}/cars/v1/advertdetails?advertId=${advertId}`,
          { method: 'GET' }
        );

        // Instead of throwing an error, return empty object if request fails
        if (!response.ok) {
          console.warn('getPistonHeadsVehicle failed:', response.statusText);
          return {};
        }

        const data = await response.json();
        if (!data) return {};
        
        // Only return fields that exist in the response
        return {
          vrm: data.vrm?.toUpperCase() || '',
          registrationYear: data.year || '',
          make: data.make || '',
          model: data.model || '',
          price: data.price || 0,
          mileage: data.mileage || 0,
          fuelType: data.fuel || '',
          transmission: data.transmission || '',
          colour: data.colour || '',
          imageUrl: data.thumbnailImage || '',
          vdpUrl: data.vdpUrl || '',
          dealerInfoUrl: data.dealerInfoUrl || '',
          trim: data.trim || ''
        };
      } catch (error) {
        // Log error but return empty object instead of throwing
        console.warn('Error in getPistonHeadsVehicle:', error);
        return {};
      }
    },

    /**
     * Sets vehicle details from multiple possible sources:
     * 1. PistonHeads API (when on PistonHeads domain)
     * 2. URL query parameters (for direct integrations)
     * 
     * Query Parameter Support:
     * - vrm: Vehicle registration mark
     * - year: Registration year
     * - make: Vehicle manufacturer
     * - model: Vehicle model
     * - price: Vehicle price
     * - mileage: Vehicle mileage
     * - fuel: Fuel type
     * - transmission: Transmission type
     * - colour: Vehicle colour
     * - imageurl: Vehicle image URL
     * - vdpurl: Vehicle details page URL
     * - dealerinfourl: Dealer information URL
     * - vehicleid: Unique vehicle identifier
     * - trim: Vehicle trim
     * 
     * @throws {Error} When validation fails during vehicle setup
     */
    async setVehicle() {
      try {
        // Check if any relevant query parameters exist
        const hasQueryParams = [
          'vrm', 'year', 'make', 
          'model', 'Model',
          'price', 'mileage',
          'fuel', 'transmission', 'colour',
          'imageurl', 'imageUrl',
          'vdpurl', 'vdpUrl',
          'dealerinfourl', 'dealerInfoUrl',
          'vehicleid', 'vehicleId', 'trim'
        ].some(param => RequestHelper.getQueryVariable(param));

        // If no query params and not PistonHeads, exit early
        if (!hasQueryParams && LocationHelper.getHost() !== 'pistonheads') {
          console.log('No vehicle query parameters found');
          return;
        }

        let vehicle: Partial<Vehicle> = {};
        
        if (LocationHelper.getHost() === 'pistonheads') {
          vehicle = await this.getPistonHeadsVehicle();
        } else {
          vehicle = {
            vehicleId: RequestHelper.getQueryVariable('vehicleId') || RequestHelper.getQueryVariable('vehicleid') 
              ? Number(RequestHelper.getQueryVariable('vehicleId') || RequestHelper.getQueryVariable('vehicleid')) 
              : null,
            vrm: RequestHelper.getQueryVariable('vrm')?.toUpperCase(),
            registrationYear: RequestHelper.getQueryVariable('year'),
            make: RequestHelper.getQueryVariable('make'),
            model: RequestHelper.getQueryVariable('Model') || RequestHelper.getQueryVariable('model'),
            price: RequestHelper.getQueryVariable('price'),
            mileage: RequestHelper.getQueryVariable('mileage'),
            fuelType: RequestHelper.getQueryVariable('fuel'),
            transmission: RequestHelper.getQueryVariable('transmission'),
            colour: RequestHelper.getQueryVariable('colour'),
            imageUrl: RequestHelper.getQueryVariable('imageUrl') || RequestHelper.getQueryVariable('imageurl'),
            dealerInfoUrl: RequestHelper.getQueryVariable('dealerInfoUrl') || RequestHelper.getQueryVariable('dealerinfourl'),
            vdpUrl: RequestHelper.getQueryVariable('vdpUrl') || RequestHelper.getQueryVariable('vdpurl'),
            trim: RequestHelper.getQueryVariable('trim')
          };
        }

        if (vehicle.imageUrl) {
            this.cloudinaryVehicleImageUrl = vehicle.imageUrl.includes('cloudinary') 
                ? vehicle.imageUrl 
                : CloudinaryService.getCloudinaryImageUrl(vehicle.imageUrl);
        }

        this.vehicle = { ...this.vehicle, ...vehicle };
        this.vehicleId = RequestHelper.getQueryVariable('vehicleId') || RequestHelper.getQueryVariable('vehicleid')
          ? Number(RequestHelper.getQueryVariable('vehicleId') || RequestHelper.getQueryVariable('vehicleid'))
          : null;
      } catch (error) {
        handleStoreError('vehicle', 'setVehicle', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Updates the Cloudinary-optimized image URL for the vehicle
     * Used for caching and optimizing vehicle images through Cloudinary service
     * 
     * @param {string} url - New Cloudinary-optimized image URL
     */
    setCloudinaryVehicleImageUrl(url: string) {
      this.cloudinaryVehicleImageUrl = url;
    },

    /**
     * Fetches vehicle details from the vehicle API by ID
     * Supports both quote and search endpoints based on configuration
     * 
     * @param {string} vehicleId - Unique identifier for the vehicle
     * @param {boolean} loadVehicleFromQuote - When true, uses quote endpoint instead of search
     * @returns {Promise<{data: Object}>} Vehicle data response
     * @throws {Error} When API request fails or response is invalid
     */
    async getVehicleById(vehicleId: string, loadVehicleFromQuote: boolean) {
      try {
        const configStore = useConfigStore();
        const baseUrl = configStore.isProduction 
          ? URLS.production.apiBaseUrl 
          : URLS.replica.apiBaseUrl;

        const endpoint = loadVehicleFromQuote ? '/v1/vehicle/application' : '/v1/cars';
        const params = loadVehicleFromQuote
          ? `vehicleId=${vehicleId}&applicationFormVehicleTypeId=1`
          : `vehicleId=${vehicleId}`;

        const response = await fetch(`${baseUrl}${endpoint}?${params}`, {
          method: 'GET'
        });

        if(response.status === 404) {
          return { data: 404 };
        } else if (!response.ok) {
          console.error('getVehicleById failed:', response.statusText);
          throw new Error('Failed to getVehicleById');
        }

        const data = await response.json();
        return { data };

      } catch (error) {
        return handleStoreError('vehicle', 'getVehicleById', error, StoreErrorType.NETWORK);
      }
    },

    /**
     * Loads complete vehicle details from the appropriate API endpoint
     * Handles data normalization between quote and search response formats
     * Automatically converts non-Cloudinary images to Cloudinary URLs
     * 
     * Response Handling:
     * - Normalizes different field names between quote and search responses
     * - Handles image URL processing and Cloudinary conversion
     * - Updates store state with complete vehicle information
     * 
     * @throws {Error} When vehicle ID is missing or API request fails
     */
    async loadVehicle() {
      try {
        const configStore = useConfigStore();
        const loadVehicleFromQuote = configStore.affiliateConfig && configStore.affiliateConfig.loadVehicleFromQuote === true ? true : false;
        
        if (!this.vehicleId) {
          throw new Error('Vehicle ID is required');
        }
        
        const response = await this.getVehicleById(this.vehicleId.toString(), loadVehicleFromQuote);
        const responseData = response.data;

        if (responseData === 404) {
          await this.loadDealerVehicleByVrm();
          return ;
        }
        
        // Fix image URL handling
        const imageUrl = loadVehicleFromQuote 
          ? responseData.imageUrl 
          : (responseData.images?.[0] || undefined);


        // Set basic vehicle details based on quote or regular response
        const vehicleData = {
          vehicleId: this.vehicleId,
          vrm: loadVehicleFromQuote ? responseData.vrm : responseData.regNumber,
          fuelType: loadVehicleFromQuote ? responseData.fuelType : responseData.fuel,
          registrationYear: loadVehicleFromQuote ? responseData.regYear : responseData.regYear || responseData.year,
          transmission: loadVehicleFromQuote ? responseData.transmission : responseData.trans,
          make: responseData.make,
          model: responseData.model,
          price: responseData.price,
          mileage: responseData.mileage,
          colour: responseData.colour,
          trim: responseData.trim,
          imageUrl: imageUrl,
          vdpUrl: responseData.vdpUrl || undefined,
          dealerInfoUrl: responseData.dealer?.url || undefined
        };

        // Update vehicle state
        this.vehicle = { ...this.vehicle, ...vehicleData };

        // Set Cloudinary image URL if available
        if (imageUrl && !imageUrl.includes('cloudinary')) {
          this.cloudinaryVehicleImageUrl = CloudinaryService.getCloudinaryImageUrl(imageUrl);
        }

      } catch (error) {
        handleStoreError('vehicle', 'loadVehicle', error, StoreErrorType.NETWORK);
      }
    },

    setFormVehicle( vrm, fuelType, regYear, transmission, imageUrl) {
      // store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(vrm, null), 'form.vehicle.vrm']);
      // store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(fuelType, null), 'form.vehicle.fuel']);
      // store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(regYear, null), 'form.vehicle.year']);
      // store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(transmission, null), 'form.vehicle.transmission']);
      // store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(imageUrl, null), 'form.vehicle.imageUrl']);

      var updatedVehicle = { vrm: ObjectHelper.defaultIfUndefinedOrNull(vrm, null), 
        fuel: ObjectHelper.defaultIfUndefinedOrNull(fuelType, null),
        regYear: ObjectHelper.defaultIfUndefinedOrNull(regYear, null),
        transmission: ObjectHelper.defaultIfUndefinedOrNull(transmission, null),
        imageUrl: ObjectHelper.defaultIfUndefinedOrNull(imageUrl, null),
      };
      this.vehicle = { ...this.vehicle, ...updatedVehicle };
    },

    /**
     * Loads vehicles from dealer feed by dealer ID
     * Fetches vehicles from the dealer feed API and updates store state
     * 
     * @param {string} did - Dealer ID to fetch vehicles for
     * @throws {Error} When API request fails or response is invalid
     */
    async loadDealerFeedVehicle(did: string) {
      try {
        const configStore = useConfigStore();
        const baseUrl = configStore.isProduction 
          ? URLS.production.apiBaseUrl 
          : URLS.replica.apiBaseUrl;

        const initialResponse = await fetch(`${baseUrl}/vehicles/cars`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            postcode: null,
            mileage: null,
            transId: null,
            priceMin: null,
            priceMax: null,
            engineMin: null,
            engineMax: null,
            colourIds: [],
            dealerId: did,
            pageSize: 999,
            page: 1,
            searchPublisherIds: [1, 5]
          })
        }
        );
        if (!initialResponse.ok) {
          throw new Error(`Failed to load dealer feed vehicles: ${initialResponse.statusText}`);
        }

        const initialData = await initialResponse.json() as DotCMSPageComponent;
        if (!initialData) {
          throw new Error('No vehicle data found in dealer feed response');
        }

        this.fullVehicleList = initialData.vehicles || [];
        this.isFullVehicleSearchDataLoaded = true;

      } catch (error) {
        handleStoreError('vehicle', 'loadDealerFeedVehicle', error, StoreErrorType.NETWORK);
      }
    },

    /**
     * Loads dealer vehicle details by VRM (Vehicle Registration Mark)
     * Fetches vehicle data and updates store state with normalized vehicle information
     * 
     * @throws {Error} When VRM lookup fails or response processing fails
     */
    async loadDealerVehicleByVrm(): Promise<void> {
      try {
        const response = await this.getVehicleByVrm(this.vehicle.vrm);
        const responseData = response.data;

        // Extract image URL from response
        const imageUrl = responseData.images?.[0];
        const vehicleId = responseData.vehicleId;

        // Update form-specific vehicle data
        this.setFormVehicle(
          responseData.regNumber,
          responseData.fuel,
          responseData.year,
          responseData.trans,
          imageUrl
        );

        // Process Cloudinary image if needed
        if (imageUrl) {
            // If it's already a Cloudinary URL, use it directly
            this.cloudinaryVehicleImageUrl = imageUrl.includes('cloudinary') 
                ? imageUrl 
                : CloudinaryService.getCloudinaryImageUrl(imageUrl);
            console.log('cloudinaryVehicleImageUrl', this.cloudinaryVehicleImageUrl);
        }

        if (vehicleId) {
          this.vehicleId = vehicleId;
        }

        // Update vehicle state with all available data
        this.vehicle = {
          ...this.vehicle,
          vehicleId: this.vehicleId,
          vrm: responseData.vrm ?? responseData.registration,
          fuelType: ObjectHelper.defaultIfUndefinedOrNull(responseData.fuel, null),
          registrationYear: responseData.registrationDate 
            ? ObjectHelper.defaultIfUndefinedOrNull(
                new Date(responseData.registrationDate).getFullYear(), 
                responseData.year || null
              )
            : responseData.year || null,
          transmission: ObjectHelper.defaultIfUndefinedOrNull(responseData.transmission, null),
          make: responseData.make,
          model: responseData.model,
          price: responseData.price,
          mileage: responseData.mileage,
          colour: responseData.colour,
          trim: responseData.trim,
          imageUrl,
          vdpUrl: responseData.vdpUrl,
          dealerInfoUrl: responseData.dealer?.url
        };

      } catch (error) {
        handleStoreError('vehicle', 'loadDealerVehicleByVrm', error, StoreErrorType.NETWORK);
        throw error; // Re-throw to allow caller to handle the error if needed
      }
    },

    /**
     * Fetches vehicle details by VRM (Vehicle Registration Mark)
     * 
     * @param {string} vrm - Vehicle registration mark
     * @returns {Promise<{data: Object}>} Vehicle data response
     * @throws {Error} When API request fails or response is invalid
     */
    async getVehicleByVrm(vrm: string): Promise<{ data: Record<string, any> }> {
      try {
        const configStore = useConfigStore();
        const baseUrl = configStore.isProduction 
          ? URLS.production.apiBaseUrl 
          : URLS.replica.apiBaseUrl;

        const response = await fetch(
          `${baseUrl}/vehicle-search?vrm=${encodeURIComponent(vrm)}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }
          }
        );

        if (!response.ok) {
          console.error('getVehicleByVrm failed:', response.statusText);
          throw new Error('Failed to get vehicle by VRM');
        }

        const data = await response.json();
        return { data };

      } catch (error) {
        return handleStoreError('vehicle', 'getVehicleByVrm', error, StoreErrorType.NETWORK);
      }
    },
    /**
     * Loads vehicle details using VRM and updates store state
     * Handles data normalization and Cloudinary image conversion
     * 
     * @param {string} vrm - Vehicle registration mark
     * @throws {Error} When VRM lookup fails or response is invalid
     */
    async loadVehicleByVrm(vrm: string) {
      try {
        
        // Check if daid query parameter exists
        const daid = RequestHelper.getQueryVariable('daid');
        if (daid) {
          this.loadDealerVehicleByVrm();
        }

        const response = await this.getVehicleByVrm(vrm);
        const responseData = response.data;

        // Set basic vehicle details from VRM lookup response
        const vehicleData = {
          vehicleId: responseData.vehicleId ? responseData.vehicleId : responseData.capId || null,
          vrm: responseData.registration ? responseData.registration?.toUpperCase() : responseData.vehicleRegistration.toUpperCase(),
          make: responseData.make,
          model: responseData.model,
          colour: responseData.colour,
          registrationYear: responseData.registrationDate ? new Date(responseData.registrationDate).getFullYear().toString() : responseData.year,
          fuelType: responseData.fuel,
          transmission: responseData.transmission,
          bodyType: responseData.bodyType,
          engineCapacity: responseData.engineCapacity,
          vin: responseData.vin,
          doorplan: responseData.doorplan,
          manufactureDate: responseData.manufactureDate,
          // Set default values for fields not in VRM response
          price: this.vehicle.price || 0,
          mileage: this.vehicle.mileage || 0,
          imageUrl: this.vehicle.imageUrl || '',
          vdpUrl: this.vehicle.vdpUrl || '',
          dealerInfoUrl: this.vehicle.dealerInfoUrl || '',
          trim: this.vehicle.trim || ''
        };

        // Update vehicle state
        this.vehicle = { ...this.vehicle, ...vehicleData };

        // Handle Cloudinary image if one exists
        if (this.vehicle.imageUrl && !this.vehicle.imageUrl.includes('cloudinary')) {
          this.cloudinaryVehicleImageUrl = CloudinaryService.getCloudinaryImageUrl(this.vehicle.imageUrl);
        }

        if (vehicleData.vehicleId) {
          this.vehicleId = vehicleData.vehicleId;
        }

      } catch (error) {
        handleStoreError('vehicle', 'loadVehicleByVrm', error, StoreErrorType.NETWORK);
      }
    },
    /**
     * Updates vehicle data directly with provided vehicle object
     * @param {Partial<Vehicle>} vehicleData - Vehicle data to update
     */
    updateVehicle(vehicleData: Partial<Vehicle>) {
      this.vehicle = { ...this.vehicle, ...vehicleData };
      this.vehicleId = vehicleData.vehicleId || null;
    },
  }
});