/**
 * @fileoverview UTM store module for managing marketing tracking parameters
 * Handles both cookie-based and URL-based UTM parameters with fallback logic
 */

import { defineStore } from 'pinia';
import { RequestHelper, CookieHelper, RegExHelper } from "@/helpers";
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import type { UTMState } from '@/types/utmTypes';

/**
 * Pinia store for UTM and tracking parameter management
 * Provides centralized state management for marketing attribution and visitor tracking
 * 
 * Features:
 * - Cookie-based persistence with URL parameter fallback
 * - Support for multiple tracking systems (Google, Facebook, etc.)
 * - Automatic campaign ID extraction from mkwid
 * 
 * @store utm
 */
export const useUTMStore = defineStore('utm', {
  /**
   * Initial state of the UTM store
   * @returns {UTMState} The UTM's initial state with null values
   */
  state: (): UTMState => ({
    utmParameters: {
      agentPublicId: '',           // Unique identifier for advisor/agent
      campaign: '',                // Marketing campaign identifier
      id: '',                      // Extracted campaign ID from mkwid
      content: '',                 // Specific content variation
      gclid: '',                   // Google Ads click tracking
      // fbclid: '',                  // TODO: Facebook click tracking (Not in the payload)
      medium: '',                  // Marketing channel (e.g., cpc, email)
      mkwid: '',                   // Marketing keyword identifier
      referrerApplicationId: '',    // ID of referring application
      sid: '',                     // Session tracking identifier
      source: '',                  // Traffic source platform/site
      term: '',                    // Search keywords or terms
      type: '',                    // Campaign type classification
      // visitorId: ''                // TODO: Unique visitor identifier (Not in the payload)
    }
  }),

  actions: {
    /**
     * Initializes UTM parameters from available sources
     * Prioritizes cookie data over URL parameters for consistency across sessions
     * 
     * @throws {Error} When parameter validation fails
     */
    setUTMParams() {
      try {
        const utmCookie = CookieHelper.get('cf247-utm');
        
        if (utmCookie) {
          this.setUTMParamsFromCookie(utmCookie);
        } else {
          this.setUTMParamsFromURL();
        }
      } catch (error) {
        handleStoreError('utm', 'setUTMParams', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Populates UTM parameters from stored cookie data
     * Maintains certain parameters (sid, agentPublicId) from existing state
     * 
     * @param {string} utmCookie - Stringified JSON containing stored UTM data
     * @throws {Error} When cookie parsing or validation fails
     */
    setUTMParamsFromCookie(utmCookie: string) {
      try {
        const cookie = JSON.parse(utmCookie);
        if (!cookie) {
          throw new Error('Invalid UTM cookie data');
        }

        this.utmParameters = {
          source: cookie['UtmSource'],
          medium: cookie['UtmMedium'],
          term: cookie['UtmTerm'],
          content: cookie['UtmContent'],
          campaign: cookie['UtmCampaign'],
          type: cookie['UtmType'],
          sid: this.utmParameters.sid,
          mkwid: cookie['Mkwid'],
          gclid: cookie['Gclid'],
          // fbclid: cookie['Fbclid'],
          agentPublicId: this.utmParameters.agentPublicId,
          id: RequestHelper.getQueryVariable('id') || '',
          referrerApplicationId: cookie['Rai'],
          // visitorId: null
        };
      } catch (error) {
        handleStoreError('utm', 'setUTMParamsFromCookie', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Extracts and sets UTM parameters from URL query string
     * Handles both utm_prefixed and legacy non-prefixed parameter names
     * Automatically extracts campaign ID from mkwid parameter if present
     * 
     * Example URL parameters:
     * - utm_source=google
     * - utm_medium=cpc
     * - mkwid=campaignid|123456
     * 
     * @throws {Error} When URL parameter validation fails
     */
    setUTMParamsFromURL() {
      try {
        const params = {
          source: RequestHelper.getQueryVariable('utm_source'),
          medium: RequestHelper.getQueryVariable('utm_medium') || RequestHelper.getQueryVariable('medium'),
          term: RequestHelper.getQueryVariable('utm_term') || RequestHelper.getQueryVariable('term'),
          content: RequestHelper.getQueryVariable('utm_content') || RequestHelper.getQueryVariable('content'),
          campaign: RequestHelper.getQueryVariable('utm_campaign') || RequestHelper.getQueryVariable('campaign'),
          type: RequestHelper.getQueryVariable('utm_type') || RequestHelper.getQueryVariable('type'),
          sid: RequestHelper.getQueryVariable('utm_sid'),
          mkwid: RequestHelper.getQueryVariable('mkwid'),
          agentPublicId: RequestHelper.getQueryVariable('advisor'),
          gclid: RequestHelper.getQueryVariable('gclid'),
          fbclid: RequestHelper.getQueryVariable('fbclid'),
          referrerApplicationId: RequestHelper.getQueryVariable('rai'),
        };

        this.utmParameters = {
          ...this.utmParameters,
          ...params,
          id: params.mkwid ? RegExHelper.extract(params.mkwid, /campaignid\|(\d+)/i) : ''
        };
      } catch (error) {
        handleStoreError('utm', 'setUTMParamsFromURL', error, StoreErrorType.VALIDATION);
      }
    }
  }
}); 