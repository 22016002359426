/**
 * Represents the URL configuration for a single environment
 * Contains all API endpoints needed by the application
 */
type Environment = {
    allstarsApiUrl: string;
    errorApiUrl: string;
    applicationApiFullUrl: string;
    applicationApiPartialUrl: string;
    vehicleApiUrl: string;
    vehicleSearchApiUrl: string;
    authApiUrl: string;
    attributionApiUrl: string;
    featureSwitchingApiUrl: string;
    afdPostcodeLookup: string;
    dotCMSApiUrl: string;
    pistonheadsCarAdvertApiUrl: string;
    dealerApi: string;
    apiBaseUrl: string;
};

/**
 * Complete URL configuration for all environments
 * Currently supports 'replica' (development) and 'production' environments
 */
type UrlConfig = {
    /** Development/testing environment URLs */
    replica: Environment;
    /** Production environment URLs */
    production: Environment;
};

/**
 * URL configuration for all application environments
 * Use this to access API endpoints throughout the application
 * 
 * @example
 * // Get the auth API URL for the current environment
 * const authUrl = urlConfig[currentEnvironment].authApiUrl;
 * 
 * @example
 * // Access production API endpoint
 * const prodApiUrl = urlConfig.production.applicationApiFullUrl;
 */
const urlConfig: UrlConfig = {
    replica: {
        allstarsApiUrl: 'https://allstars-api.replica.cf247.net',
        errorApiUrl: 'https://errors.replica.cf247.net',
        applicationApiFullUrl: 'https://application-submission.replica.cf247.net',
        applicationApiPartialUrl: 'https://cf247-submission-partial-api-dev.azurewebsites.net',
        vehicleApiUrl: 'https://vehicles.replica.cf247.net',
        vehicleSearchApiUrl: 'https://vehicles-search.replica.cf247.net',
        authApiUrl: 'https://authorization.replica.cf247.net',
        attributionApiUrl: 'https://application-attribution.replica.cf247.net',
        featureSwitchingApiUrl: 'https://feature-switching.replica.cf247.net',
        afdPostcodeLookup: 'https://postcode-lookup-dev.cf247.workers.dev',
        dotCMSApiUrl: 'https://www.carfinance247.co.uk',
        pistonheadsCarAdvertApiUrl: 'https://apim-submission-dev.azure-api.net/pistonheads',
        dealerApi: 'https://apim.247dev.io/api/dealer',
        apiBaseUrl: 'https://apim.247dev.io/api/apply/v1'
    },
    production: {
        allstarsApiUrl: 'https://allstars-api.carfinance247.co.uk',
        errorApiUrl: 'https://errors.carfinance247.co.uk',
        applicationApiFullUrl: 'https://application-submission.carfinance247.co.uk',
        applicationApiPartialUrl: 'https://application-partial.carfinance247.co.uk',
        vehicleApiUrl: 'https://vehicles.carfinance247.co.uk',
        vehicleSearchApiUrl: 'https://vehicles-search.carfinance247.co.uk',
        authApiUrl: 'https://authorization.carfinance247.co.uk',
        attributionApiUrl: 'https://application-attribution.carfinance247.co.uk',
        featureSwitchingApiUrl: 'https://feature-switching.carfinance247.co.uk',
        afdPostcodeLookup: 'https://postcode-lookup.carfinance247.co.uk',
        dotCMSApiUrl: 'https://www.carfinance247.co.uk',
        pistonheadsCarAdvertApiUrl: 'https://apim-submission-prod.azure-api.net/pistonheads',
        dealerApi: 'https://apim.247prod.io/api/dealer',
        apiBaseUrl: 'https://apim.247prod.io/api/apply/v1'
    }
} as const;

export type { Environment, UrlConfig };
export default urlConfig;
