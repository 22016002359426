import { isEmpty, cloneDeep } from 'lodash';
import { DEFAULT_VALUE } from '@/constants';
import type { FormState, Address, Employment, MappedResult } from '@/types/formTypes';
import type { DeviceState } from '@/types/deviceTypes';
import type { VehicleState } from '@/types/vehicleTypes';
import type { ConfigState  } from '@/types/configTypes';
import type { UTMState } from '@/types/utmTypes';
import * as Sentry from '@sentry/browser';

export class ApplicationDataMapper {
  private static readonly UK_PREFIXES = ['+44', '044', '44'] as const;
  private static readonly CHARS_TO_REMOVE = /[\+,\.\D]/g;
  private static readonly ONLY_DIGITS = /^\d+$/;
  private static readonly STARTS_WITH_ZERO = /^0[0-9].*$/;
  private static readonly MAX_YEARS = 10;
  private static readonly MAX_MONTHS = 11;
  private static readonly REQUIRED_ADDRESS_MONTHS = 36;
  private static readonly REQUIRED_EMPLOYMENT_MONTHS = 12;
  private static readonly DEFAULT_STRING = '';
  private static readonly SUBDOMAIN_SUFFIX = '.carfinance247.co.uk';
  private static readonly SUBMITTED_BY = 'Apply Form';

  private static getStringOrDefault(value: string | null | undefined): string {
    return value || this.DEFAULT_STRING;
  }

  private static calculateTotalMonths(years: number | null | undefined, months: number | null | undefined): number {
    return ((years || 0) * 12) + (months || 0);
  }

  private static mapContactNumber(contactNumber: string | null | undefined): string | null {
    if (!contactNumber) return null;
    
    // Clean the number
    const cleanNumber = contactNumber.replace(this.CHARS_TO_REMOVE, '');
    
    // Check if it's a valid format after cleaning
    if (!this.ONLY_DIGITS.test(cleanNumber)) {
      try {
        Sentry.captureMessage(`Invalid phone number format after cleaning: ${contactNumber}`);
      } catch (err) {
        console.warn('Sentry error:', err);
      }
      return contactNumber;
    }

    // Ensure number starts with zero
    return this.STARTS_WITH_ZERO.test(cleanNumber) 
      ? cleanNumber 
      : `0${cleanNumber}`;
  }

  private static removeEmptyValues(obj: Record<string, any>): void {
    Object.keys(obj).forEach((key) => obj[key] === null && delete obj[key]);
  }

  private static mapAddresses(addresses: Address[]): MappedResult['addresses'] {
    let addressLevel = 0;
    let totalMonths = 0;

    const mappedAddresses = addresses.reduce((acc, item) => {
      this.removeEmptyValues(item);

      if (!isEmpty(item)) {
        const address = {
          addressLevel,
          subBuilding: this.getStringOrDefault(item.subBuilding),
          buildingName: this.getStringOrDefault(item.buildingName),
          buildingNumber: this.getStringOrDefault(item.buildingNumber),
          locality: this.getStringOrDefault(item.locality),
          town: this.getStringOrDefault(item.town),
          postcode: this.getStringOrDefault(item.postcode),
          yearsAtAddress: Math.min(item.yearsAtAddress || 0, this.MAX_YEARS),
          monthsAtAddress: Math.min(item.monthsAtAddress || 0, this.MAX_MONTHS),
          residentialStatusId: item.residentialStatusId,
          county: this.getStringOrDefault(item.county),
          street: this.getStringOrDefault(item.street),
          flatNumber: this.getStringOrDefault(!item.flatNumber?.length ? item.subBuilding : item.flatNumber),
          companyName: this.getStringOrDefault(item.companyName),
        };

        if (item.companyName) {
          if (!address.subBuilding) {
            address.subBuilding = item.companyName;
          } else {
            address.buildingName = item.companyName;
          }
        }

        totalMonths += this.calculateTotalMonths(address.yearsAtAddress, address.monthsAtAddress);
        acc.push(address);
        addressLevel++;
      }

      return totalMonths >= this.REQUIRED_ADDRESS_MONTHS ? acc : acc;
    }, [] as MappedResult['addresses']);
    
    if (totalMonths < this.REQUIRED_ADDRESS_MONTHS) {
      try {
        Sentry.captureMessage(`Address history insufficient: ${totalMonths}/${this.REQUIRED_ADDRESS_MONTHS} months`);
      } catch (err) {
        console.warn('Sentry error:', err);
      }
    }
    
    return mappedAddresses;
  }

  private static mapEmployments(employments: Employment[]): MappedResult['employments'] {
    let employmentLevel = 0;
    let totalMonths = 0;

    const mappedEmployments = employments.reduce((acc, item) => {
      this.removeEmptyValues(item);

      if (!isEmpty(item)) {
        const employment = {
          employmentLevel,
          statusId: item.statusId,
          yearsAtEmployer: Math.min(item.yearsAtEmployer || 0, this.MAX_YEARS),
          monthsAtEmployer: Math.min(item.monthsAtEmployer || 0, this.MAX_MONTHS),
          occupation: item.occupation,
          employerName: item.employerName,
          employerTown: item.employerTown,
          subBuilding: item.subBuilding,
          buildingName: item.buildingName,
          buildingNumber: item.buildingNumber,
          locality: item.locality,
          postcode: item.postcode,
          county: item.county,
          street: item.street,
          flatNumber: item.flatNumber,
        };

        totalMonths += this.calculateTotalMonths(employment.yearsAtEmployer, employment.monthsAtEmployer);
        acc.push(employment);
        employmentLevel++;
      }

      return totalMonths >= this.REQUIRED_EMPLOYMENT_MONTHS ? acc : acc;
    }, [] as MappedResult['employments']);
    
    if (totalMonths < this.REQUIRED_EMPLOYMENT_MONTHS) {
      try {
        Sentry.captureMessage(`Employment history insufficient: ${totalMonths}/${this.REQUIRED_EMPLOYMENT_MONTHS} months`);
      } catch (err) {
        console.warn('Sentry error:', err);
      }
    }
    
    return mappedEmployments;
  }

  private static mapVehicle(vehicle: VehicleState['vehicle']): MappedResult['vehicle'] | undefined {
    if (!vehicle) return undefined;

    return {
      vehicleId: vehicle.vehicleId ? Number(vehicle.vehicleId) : null,
      vrm: vehicle.vrm ?? null,
      registrationYear: vehicle.registrationYear ?? null,
      make: vehicle.make ?? null,
      model: vehicle.model ?? null,
      price: vehicle.price ?? null,
      mileage: vehicle.mileage ?? null,
      fuelType: vehicle.fuelType ?? null,
      transmission: vehicle.transmission ?? null,
      colour: vehicle.colour ?? null,
      imageUrl: vehicle.imageUrl ?? null,
      vdpUrl: vehicle.vdpUrl ?? null,
      dealerInfoUrl: vehicle.dealerInfoUrl ?? null,
    };
  }

  private static mapVehicleFinance(vehicleFinance: FormState['vehicleFinance']): MappedResult['vehicleFinance'] | undefined {
    if (!vehicleFinance || isEmpty(vehicleFinance)) return undefined;

    const mapped = {
      ...vehicleFinance,
      term: this.normalizeNegativeNumber(vehicleFinance.term) ?? 0,
      deposit: this.normalizeNegativeNumber(vehicleFinance.deposit) ?? 0,
      partExchangeValue: this.normalizeNegativeNumber(vehicleFinance.partExchangeValue) ?? 0,
      partExchangeSettlement: this.normalizeNegativeNumber(vehicleFinance.partExchangeSettlement) ?? 0,
      estimatedAnnualMileage: this.normalizeNegativeNumber(vehicleFinance.estimatedAnnualMileage) ?? 0,
    };

    // Return undefined if all numeric values are 0
    if (mapped.term === 0 &&
        mapped.deposit === 0 &&
        mapped.partExchangeValue === 0 &&
        mapped.partExchangeSettlement === 0 &&
        mapped.estimatedAnnualMileage === 0) {
      return undefined;
    }

    return mapped;
  }

  private static mapUtmParameters(utmParameters: FormState['utmParameters'] | undefined): MappedResult['utmParameters'] {
    // Always return an object with default values to prevent (void 0) errors
    const defaultUtmParams = {
      source: "",
      medium: "",
      term: "",
      content: "",
      campaign: "",
      type: "",
      id: "",
      sid: "",
      mkwid: "",
      agentPublicId: "",
      gclid: "",
      referrerApplicationId: ""
    };

    if (!utmParameters) {
      try {
        Sentry.captureMessage("UTM parameters missing, using default empty values");
      } catch (err) {
        console.warn('Sentry error:', err);
      }
      return defaultUtmParams;
    }

    return {
      source: utmParameters.source ?? "",
      medium: utmParameters.medium ?? "",
      term: utmParameters.term ?? "",
      content: utmParameters.content ?? "",
      campaign: utmParameters.campaign ?? "",
      type: utmParameters.type ?? "",
      id: utmParameters.id ?? "",
      sid: utmParameters.sid ?? "",
      mkwid: utmParameters.mkwid ?? "",
      agentPublicId: utmParameters.agentPublicId ?? "",
      gclid: utmParameters.gclid ?? "",
      referrerApplicationId: utmParameters.referrerApplicationId ?? "",
    };
  }

  private static normalizeNegativeNumber(value: number | null | undefined): number | null {
    if (value !== null && value < 0) {
      try {
        Sentry.captureMessage(`Negative value normalized to null: ${value}`);
      } catch (err) {
        console.warn('Sentry error:', err);
      }
      return null;
    }
    return value === undefined ? null : value;
  }

  private static getUrlReferrer(subdomain?: string): string {
    return subdomain ? `${subdomain}${this.SUBDOMAIN_SUFFIX}` : window.location.host;
  }

  static map(state: { 
    form: FormState; 
    subdomain?: string; 
    deviceStore: DeviceState;
    vehicleStore: VehicleState;
    utmStore?: UTMState;
    configStore: ConfigState;
  }): MappedResult {
    console.log('🔄 ApplicationDataMapper: Starting mapping process');
    
    if (!state?.form) {
      console.error('🔄 ApplicationDataMapper: Invalid state object - form is missing');
      Sentry.captureException(new Error("Invalid state object passed to ApplicationDataMapper.map"));
      return {} as MappedResult;
    }

    const { form, deviceStore, vehicleStore, configStore } = state;
    const utmStore = state.utmStore || { utmParameters: undefined };
    
    console.log('🔄 ApplicationDataMapper: Input state overview', {
      formKeys: Object.keys(form),
      hasAddresses: !!form.addresses?.length,
      addressCount: form.addresses?.length || 0,
      hasEmployments: !!form.employments?.length,
      employmentCount: form.employments?.length || 0,
      subdomain: state.subdomain,
      isDealer: configStore.isDealer
    });

    try {
      console.log('🔄 ApplicationDataMapper: Processing loan amount', {
        original: form.loanAmount,
        isNaN: isNaN(form.loanAmount),
        defaultValue: DEFAULT_VALUE.loanAmount
      });
      
      const loanAmount = isNaN(form.loanAmount) ? DEFAULT_VALUE.loanAmount : form.loanAmount;
      if (isNaN(form.loanAmount)) {
        console.warn(`🔄 ApplicationDataMapper: Invalid loan amount (${form.loanAmount}), using default: ${DEFAULT_VALUE.loanAmount}`);
        try {
          Sentry.captureMessage(`Invalid loan amount, using default: ${form.loanAmount}`);
        } catch (err) {
          console.warn('🔄 Sentry error:', err);
        }
      }
      
      console.log('🔄 ApplicationDataMapper: Building core application data');
      const result: Partial<MappedResult> = {
        referralId: form.referralId ?? undefined,
        clickId: form.clickId ?? undefined,
        quoteId: form.quoteId ?? undefined,
        riskBand: form.riskBand ?? undefined,
        loanAmount: loanAmount ?? DEFAULT_VALUE.loanAmount,
        firstName: this.getStringOrDefault(form.firstName),
        lastName: this.getStringOrDefault(form.lastName),
        maritalStatusId: form.maritalStatusId ?? undefined,
        email: this.getStringOrDefault(form.email),
        contactNumber: this.getStringOrDefault(this.mapContactNumber(form.contactNumber)),
        dateOfBirth: form.dateOfBirth ?? undefined,
        titleId: form.titleId ?? undefined,
        vehicleTypeId: form.vehicleTypeId ?? undefined,
        drivingLicenceTypeId: form.drivingLicenceTypeId ?? undefined,
        netMonthlyIncome: form.netMonthlyIncome ?? undefined,
        agreedTermsAndConditions: form.agreedTermsAndConditions,
        isPromoEmail: form.isPromoEmail,
        isPromoSms: form.isPromoSms,
        isPromoWhatsApp: form.isPromoWhatsApp,
        isPromoTelephone: form.isPromoTelephone,
        isShareInfo: form.IsShareInfo ?? false,
        affiliateUserId: form.affiliateUserId ?? undefined,
        monthlyBudget: form.monthlyBudget ?? undefined,
        loanTypeId: form.loanTypeId ?? undefined,
        submissionTypeId: form.submissionTypeId ?? undefined,
        deviceContextId: form.deviceContextId ?? undefined,
        userAgent: deviceStore.userAgent ?? undefined,
        remoteAddress: deviceStore.remoteAddress ?? undefined,
        urlReferrer: this.getUrlReferrer(state.subdomain),
        appliedDate: form.appliedDate ?? undefined,
        submittedBy: this.SUBMITTED_BY,
      };

      console.log('🔄 ApplicationDataMapper: Core application data', {
        firstName: result.firstName,
        lastName: result.lastName,
        email: result.email,
        contactNumber: result.contactNumber,
        loanAmount: result.loanAmount,
        vehicleTypeId: result.vehicleTypeId,
        agreedTermsAndConditions: result.agreedTermsAndConditions,
        submissionTypeId: result.submissionTypeId,
        urlReferrer: result.urlReferrer,
        isShareInfo: result.isShareInfo,
        IsShareInfo: form.IsShareInfo
      });

      // Log important information as breadcrumbs
      try {
        console.log(`🔄 Adding Sentry breadcrumb for mapping: ${result.firstName} ${result.lastName}`);
        Sentry.addBreadcrumb({
          category: 'mapping',
          message: `Mapping application for ${result.firstName} ${result.lastName}`
        });
      } catch (err) {
        console.warn('🔄 Sentry error:', err);
      }

      // Map addresses
      if (form.addresses?.length) {
        console.log(`🔄 ApplicationDataMapper: Mapping ${form.addresses.length} addresses`);
        result.addresses = this.mapAddresses(cloneDeep(form.addresses));
        console.log(`🔄 ApplicationDataMapper: Mapped ${result.addresses.length} addresses`);
        
        // Log first address details for debugging
        if (result.addresses.length > 0) {
          const firstAddress = result.addresses[0];
          console.log('🔄 First address details:', {
            street: firstAddress.street,
            town: firstAddress.town,
            postcode: firstAddress.postcode,
            yearsAtAddress: firstAddress.yearsAtAddress,
            monthsAtAddress: firstAddress.monthsAtAddress,
            residentialStatusId: firstAddress.residentialStatusId
          });
        }
      } else {
        console.warn('🔄 ApplicationDataMapper: No addresses provided for mapping');
        try {
          Sentry.captureMessage("No addresses provided for mapping");
        } catch (err) {
          console.warn('🔄 Sentry error:', err);
        }
      }

      // Map employments
      if (form.employments?.length) {
        console.log(`🔄 ApplicationDataMapper: Mapping ${form.employments.length} employments`);
        result.employments = this.mapEmployments(cloneDeep(form.employments));
        console.log(`🔄 ApplicationDataMapper: Mapped ${result.employments.length} employments`);
        
        // Log first employment details for debugging
        if (result.employments.length > 0) {
          const firstEmployment = result.employments[0];
          console.log('🔄 First employment details:', {
            statusId: firstEmployment.statusId,
            occupation: firstEmployment.occupation,
            employerName: firstEmployment.employerName,
            yearsAtEmployer: firstEmployment.yearsAtEmployer,
            monthsAtEmployer: firstEmployment.monthsAtEmployer
          });
        }
      } else {
        console.warn('🔄 ApplicationDataMapper: No employments provided for mapping');
        try {
          Sentry.captureMessage("No employments provided for mapping");
        } catch (err) {
          console.warn('🔄 Sentry error:', err);
        }
      }

      // Map banks
      if (form.banks?.length) {
        console.log(`🔄 ApplicationDataMapper: Processing ${form.banks.length} banks`);
        const filteredBanks = form.banks.filter(bank => 
          bank && Object.values(bank).some(value => value !== null)
        );
        
        console.log(`🔄 ApplicationDataMapper: After filtering, ${filteredBanks.length} valid banks remain`);

        filteredBanks.map(bank => {
          bank.monthsAtBank = bank.monthsAtBank || 0;
          bank.yearsAtBank = bank.yearsAtBank || 0;
          return bank;
        });

        result.banks = filteredBanks.length ? filteredBanks : [];
        console.log('🔄 ApplicationDataMapper: Final mapped banks:', result.banks);
      } else {
        console.log('🔄 ApplicationDataMapper: No banks to map, setting empty array');
        result.banks = [];
      }

      // Map vehicle finance
      console.log('🔄 ApplicationDataMapper: Mapping vehicle finance data');
      result.vehicleFinance = this.mapVehicleFinance(form.vehicleFinance);
      if (result.vehicleFinance) {
        console.log('🔄 Vehicle finance details:', {
          term: result.vehicleFinance.term,
          deposit: result.vehicleFinance.deposit,
          partExchangeValue: result.vehicleFinance.partExchangeValue
        });
      } else {
        console.log('🔄 No vehicle finance data to map');
      }

      // Map vehicle
      console.log('🔄 ApplicationDataMapper: Mapping vehicle data');
      result.vehicle = this.mapVehicle(vehicleStore.vehicle);
      if (result.vehicle) {
        console.log('🔄 Vehicle details:', {
          vehicleId: result.vehicle.vehicleId,
          make: result.vehicle.make,
          model: result.vehicle.model,
          price: result.vehicle.price
        });
      } else {
        console.log('🔄 No vehicle data to map');
      }

      // Map UTM parameters
      console.log('🔄 ApplicationDataMapper: Mapping UTM parameters');
      result.utmParameters = this.mapUtmParameters(utmStore.utmParameters);
      console.log('🔄 UTM parameters mapped:', result.utmParameters);

      // Handle dealer data
      if (configStore.isDealer) {
        console.log('🔄 ApplicationDataMapper: Setting dealer information');
        let d = {};
        
        d.dealerIntroducer = "Customer";
        d.introducerReference = configStore.dealerAffiliateUserId;
        
        result.dealer = d;
        console.log('🔄 Dealer data:', result.dealer);
      }

      // Special cases for refinance submissions
      if ((state.subdomain === 'ckdirect' && 
            state.utmStore?.utmParameters?.term?.toLowerCase().trim() === 'refinance_2') ||
          (state.subdomain === 'clearscore' && 
            state.utmStore?.utmParameters?.campaign?.toLowerCase().trim() === 'refinance')) {
        console.log('🔄 ApplicationDataMapper: Setting submission type to refinance based on UTM parameters');
        result.submissionTypeId = 1;
        try {
          Sentry.addBreadcrumb({
            category: 'refinance',
            message: 'Setting submission type to refinance based on UTM parameters'
          });
        } catch (err) {
          console.warn('🔄 Sentry error:', err);
        }
      }

      // Include UTM parameters in logs
      try {
        console.log(`🔄 Adding Sentry breadcrumb for UTM: ${JSON.stringify(utmStore)}`);
        Sentry.addBreadcrumb({
          category: 'utm',
          message: `UTM parameters: ${JSON.stringify(utmStore)}`
        });
      } catch (err) {
        console.warn('🔄 Sentry error:', err);
      }

      // Include device context in logs
      try {
        console.log(`🔄 Adding Sentry breadcrumb for device: ${deviceStore.userAgent}`);
        Sentry.addBreadcrumb({
          category: 'device',
          message: `Device context: ${deviceStore.userAgent}`
        });
      } catch (err) {
        console.warn('🔄 Sentry error:', err);
      }

      console.log('🔄 ApplicationDataMapper: Removing empty values from result');
      this.removeEmptyValues(result);
      
      // Add completion breadcrumb instead of finishing transaction
      try {
        console.log('🔄 Adding Sentry breadcrumb for completion');
        Sentry.addBreadcrumb({
          category: 'mapping',
          message: 'Completed application data mapping'
        });
      } catch (err) {
        console.warn('🔄 Sentry error:', err);
      }
      
      console.log('🔄 ApplicationDataMapper: Mapping complete');
      return result as MappedResult;
    } catch (error) {
      console.error('🔄 ApplicationDataMapper: Error during mapping process:', error);
      if (error instanceof Error) {
        console.error('🔄 Error name:', error.name);
        console.error('🔄 Error message:', error.message);
        console.error('🔄 Error stack:', error.stack);
      }
      
      try {
        console.log('🔄 Reporting mapping error to Sentry');
        Sentry.captureException(error);
      } catch (err) {
        console.error('🔄 Failed to capture exception in Sentry:', err);
      }
      throw error;
    }
  }
} 