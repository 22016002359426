import * as Sentry from '@sentry/browser';

export enum StoreErrorType {
  NETWORK = 'NETWORK',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN'
}

export class StoreError extends Error {
  constructor(
    message: string,
    public readonly store: string,
    public readonly action: string,
    public readonly type: StoreErrorType = StoreErrorType.UNKNOWN,
    public readonly originalError?: Error
  ) {
    super(message);
    this.name = 'StoreError';
  }
}

export function handleStoreError(
  store: string, 
  action: string, 
  error: any,
  type?: StoreErrorType
): never {
  const message = error?.response?.data?.message || error.message;
  
  const errorType = type || (
    error?.response ? StoreErrorType.NETWORK :
    error?.validation ? StoreErrorType.VALIDATION :
    StoreErrorType.UNKNOWN
  );

  const storeError = new StoreError(
    `Failed to execute ${action} in ${store} store: ${message}`,
    store,
    action,
    errorType,
    error
  );

  Sentry.captureException(storeError);

  throw storeError;
} 