import { RequestHelper } from '@/helpers/index';
/**
 * @fileoverview Flow management system for the finance application.
 * 
 * Key concepts:
 * - Pages: Individual steps in the application process
 * - Flows: Different journey paths based on user type
 * - Navigation: Linear progression between pages
 * 
 * Flow Types:
 * - direct: Standard journey starting with finance details
 * - prePopulated: Pre-filled journey
 * - partnerWithVehicleId: Partner journey with vehicle
 * - partnerWithVehicleType: Partner journey with vehicle type
 * - partnerWithoutVehicle: Partner journey without vehicle
 * - dealer: Dealer-specific journey
 * 
 * Navigation:
 * - Each page knows its valid next/previous pages
 * - Fallback to 'direct' flow if type is empty
 * - All flows use standard sequence after initial pages
 */

/**
 * Comprehensive list of all possible pages in the application flow.
 * Used to ensure type safety and maintain a single source of truth for page names.
 * @constant {readonly string[]}
 */
export const PAGE_NAMES = [
  'get-my-quote',
  'finance-details',
  'driving-licence',
  'marital-status',
  'date-of-birth',
  'address-add',
  'address-status',
  'address-length',
  'employment-status',
  'employment-location',
  'employment-length',
  'employment-income',
  'amount-to-finance',
  'contact-details-p1',
  'contact-details-p2',
  'prepop-form',
  'car-finance-detail',
  'bank-detail',
  'bank-length',
  'submission-type',
  'refinance-form',
  'dealer-get-my-quote',
  'dealer-search',
  'refinance',
] as const;

/**
 * Type representing valid page names in the application.
 * Derived from PAGE_NAMES array to ensure type safety.
 */
export type PageName = typeof PAGE_NAMES[number];

/**
 * Interface defining the structure of a flow configuration.
 * @interface Flow
 * @property {PageName[]} pages - Ordered array of pages for this flow
 * @property {Record<PageName, PageName[]>} nextPages - Map of current page to possible next pages
 * @property {Record<PageName, PageName[]>} previousPages - Map of current page to possible previous pages
 */
export interface Flow {
  pages: PageName[];
  nextPages: Record<PageName, PageName[]>;
  previousPages: Record<PageName, PageName[]>;
}

/**
 * Types of forms/journeys available in the application.
 * - direct: Full journey starting from the beginning
 * - prePopulated: Journey with some pre-filled information
 * - partnerWithVehicleId: Partner journey including vehicle details
 * - partnerWithVehicleType: Partner journey including vehicle type
 * - partnerWithoutVehicle: Partner journey without vehicle details
 * - dealer: Dealer-specific journey
 */
export type FormType = 'direct' | 'prePopulated' | 'partnerWithVehicleId' | 'partnerWithVehicleType' | 'partnerWithoutVehicle' | 'dealerGetMyQuote' | 'submissionType' | 'dealerSearch' | 'refinance';

/**
 * Creates a linear navigation map for a sequence of pages
 */
function createLinearNavigation(pages: PageName[]) {
  const nextPages: Record<PageName, PageName[]> = {} as Record<PageName, PageName[]>;
  const previousPages: Record<PageName, PageName[]> = {} as Record<PageName, PageName[]>;

  // Initialize all possible pages with empty arrays
  PAGE_NAMES.forEach(page => {
    nextPages[page] = [];
    previousPages[page] = [];
  });

  // Set up navigation for pages in the sequence
  pages.forEach((page, index) => {
    if (index < pages.length - 1) {
      nextPages[page] = [pages[index + 1]];
    }
    if (index > 0) {
      previousPages[page] = [pages[index - 1]];
    }
  });

  return { nextPages, previousPages };
}

/**
 * Common sequence of pages from driving licence to the end
 */
export const STANDARD_SEQUENCE: PageName[] = [
  'driving-licence',
  'marital-status',
  'date-of-birth',
  'address-add',
  'address-status',
  'address-length',
  'employment-status',
  'employment-location',
  'employment-length',
  'employment-income',
  'amount-to-finance',
  'contact-details-p1',
  'contact-details-p2'
];


/**
 * Configuration object defining the page flow for each form type.
 * Each flow specifies the sequence of pages and their navigation options.
 * @constant
 * @type {Record<Exclude<FormType, ''>, Flow>}
 */
export const FLOW_ROUTES: Record<Exclude<FormType, ''>, Flow> = {
  direct: {
    pages: ['finance-details', ...STANDARD_SEQUENCE],
    ...createLinearNavigation(['finance-details', ...STANDARD_SEQUENCE])
  },
  submissionType: {
    pages: ['submission-type', 'driving-licence', ...STANDARD_SEQUENCE],
    ...createLinearNavigation(['submission-type', 'driving-licence', ...STANDARD_SEQUENCE])
  },
  prePopulated: {
    pages: ['prepop-form'],
    ...createLinearNavigation(['prepop-form'])
  },
  partnerWithVehicleId: {
    pages: ['get-my-quote', 'driving-licence', ...STANDARD_SEQUENCE],
    ...createLinearNavigation(['get-my-quote', 'driving-licence', ...STANDARD_SEQUENCE])
  },
  dealerGetMyQuote: (() => {
    const contactDetailsIndex = STANDARD_SEQUENCE.indexOf('contact-details-p1');
    const firstPart = STANDARD_SEQUENCE.slice(0, contactDetailsIndex);
    const lastPart = STANDARD_SEQUENCE.slice(contactDetailsIndex);
    const noBankDetails = RequestHelper.getQueryVariable(['noBankDetails', 'nobankDetails', 'nobankdetails']);
    
    const customSequence: PageName[] = [
      'dealer-get-my-quote',
      'driving-licence',
      ...firstPart,
      'bank-detail',
      'bank-length',
      ...lastPart
    ];

    // Remove bank details if noBankDetails is true
    if (noBankDetails && noBankDetails !== 'false') {
      customSequence.splice(customSequence.indexOf('bank-detail'), 1);
      customSequence.splice(customSequence.indexOf('bank-length'), 1);
    }

    return {
      pages: customSequence,
      ...createLinearNavigation(customSequence)
    };
  })(),
  dealerSearch: {
    pages: ['dealer-search', 'dealer-get-my-quote', 'driving-licence', ...STANDARD_SEQUENCE],
    ...createLinearNavigation(['dealer-search', 'dealer-get-my-quote', 'driving-licence', ...STANDARD_SEQUENCE])
  },
  partnerWithVehicleType: {
    pages: STANDARD_SEQUENCE,
    ...createLinearNavigation(STANDARD_SEQUENCE)
  },
  partnerWithoutVehicle: {
    pages: ['finance-details', ...STANDARD_SEQUENCE],
    ...createLinearNavigation(['finance-details', ...STANDARD_SEQUENCE])
  },
  refinance: {
    pages: ['refinance-form', 'driving-licence', ...STANDARD_SEQUENCE],
    ...createLinearNavigation(['refinance-form',  'driving-licence', ...STANDARD_SEQUENCE])
  }
} as const;

/**
 * Gets the valid next pages for the current page in the specified form type.
 * @param {PageName} currentPage - The current page name
 * @param {FormType} formType - The type of form being used
 * @returns {PageName[]} Array of valid next page names
 * @throws {Error} If form type is invalid or empty
 */
export function getValidNextPages(currentPage: PageName, formType: FormType): PageName[] {
    const resolvedFormType = formType || 'direct';
    
    const flow = FLOW_ROUTES[resolvedFormType];
    if (!flow) {
        throw new Error(`Invalid form type: ${resolvedFormType}`);
    }

    return flow.nextPages[currentPage] || [];
}

/**
 * Gets the valid previous pages for the current page in the specified form type.
 * @param {PageName} currentPage - The current page name
 * @param {FormType} formType - The type of form being used
 * @returns {PageName[]} Array of valid previous page names
 * @throws {Error} If form type is invalid or empty
 */
export function getValidPreviousPages(currentPage: PageName, formType: FormType): PageName[] {
    const resolvedFormType = formType || 'direct';
    
    const flow = FLOW_ROUTES[resolvedFormType];
    if (!flow) {
        throw new Error(`Invalid form type: ${resolvedFormType}`);
    }

    return flow.previousPages[currentPage] || [];
}

/**
 * Gets all pages for a specific form type.
 * @param {FormType} formType - The type of form being used
 * @returns {PageName[]} Array of all pages in the flow
 * @throws {Error} If form type is invalid or empty
 */
export function getFlowPages(formType: FormType): PageName[] {
    const resolvedFormType = formType || 'direct';
    
    const flow = FLOW_ROUTES[resolvedFormType];
    if (!flow) {
        throw new Error(`Invalid form type: ${resolvedFormType}`);
    }

    return flow.pages;
}