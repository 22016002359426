/**
 * @fileoverview Progress store module for managing application progress state and navigation.
 * 
 * This store handles:
 * - Form completion tracking
 * - Page navigation state
 * - Application flow control
 * - Progress calculation
 * - Duplicate application detection
 */

import { defineStore } from 'pinia';
import type { ProgressState } from '@/types/progressTypes';
import { FLOW_ROUTES, type FormType, type PageName } from '@/constants/flows';

/**
 * Progress store for managing application state and navigation flow.
 * 
 * @store progress
 * @description Provides centralized state management for:
 * - Tracking page completion status
 * - Managing navigation between pages
 * - Controlling application flow
 * - Calculating progress percentage
 * - Handling form submission states
 */

export const useProgressStore = defineStore('progress', {
  /**
   * Initial state of the progress store
   * @returns {ProgressState} The progress store's initial state
   * @property {FormType} formType - The type of form being completed
   * @property {string[]} completedPages - Array of completed page identifiers
   * @property {string} pageName - Current page identifier
   * @property {string} previousPage - Last visited page identifier
   * @property {boolean} fromGetMyQuote - Indicates if user came from quote flow
   * @property {boolean} fullAppSuccess - Application submission status
   * @property {boolean} duplicateApp - Duplicate application flag
   * @property {boolean} hasStarted - Indicates if user has begun the flow
   * @property {string|null} membersURL - URL for members area after completion
   * @property {boolean} trackingComplete - Indicates if tracking is complete
   */
  state: (): ProgressState => ({
    formType: '' as FormType,
    completedPages: [] as string[],
    pageName: '',
    previousPage: '',
    fromGetMyQuote: false,
    fullAppSuccess: false,
    duplicateApp: false,
    hasStarted: false,
    membersURL: null,
    trackingComplete: false
  }),

  actions: {
    /**
     * Sets the current page identifier and manages page completion state
     * @param {PageName} name - Page name/identifier to set as current
     * @description
     * - Updates previous page tracking
     * - Manages hasStarted flag
     * - Handles forward/backward navigation
     * - Updates completed pages list based on navigation direction
     */
    setPageName(name: PageName) {
      const newPage = name;
      const oldPage = this.pageName;
      
      if (oldPage !== newPage) {
        this.previousPage = oldPage;
      }
      if (!this.hasStarted) {
        this.hasStarted = true;
      }
      this.pageName = newPage;

      const currentFlow = FLOW_ROUTES[this.formType as Exclude<FormType, ''>];
      if (!currentFlow) {
        return;
      }

      const newPageIndex = currentFlow.pages.indexOf(newPage as PageName);
      const oldPageIndex = currentFlow.pages.indexOf(oldPage as PageName);

      // If we're going backwards, remove pages after the new position
      if (newPageIndex < oldPageIndex) {
        this.completedPages = this.completedPages.filter(page => {
          const pageIndex = currentFlow.pages.indexOf(page as PageName);
          return pageIndex < newPageIndex;
        });
      } 
      // If we're going forwards, mark the previous page as complete
      else if (oldPage) {
        if (!this.completedPages.some(page => page === oldPage)) {
          this.markPageComplete(oldPage as PageName);
        }
      }
    },

    /**
     * Updates the application completion status
     * @param {boolean} success - Application completion status
     */
    setFullAppSuccess(success: boolean) {
      this.fullAppSuccess = success;
    },

    /**
     * Sets the members URL
     * @param {string} url - Members URL
     */
    setMembersURL(url: string | null) {
      this.membersURL = url;
    },

    /**
     * Marker action to trigger analytics tracking via the store plugin
     */
    completeForm() {
      console.log('🚀 Starting completeForm action');
      return new Promise<void>((resolve) => {
        this.fullAppSuccess = true;
        console.log('✅ Form marked as complete, waiting for tracking...');
        resolve();
        console.log('🏁 CompleteForm action finished');
      });
    },

    /**
     * Sets the duplicate application status
     * @param {boolean} duplicate - Duplicate application status
     */
    setDuplicateApp(duplicate: boolean) {
      this.duplicateApp = duplicate;
    },

    /**
     * Marks a page as complete in the current flow
     * @param {PageName} pageName - Page identifier to mark as complete
     * @description
     * - Validates page exists in current flow
     * - Removes any subsequent pages if they were previously completed
     * - Maintains chronological completion order
     */
    markPageComplete(pageName: PageName) {
      const currentFlow = FLOW_ROUTES[this.formType as Exclude<FormType, ''>];
      
      if (!currentFlow) {
        return;
      }

      const currentPageIndex = currentFlow.pages.indexOf(pageName);
      
      if (currentPageIndex === -1) {
        return;
      }

      // Cache the pages array for better performance
      const flowPages = currentFlow.pages;
      
      // Create new array with pages up to and including current page
      this.completedPages = this.completedPages
        .filter(page => flowPages.indexOf(page as PageName) <= currentPageIndex)
        .concat(this.completedPages.includes(pageName) ? [] : [pageName]);
    },

    /**
     * Sets the form type and initializes the corresponding flow
     * @param {Exclude<FormType, ''>} type - The form type to set
     */
    setFormType(type: Exclude<FormType, ''>) {
      this.formType = type;
    },

    setTrackingComplete() {
      this.trackingComplete = true;
    },

    resetTrackingComplete() {
      this.trackingComplete = false;
    }
  },

  getters: {
    /**
     * Calculates the completion percentage of required pages
     * @param {ProgressState} state - Current state
     * @returns {number} Percentage of completion (0-100)
     * @description
     * - Normalizes page names to handle dynamic routes
     * - Calculates percentage based on required pages only
     * - Returns 0 if no valid flow is set
     * - Uses Set for optimized lookup performance
     */
    currentProgress(state): number {
      // Early return if no valid flow is set
      if (!state.formType || !FLOW_ROUTES[state.formType]) {
        return 0;
      }

      const currentFlow = FLOW_ROUTES[state.formType];
      
      // Return early if there are no pages in the flow
      if (!currentFlow.pages.length) {
        return 0;
      }

      // Create a Set for O(1) lookup performance
      const normalizedCompletedPages = new Set(
        state.completedPages.map(page => page.replace(/\/\d+$/, ''))
      );

      const completedRequired = currentFlow.pages.reduce((count, page) => 
        normalizedCompletedPages.has(page) ? count + 1 : count, 
        0
      );

      return Math.floor((completedRequired / currentFlow.pages.length) * 100);
    }
  }
}); 