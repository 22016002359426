/**
 * @fileoverview Application store module for Pinia
 * Manages the application submission process, including state management,
 * data preparation, and API communication.
 * 
 * @module applicationStore
 */

import { defineStore } from 'pinia';
import { ApplicationDataMapper } from '@/utils/ApplicationDataMapper';
import { useConfigStore } from './configStore';
import { useFormStore } from './formStore';
import { useProgressStore } from './progressStore';
import { useDeviceStore } from './deviceStore';
import { useVehicleStore } from './vehicleStore';
import { useUTMStore } from './utmStore';
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import urlConfig from '@/factories/urls-factory';
// import type { ApplicationData } from '@/types/applicationTypes';

/**
 * Response interface for application submission
 * @interface SubmissionResponse
 * @property {number} accountCreationStatusCode - Status code indicating account creation result (2 indicates duplicate)
 * @property {string} membersUrl - URL for redirecting to members area after successful submission
 */
export interface SubmissionResponse {
  accountCreationStatusCode: number;
  membersUrl: string;
}

interface ApplicationState {
  submissionError: Error | null
  referrer: {
    url: string | null
    source: string | null
    timestamp: string | null
  }
  isSubmitting: boolean
  lastSubmissionTime: number
  partialSubmissionFailures: number
  cancelPartialSubmission: boolean
  _fullSubmissionInProgress: boolean
}

const MAX_RETRIES = 1;
const RETRY_DELAY = 1000; // 1 second

/**
 * Pinia store for application submission management
 * Handles the entire application submission workflow including:
 * - State management for submission status
 * - Data preparation and validation
 * - API communication
 * - Error handling
 * - Success state updates
 * 
 * @store application
 */
export const useApplicationStore = defineStore('application', {
  state: (): ApplicationState => ({
    /** Flag indicating if submission is in progress */
    isSubmitting: false,
    /** Stores any submission-related error */
    submissionError: null as Error | null,
    referrer: {
      url: null,
      source: null,
      timestamp: null
    },
    lastSubmissionTime: 0,
    partialSubmissionFailures: 0,
    cancelPartialSubmission: false,
    _fullSubmissionInProgress: false
  }),

  actions: {
    /**
     * Gets a cookie value by name
     * @param {string} name - The name of the cookie to retrieve
     * @returns {string|undefined} The cookie value if found
     */
    getCookie(name: string): string | undefined {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return decodeURIComponent(parts.pop()?.split(';').shift() || '');
    },

    /**
     * Captures referrer information from cookies
     */
    captureReferrer() {
      console.log('📍 Starting referrer capture');
      
      const referrerUrl = decodeURIComponent(this.getCookie('referrerUrl') || '');
      console.log('📍 Decoded referrerUrl:', referrerUrl);
      
      const urlParameters = JSON.parse(decodeURIComponent(this.getCookie('urlParameters') || '{}'));
      console.log('📍 Decoded urlParameters:', urlParameters);

      this.referrer = {
        url: referrerUrl || null,
        source: urlParameters.source || 'direct',
        timestamp: new Date().toISOString()
      };
      console.log('📍 Final referrer object:', this.referrer);
    },

    /**
     * Validates application data for potential HTML/script injection
     * @param {object} data - The application data to validate
     * @throws {Error} If suspicious content is found
     */
    validateApplicationData(data: object) {
      const htmlRegex = /<[^>]*>|&[#\w]+;/g;
      const scriptRegex = /javascript:|data:/i;

      const checkValue = (value: any) => {
        if (typeof value === 'string') {
          if (htmlRegex.test(value) || scriptRegex.test(value)) {
            throw new Error(`Suspicious content detected in application data: ${value}`);
          }
        } else if (typeof value === 'object' && value !== null) {
          Object.values(value).forEach(checkValue);
        }
      };

      checkValue(data);
    },

    /**
     * Submits the completed application to the backend
     * 
     * Process:
     * 1. Prepares application data using ApplicationDataMapper
     * 2. Validates required data (e.g., GUID)
     * 3. Submits to appropriate environment endpoint
     * 4. Handles success/failure scenarios
     * 5. Updates related store states
     * 
     * @returns {Promise<SubmissionResponse>} Object containing submission status and redirect URL
     * @throws {Error} When GUID is missing or submission fails
     * 
     * @example
     * try {
     *   const result = await applicationStore.submitApplication();
     *   // Handle success
     * } catch (error) {
     *   // Handle error
     * }
     */
    async submitApplication(): Promise<SubmissionResponse | undefined> {
      console.log('📋 Starting application submission process');
      
      // IMPORTANT - If this is a full submission already in progress (not a partial one), return undefined
      if (this.isSubmitting && !this.cancelPartialSubmission) {
        console.log('📋 Full submission already in progress, aborting');
        return undefined;
      }

      // If a partial submission is in progress, we need to handle it specially
      if (this.isSubmitting && this.cancelPartialSubmission) {
        console.log('📋 Cancelling in-progress partial submission to start full submission');
        this.isSubmitting = false;
      }

      // Set our cancellation flag to stop any partial submissions in progress
      this.cancelPartialSubmission = true;

      // Added check for concurrent full submissions - maintain original behavior for tests
      // This will only execute if multiple full submissions are attempted simultaneously
      const isFullSubmissionReentry = this._fullSubmissionInProgress === true;
      if (isFullSubmissionReentry) {
        console.log('📋 Concurrent full submission detected, aborting to maintain rate limiting');
        return undefined;
      }
    
      // Mark that we're starting a full submission
      this._fullSubmissionInProgress = true;

      const configStore = useConfigStore();
      const formStore = useFormStore();
      const progressStore = useProgressStore();
      const deviceStore = useDeviceStore();
      const vehicleStore = useVehicleStore();
      const utmStore = useUTMStore();
      const environment = configStore.isProduction ? 'production' : 'replica';
      
      console.log(`📋 Environment: ${environment}`);
      console.log(`📋 GUID: ${configStore.GUID}`);

      let retryCount = 0;

      try {
        console.log('📋 Setting submission state to true');
        this.isSubmitting = true;
        this.submissionError = null;

        // Prepare application data
        console.log('📋 Preparing application data via ApplicationDataMapper');
        const applicationData = ApplicationDataMapper.map({
          form: formStore.$state,
          deviceStore: deviceStore.$state,
          vehicleStore: vehicleStore.$state,
          subdomain: configStore.subdomain || undefined,
          utmStore: utmStore && utmStore.$state ? utmStore.$state : undefined,
          configStore: configStore.$state
        });
        
        console.log('📋 Setting agreedTermsAndConditions and IsCompleted to true');
        applicationData.agreedTermsAndConditions = true;
        applicationData.IsCompleted = true;

        console.log('📋 Form data extract:', {
          loanAmount: formStore.loanAmount,
          vehicleTypeId: formStore.vehicleTypeId,
          titleId: formStore.titleId,
          firstName: formStore.firstName,
          lastName: formStore.lastName,
          addresses: formStore.addresses.length,
          employments: formStore.employments.length,
          netMonthlyIncome: formStore.netMonthlyIncome
        });

        // Validate application data before submission
        console.log('📋 Validating application data for security concerns');
        this.validateApplicationData(applicationData);

        if (!configStore.GUID) {
          console.error('📋 GUID is missing, submission cannot proceed');
          const error = new Error('GUID is required for application submission');
          this.submissionError = error;
          return undefined;
        }

        const apiUrl = `${urlConfig[environment].apiBaseUrl}/submit/${configStore.GUID}`;
        console.log(`📋 Submission URL: ${apiUrl}`);
        
        while (retryCount <= MAX_RETRIES) {
          try {
            console.log(`📋 Attempt ${retryCount + 1}/${MAX_RETRIES + 1} to submit application`);
            
            console.log('📋 Sending application data to API');
            const response = await fetch(
              apiUrl,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(applicationData),
              }
            );

            console.log(`📋 Response status: ${response.status} ${response.statusText}`);
            
            if (!response.ok) {
              console.error(`📋 API responded with error: ${response.status} ${response.statusText}`);
              const responseText = await response.text();
              console.error('📋 Error response body:', responseText);
              throw new Error(`Failed to submit application: ${response.status} ${response.statusText}`);
            }

            console.log('📋 Parsing response JSON');
            const result = await response.json();
            console.log('📋 API response:', result);
            
            // Handle success
            console.log(`📋 Setting membersURL in progressStore: ${result.membersUrl}`);
            progressStore.setMembersURL(result.membersUrl);
            
            console.log('📋 Setting fullAppSuccess to true in progressStore');
            progressStore.setFullAppSuccess(true);

            if (result.accountCreationStatusCode === 2) {
              progressStore.setDuplicateApp(true);
            }
            
            console.log('📋 Submission completed successfully');
            return result;
          } catch (error) {
            console.error('📋 Error during submission attempt:', error);
            
            if (retryCount < MAX_RETRIES) {
              console.log(`📋 Retry attempt ${retryCount + 1}/${MAX_RETRIES}, waiting ${RETRY_DELAY}ms`);
              await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
              retryCount++;
              continue;
            }
            
            console.error('📋 All retry attempts failed');
            throw error;
          }
        }
      } catch (error) {        
        this.submissionError = error as Error;
        handleStoreError('application', 'submitApplication', error, StoreErrorType.NETWORK);
        return undefined;
      } finally {
        console.log('📋 Setting isSubmitting back to false');
        this.isSubmitting = false;
        // Clear the full submission flag
        this._fullSubmissionInProgress = false;
      }

      return undefined;  // Ensure we always return undefined for error cases
    },
    /**
     * Submits the partial application to the backend
     * 
     * Process:
     * 1. Prepares application data using ApplicationDataMapper
     * 2. Validates required data (e.g., GUID)
     * 3. Submits to appropriate environment endpoint
     * 4. Handles success/failure scenarios
     * 5. Updates related store states
     * 
     * @returns {Promise<SubmissionResponse>} Object containing submission status and redirect URL
     * @throws {Error} When GUID is missing or submission fails
     * 
     * @example
     * try {
     *   const result = await applicationStore.submitPartialApplication();
     *   // Handle success
     * } catch (error) {
     *   // Handle error
     * }
     */
    async submitPartialApplication(): Promise<SubmissionResponse> {
      const progressStore = useProgressStore();
      
      // Check if full app already submitted successfully
      if (progressStore.fullAppSuccess) {
        console.log('Full application already submitted successfully, skipping partial submission');
        return {
          accountCreationStatusCode: 0,
          membersUrl: ''
        };
      }

      // Check if full submission is in progress or requested
      if (this.isSubmitting || this.cancelPartialSubmission) {
        console.log('Full submission in progress or requested, skipping partial submission');
        return {
          accountCreationStatusCode: 0,
          membersUrl: ''
        };
      }

      // Add throttling - prevent submissions within 3 seconds of each other (changed from 1 second)
      const now = Date.now();
      const timeSinceLastSubmission = now - this.lastSubmissionTime;
      if (timeSinceLastSubmission <= 1000) { 
        console.log('Throttling partial submission - too soon since last submission');
        return {
          accountCreationStatusCode: 0,
          membersUrl: ''
        };
      }

      // Track consecutive failures to prevent infinite retry loops
      if (!this.partialSubmissionFailures) {
        this.partialSubmissionFailures = 0;
      }
      
      // If we've failed too many times in a row, stop trying
      if (this.partialSubmissionFailures >= 3) {
        console.warn('Too many consecutive partial submission failures, blocking further attempts');
        return {
          accountCreationStatusCode: 0,
          membersUrl: ''
        };
      }

      const configStore = useConfigStore();
      const formStore = useFormStore();
      const deviceStore = useDeviceStore();
      const vehicleStore = useVehicleStore();
      const utmStore = useUTMStore();
      const environment = configStore.isProduction ? 'production' : 'replica';

      try {
        this.isSubmitting = true;
        // Update last submission time
        this.lastSubmissionTime = now;
        
        this.submissionError = null;

        // Prepare application data
        const applicationData = ApplicationDataMapper.map({
          form: formStore.$state,
          deviceStore: deviceStore.$state,
          vehicleStore: vehicleStore.$state,
          subdomain: configStore.subdomain || undefined,
          utmStore: utmStore && utmStore.$state ? utmStore.$state : undefined,
          //referrer: this.referrer,
          configStore: configStore.$state
        });

        // Validate application data before submission
        this.validateApplicationData(applicationData);

        console.log('⭐ Partial Application Data to be submitted:', JSON.stringify(applicationData, null, 2));

        // Ensure GUID is available
        if (!configStore.GUID) {
          const error = new Error('GUID is required for partial application submission');
          throw error;
        }

        const url = `${urlConfig[environment].apiBaseUrl}/submit/partial/${configStore.GUID}`;
        console.log('Submitting partial application to:', url);

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(applicationData),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Partial Application submission failed:', {
            status: response.status,
            statusText: response.statusText,
            responseText: errorText
          });
          throw new Error(`Failed to submit partial application: ${response.status} ${response.statusText}`);
        }

        // If we get here, the submission was successful even if there's no response body
        const responseText = await response.text();
        console.log('⭐ Partial Application submission response:', responseText);
        
        // Reset failure counter on success
        this.partialSubmissionFailures = 0;
        
        if (!responseText) {
          // Return a default successful response instead of throwing an error
          return {
            accountCreationStatusCode: 0, // or whatever default value makes sense
            membersUrl: '' // empty string since we don't have a URL for partial submissions
          };
        }

        try {
          return JSON.parse(responseText);
        } catch (parseError) {
          console.warn('Could not parse response, using default success response:', responseText);
          return {
            accountCreationStatusCode: 0,
            membersUrl: ''
          };
        }
      } catch (error) {
        console.error('Partial Application submission error:', error);
        this.submissionError = error as Error;
        
        // Increment failure counter
        this.partialSubmissionFailures++;
        console.warn(`Partial submission failed ${this.partialSubmissionFailures} times consecutively`);
        
        handleStoreError('application', 'submitPartialApplication', error, StoreErrorType.NETWORK);
        
        // Return a default response rather than throwing to prevent loops
        return {
          accountCreationStatusCode: 0,
          membersUrl: ''
        };
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}); 