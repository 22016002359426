<template>
  <div class="relative mb-2 w-full bg-usp"  :style=" isScrolled ? 'position:fixed;top: 0;z-index:2;width:'+ headerWidth + 'px;' : ''">
    <label :for="name">
      <span class="text-sm font-bold mb-1 block">{{ label }}</span>
    </label>
    
    <div class="relative">
      <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none">
        <Icon
          icon="search"
          icon-color="#000"
          stroke="white"
          :width="26"
          :height="25"
        />
      </div>
      <input
        :id="id"
        :type="type"
        :name="name"
        v-model.trim="value"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :maxlength="maxLength"
        :inputmode="inputMode"
        :pattern="pattern"
        :disabled="disabled"
        :class="[baseInputClass, errorMessage ? 'input-error' : '']"
        v-maska="maska"
        ref="inputRef"
        @input="sanitizeAndUpdateValue"
        @blur="handleBlur"
      >
      <div v-if="value !='' && value != null" class="absolute z-10 inset-y-0 right-4 flex items-center"  @click="handleClearSearch" >
        <div style="width: 20px; height: 20px;border-radius: 50%;background-color: #eee;" class="flex items-center justify-center cursor-pointer">
          <Icon
            icon="close"
            :icon-color="'hsl(var(--twc-dark-1))'"
            :width="12"
            :height="12"
          />
        </div>
      </div>
    </div>

    <p class="text-sm mt-2">Search by registration, make or model</p>
    <p v-if="errorMessage" class="error text-body1">{{ errorMessage }}</p>
    <p v-if="smallPrint" class="mt-2 text-sm text-dark-1 opacity-80 mb-6">{{ smallPrint }}</p>
  </div>
</template>

<script setup lang="ts">
// Imports
import { ref, computed, inject } from 'vue'
import { useField } from 'vee-validate'
import { debounce, uniqueId } from 'lodash'
import { vMaska } from "maska/vue"
import { useProgressStore } from '@/stores/modules/progressStore'
import { useConfigStore } from '@/stores/modules/configStore'

/**
 * Interface for Input props
 * @typedef {Object} InputProps
 * @property {string} [id] - Input field id
 * @property {string} [type] - Input type (text, number, etc)
 * @property {string} [inputMode] - Input mode (text, numeric, etc)
 * @property {string} [label] - Label text
 * @property {string} name - Input field name
 * @property {string} [placeholder] - Placeholder text
 * @property {string} [autocomplete] - Autocomplete attribute
 * @property {number} [maxLength] - Maximum input length
 * @property {boolean} [uppercase] - Whether to transform text to uppercase
 * @property {string} [smallPrint] - Helper text below input
 * @property {boolean} [showPoundSign] - Whether to show pound sign
 * @property {string} [modelValue] - v-model value
 * @property {string} [pattern] - Input pattern
 * @property {boolean} [sensitiveData] - Whether the input is sensitive data
 */
interface InputProps {
  id?: string
  type?: string
  inputMode?: 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  label?: string
  name: string
  placeholder?: string
  autocomplete?: string
  maxLength?: number
  uppercase?: boolean
  smallPrint?: string
  showPoundSign?: boolean
  modelValue?: string
  disabled?: boolean
  pattern?: string
  sensitiveData?: boolean
  rightText?: string
  maska?: string
  isScrolled?: boolean
  headerWidth?: number
}

const props = withDefaults(defineProps<InputProps>(), {
  id: () => 'input-text-' + uniqueId(),
  type: 'text',
  inputMode: 'text',
  label: '',
  placeholder: '',
  autocomplete: '',
  maxLength: 50,
  uppercase: false,
  smallPrint: '',
  showPoundSign: false,
  disabled: false,
  sensitiveData: false,
  rightText: '',
  maska: '',
  isScrolled: false,
  headerWidth: 0
})

const progressStore = useProgressStore()
const configStore = useConfigStore()
const isDefaultTheme = computed(() => !configStore.isDefaultTheme)
const vehicleSearch = inject('vehicleSearch')

const handleClearSearch = () => {
  try {
    value.value = ''
    //emit('update:modelValue', vehicleSearch)

    if (vehicleSearch?.value) {
      vehicleSearch.value.data = { car: "" }
    }
  } catch (error) {
   
  }
}

/**
 * Emitted events for v-model support and blur handling
 */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'blur', value: string | undefined): void
}>()

/**
 * Input reference for DOM access
 */
const inputRef = ref<HTMLInputElement>()

/**
 * Field validation using vee-validate
 */
const { value, errorMessage, validate } = useField(props.name, props.name, {
  validateOnValueUpdate: false,
})



/**
 * Computed classes for input styling
 */
const baseInputClass = computed(() => ({
  'uppercase': props.uppercase,
  'text-left': true,
  'font-base': true,
  'pl-14': !props.showPoundSign,
  'pr-4': !props.showPoundSign,
  'px-10': props.showPoundSign,
  'maskpii': props.sensitiveData
}))

/**
 * Sanitize input value by removing HTML tags and entities
 */
const sanitizeInput = (value: string): string => {
  return value
    .replace(/[<>]/g, '') // Remove < and > characters
    .replace(/&/g, '&amp;') // Escape & character
    .replace(/"/g, '&quot;') // Escape " character
    .replace(/'/g, '&#39;') // Escape ' character
}

/**
 * Sanitize and debounce input handler
 */
const sanitizeAndUpdateValue = debounce((event: Event) => {
  try {
    const target = event.target as HTMLInputElement
    const sanitizedValue = target.value

    target.value = sanitizedValue
    emit('update:modelValue', vehicleSearch)

    if (vehicleSearch?.value) {
      vehicleSearch.value.data = { car: sanitizedValue }
    }
  } catch (error) {
   
  }
}, 300)

/**
 * Blur event handler
 */
const handleBlur = async () => {
  await validate()
  emit('blur', value.value as string | undefined)
}
</script>

<style lang="scss" scoped>
input {
  text-transform: none;
  border: 1px solid #ccc;

  @apply h-14 w-full rounded-md  text-black font-normal;
  
  &::placeholder {
    font-weight: normal;
  }
  
  &:focus {
    @apply outline-2 outline-blue-500 border-transparent;
  }
}

.uppercase {
  text-transform: uppercase;
}

.error {
  @apply text-red-600 mt-2.5;
}

.input-error {
  @apply border-2 border-red-600 bg-red-50;
}
</style>