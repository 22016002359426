<template>
  <div>
    <div v-for="(benefit, index) in benefits" 
       :key="index" 
       :class="'flex text-left gap-4 pb-3 ' + ( progressStore.pageName !== 'dealer-search' ? ' text-body2 ' : 'text-body1 ') ">
      <div style="width: 20px;">
        <Icon
          :icon="benefit.icon"
          :icon-color="'hsl(var(--twc-secondary))'"
          stroke="black"
          width="20"
          height="20"
        />
      </div>
      <p class="leading-[1.45]" v-html="benefit.text"></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProgressStore } from '@/stores/modules/progressStore'
import { useConfigStore } from '@/stores/modules/configStore';

const progressStore = useProgressStore()
const content = useConfigStore().affiliateConfig.contents;

const benefits = content.PRIVACY_SECTION_CONTENT.BENEFITS as Array<{
  icon: string;
  text: string;
}>

</script> 