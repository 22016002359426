// Remove this line since we're not using crypto anymore
// declare const crypto: Crypto;

import { sha256 } from 'js-sha256';

export default {
    async sha256(str: string): Promise<string> {
        if (!str || str.trim() === '') {
            throw new Error('Cannot hash empty or undefined string');
        }
        return sha256.hex(str.trim());
    },

    simpleHash(str: string): string {
        // Enhanced simple hash function for better distribution
        let hash = 0;
        const inputString = String(str).toLowerCase();
        
        for (let i = 0; i < inputString.length; i++) {
            const char = inputString.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }

        // Create a longer hash by combining multiple operations
        const timestamp = Date.now().toString(16);
        const randomPart = Math.random().toString(16).substring(2, 8);
        const baseHash = Math.abs(hash).toString(16).padStart(8, '0');
        
        // Combine parts to create a more unique hash
        return `${baseHash}${timestamp}${randomPart}`;
    }
}