/**
 * @fileoverview Form store module for managing application form state and validation
 * Handles user input, form progression, data validation, and loan option management
 * for the entire application process
 */

import { defineStore } from 'pinia';
import _ from 'lodash';
import { RequestHelper, ObjectHelper } from "@/helpers";
import { DEFAULT_VALUE, VehicleType } from '@/constants';
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';

import { useAddressStore } from '@/stores/modules/addressStore';
import { useConfigStore } from '@/stores/modules/configStore';
import urlConfig from '@/factories/urls-factory';
import type { FormState, Address, Employment, PrepopResponse, VehicleFinance } from '@/types/formTypes';

/**
 * Form store for managing the application form state and operations
 * Provides centralized state management for:
 * - Form completion tracking
 * - User input validation
 * - Application metadata
 * - Vehicle finance details
 * - Bank account information
 * - Marketing preferences
 * - Loan options and eligibility
 * 
 * @store form
 */
export const useFormStore = defineStore('form', {
  /**
   * Initial state of the form store
   * Contains all form fields, completion flags, and application metadata
   */
  state: (): FormState => ({
    // Application metadata
    referralId: null,
    riskBand: null,
    affiliateUserId: null,
    clickId: null,
    quoteId: null,

    // Application form data
    applicationId: null,
    loanTypeId: null,
    vehicleTypeId: null,
    maritalStatusId: null,
    drivingLicenceTypeId: null,
    loanAmount: DEFAULT_VALUE.loanAmount,
    titleId: null,
    firstName: null,
    lastName: null,
    email: null,
    contactNumber: null,
    dateOfBirth: null,
    appliedDate: null,
    netMonthlyIncome: null,
    // Residential addresses
    addresses: [{
      addressKey: null,
      buildingName: null,
      buildingNumber: null,
      companyName: null,
      county: null,
      flatNumber: null,
      locality: null,
      monthsAtAddress: null,
      yearsAtAddress: null,
      postcode: null,
      street: null,
      town: null,
      subBuilding: null,
      residentialStatusId: null,
    }] as Address[],

    // Employment details
    employments: [{
      occupation: null,
      employerName: null,
      employerTown: null,
      statusId: null,
      yearsAtEmployer: null,
      monthsAtEmployer: null,
      subBuilding: null,
      buildingName: null,
      buildingNumber: null,
      locality: null,
      postcode: null,
      county: null,
      street: null,
      flatNumber: null,
    }] as Employment[],

    // Vehicle finance details
    vehicleFinance: {
      term: null,
      deposit: null,
      partExchangeValue: null,
      partExchangeSettlement: null,
      estimatedAnnualMileage: null,
    } as VehicleFinance,

    // Bank details
    banks: [],

    // Email preferences
    isPromoEmail: false,
    isPromoSms: false,
    isPromoTelephone: false,
    isPromoWhatsApp: false,
    IsShareInfo: false,

    // Form Completion
    agreedTermsAndConditions: false,
    IsCompleted: false,
    submissionTypeId: null,
    monthlyBudget: null,
    loanOption: null,
    isMonthlyBudgetAllowed: false,
  }),

  actions: {
    /**
     * Updates a form value at the specified path
     * @param {[any, string]} data - Tuple containing the value and the path to update
     */
    updateFormValue(data: [any, string]) {
      try {
        const [value, path] = data;
        // Remove 'form.' prefix if it exists
        const normalizedPath = path.replace(/^form\./, '');
        _.set(this, normalizedPath, value);
      } catch (error) {
        handleStoreError('form', 'updateFormValue', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Sets the application ID
     */
    setApplicationId() {
      const appId = RequestHelper.getQueryVariable('appid');
      this.applicationId = appId;
      if (appId) this.referralId = appId;
    },
    /**
     * Sets the application ID
     */
    setAffiliateUserId() {
      try {
        const configStore = useConfigStore();
        // Check URL parameters first
        const urlAffiliateId = RequestHelper.getQueryVariable('aid') || RequestHelper.getQueryVariable('daid');
        
        if (urlAffiliateId) {
          this.affiliateUserId = Number(urlAffiliateId);
          return;
        }

        // Fall back to config store if no URL parameter
        const configAffiliateId = configStore.affiliateConfig?.affiliateUserId;
        if (configAffiliateId || configAffiliateId === 0) {
          this.affiliateUserId = configAffiliateId;
        }
      } catch (error) {
        handleStoreError('form', 'setAffiliateUserId', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Sets the loan type ID
     */
    setLoanTypeId() {
      const loanTypeId = RequestHelper.getQueryVariable('loanTypeId');
      if (loanTypeId && !isNaN(Number(loanTypeId))) {
        this.loanTypeId = Number(loanTypeId);
      }
    },

    /**
     * Sets the submission type ID
     */
    setSubmissionTypeId() {
      const submissionTypeId = RequestHelper.getQueryVariable('submissionTypeId');
      const option = RequestHelper.getQueryVariable('option');

      if (submissionTypeId && !isNaN(Number(submissionTypeId))) {
        this.submissionTypeId = Number(submissionTypeId);
      } else if (option) {
        if (option.toLowerCase() === 'refinance') {
          this.submissionTypeId = 1;
        } else if (option.toLowerCase() === 'finance') {
          this.submissionTypeId = 0;
        }
      }
    },

    /**
     * Sets the monthly budget
     */
    setMonthlyBudget() {
      const monthlyBudget = RequestHelper.getQueryVariable('monthlyBudget');
      if (monthlyBudget && !isNaN(Number(monthlyBudget))) {
        this.monthlyBudget = Number(monthlyBudget);
      }
    },

    /**
     * Sets the application date if not already set
     */
    setAppliedDate() {
      if (this.appliedDate) return;
      
      const date = new Date();
      this.appliedDate = date.toJSON();
    },

    /**
     * Sets the loan amount based on URL parameters
     */
    setLoanAmount() {
      try {
        let loanAmount = DEFAULT_VALUE.loanAmount;

        const amount = RequestHelper.getQueryVariable('amount');
        const amountUswitch = RequestHelper.getQueryVariable('amount_uswitch');
        const loanAmountPrepop = RequestHelper.getQueryVariable('loan-amount-prepop');
        const monthlyBudget = RequestHelper.getQueryVariable('monthlyBudget');

        if (amount && !isNaN(Number(amount))) {
          loanAmount = Number(amount);
        } else if (amountUswitch && !isNaN(Number(amountUswitch))) {
          loanAmount = Number(amountUswitch);
        } else if (loanAmountPrepop && !isNaN(Number(loanAmountPrepop))) {
          loanAmount = Number(loanAmountPrepop);
        } else if (this.isMonthlyBudgetAllowed && monthlyBudget && !isNaN(Number(monthlyBudget))) {
          loanAmount = Math.round(Number(monthlyBudget) * 60);
        }

        this.loanAmount = loanAmount;
      } catch (error) {
        handleStoreError('form', 'setLoanAmount', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Sets the vehicle type based on URL parameters
     */
    setVehicleType() {
      const vehicleType = RequestHelper.getQueryVariable('vehicle_type');
      
      if (!vehicleType) return;

      const index = Object.keys(VehicleType)
        .findIndex(v => v.toUpperCase() === vehicleType.toUpperCase().trim());

      const vehicleTypeId = index > -1 
        ? Object.values(VehicleType)[index] 
        : VehicleType.Car;

      this.vehicleTypeId = vehicleTypeId && Number(vehicleTypeId) > 0 
        ? Number(vehicleTypeId) 
        : null;
    },

    /**
     * Prepopulates form data either from an existing application or URL parameters
     * 
     * If an 'appid' query parameter exists, it will fetch and populate all form fields
     * from the existing application. Otherwise, it will attempt to populate individual
     * fields from specific URL parameters.
     * 
     * URL Parameters:
     * @param {string} appid - Existing application ID to populate all fields
     * @param {string} firstName - User's first name
     * @param {string} lastName - User's last name
     * @param {string} email - User's email address
     * @param {string} contactNumber - User's contact number
     * @param {string} term - Finance term in months
     * @param {string} deposit - Initial deposit amount
     * @param {string} pxValue - Part exchange value
     * @param {string} pxSettlement - Part exchange settlement
     * @param {string} annualMileage - Estimated annual mileage
     * 
     * @throws {Error} Handled by StoreErrorType.VALIDATION if population fails
     */
    async setPrepopData() {
      try {
        const appId = RequestHelper.getQueryVariable('appid');
        const configStore = useConfigStore();
        
        // Handle existing application prepopulation
        if (appId && this.applicationId && configStore.affiliateConfig?.prePopulateForm) {
          try {
            await this.fetchAndPopulateData(appId);
            // Separate try-catch for loan options to prevent failing the entire prepop
            try {
              await this.fetchLoanOptions(appId);
            } catch (loanError) {
              console.warn('Failed to fetch loan options, continuing with prepop:', loanError);
            }
          } catch (error) {
            console.error('Failed to fetch prepop data:', error);
            // Continue with URL parameter population as fallback
          }
        }

        // Check if any relevant query parameters exist
        const hasQueryParams = [
          'firstName', 'lastName', 'email', 'contactNumber',
          'term', 'deposit', 'pxValue', 'pxSettlement', 'annualMileage'
        ].some(param => RequestHelper.getQueryVariable(param));

        if (!hasQueryParams) {
          console.log('No prepop query parameters found');
          return;
        }

        // Personal Details
        this.firstName = RequestHelper.getQueryVariable('firstName') || null;
        this.lastName = RequestHelper.getQueryVariable('lastName') || null;
        this.email = RequestHelper.getQueryVariable('email') || null;
        this.contactNumber = RequestHelper.getQueryVariable('contactNumber') || null;

        // Vehicle Finance
        const term = RequestHelper.getQueryVariable('term');
        const deposit = RequestHelper.getQueryVariable('deposit');
        const pxValue = RequestHelper.getQueryVariable('pxValue');
        const pxSettlement = RequestHelper.getQueryVariable('pxSettlement');
        const annualMileage = RequestHelper.getQueryVariable('annualMileage');

        if (term && !isNaN(Number(term))) {
          this.vehicleFinance.term = Number(term);
        }
        if (deposit && !isNaN(Number(deposit))) {
          this.vehicleFinance.deposit = Number(deposit);
        }
        if (pxValue && !isNaN(Number(pxValue))) {
          this.vehicleFinance.partExchangeValue = Number(pxValue);
        }
        if (pxSettlement && !isNaN(Number(pxSettlement))) {
          this.vehicleFinance.partExchangeSettlement = Number(pxSettlement);
        }
        if (annualMileage && !isNaN(Number(annualMileage))) {
          this.vehicleFinance.estimatedAnnualMileage = Number(annualMileage);
        }
      } catch (error) {
        handleStoreError('form', 'setPrepopData', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Fetches and populates form data from an existing application
     * Retrieves comprehensive application data and updates the form state, including:
     * - Basic application information (referral ID, risk band)
     * - Personal details (name, contact info, DOB)
     * - Address history
     * - Employment details
     * 
     * @param {string} applicationId - The ID of the application to fetch data for
     * @throws {Error} Handled by StoreErrorType.VALIDATION if fetch or population fails
     * 
     * Data Processing:
     * - Formats contact numbers to ensure leading zero
     * - Converts date strings to separate day/month/year values
     * - Formats addresses for display and lookup
     * - Maps employment details with status and income information
     */
    async fetchAndPopulateData(applicationId: string) {
      const addressStore = useAddressStore();
      const configStore = useConfigStore();
      const environment = configStore.isProduction ? 'production' : 'replica';
      const urlParams = new URLSearchParams({
        appId: applicationId,
        validation: 'false'
      });

      const response = await fetch(
        `${urlConfig[environment].apiBaseUrl}/LoanApplication?${urlParams}`,
        { 
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch prepop data: ${response.status} ${errorText}`);
      }

      const data: PrepopResponse = await response.json();
      console.log('Prepop response:', data);
      
      // Update basic info
      this.updateFormValue([applicationId, 'referralId']);
      this.updateFormValue([data.riskBand, 'riskBand']);
      this.updateFormValue([
        ObjectHelper.defaultIfUndefinedOrNull(data.loanAmount, DEFAULT_VALUE.loanAmount),
        'loanAmount'
      ]);
      this.updateFormValue([
        data.vehicleType ? data.vehicleType.id : this.vehicleTypeId,
        'vehicleTypeId'
      ]);
      this.updateFormValue([data.title ? data.title.id : null, 'titleId']);
      this.updateFormValue([data.firstName, 'firstName']);
      this.updateFormValue([data.lastName, 'lastName']);
      this.updateFormValue([data.email, 'email']);

      // Check for existing submission type ID in the query parameters
      const submissionTypeId = RequestHelper.getQueryVariable('submissionTypeId');
      if (!submissionTypeId) this.updateFormValue([data.submissionType, 'submissionTypeId']);

      // Handle contact number formatting
      const startWithZeroRegExp = /^0[0-9].*$/;
      const contactNumber = (data.contactNumber !== undefined && !isNaN(parseInt(data.contactNumber))) 
        ? (startWithZeroRegExp.test(parseInt(data.contactNumber).toString()) 
          ? parseInt(data.contactNumber) 
          : "0" + parseInt(data.contactNumber)) 
        : null;
      this.updateFormValue([contactNumber, 'contactNumber']);

      this.updateFormValue([
        data.maritalStatus ? data.maritalStatus.id : this.maritalStatusId,
        'maritalStatusId'
      ]);
      this.updateFormValue([
        data.drivingLicence ? data.drivingLicence.id : this.drivingLicenceTypeId,
        'drivingLicenceTypeId'
      ]);

      // Update date of birth
      if (data.dateOfBirth) {
        const date = new Date(data.dateOfBirth);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        
        this.updateFormValue([date, 'dateOfBirth']);
        this.updateFormValue([day, 'dobDay']);
        this.updateFormValue([month, 'dobMonth']);
        this.updateFormValue([year, 'dobYear']);
      }

      // Update addresses
      if (data.addresses?.length) {
        const addressDetails = data.addresses.map(address => ({
          residentialStatusId: address.residentialStatusLookup?.id || null,
          postcode: ObjectHelper.defaultIfUndefinedOrNull(address.postcode, null),
          subBuilding: ObjectHelper.defaultIfUndefinedOrNull(address.subBuilding, null),
          flatNumber: ObjectHelper.defaultIfUndefinedOrNull(address.flatNumber, null),
          buildingName: ObjectHelper.defaultIfUndefinedOrNull(address.buildingName, null),
          buildingNumber: ObjectHelper.defaultIfUndefinedOrNull(address.buildingNumber, null),
          street: ObjectHelper.defaultIfUndefinedOrNull(address.street, null),
          locality: ObjectHelper.defaultIfUndefinedOrNull(address.locality, null),
          town: ObjectHelper.defaultIfUndefinedOrNull(address.town, null),
          county: ObjectHelper.defaultIfUndefinedOrNull(address.county, null),
          yearsAtAddress: ObjectHelper.defaultIfUndefinedOrNull(address.yearsAtAddress, null),
          monthsAtAddress: ObjectHelper.defaultIfUndefinedOrNull(address.monthsAtAddress, null),
          addressLevel: ObjectHelper.defaultIfUndefinedOrNull(address.addressLevel, null)
        }));

        this.updateFormValue([addressDetails, 'addresses']);

        // Update address store with formatted address
        const address = data.addresses[0];
        const addressParts = [
          address.flatNumber,
          address.subBuilding,
          address.buildingNumber && address.buildingName 
            ? `${address.buildingNumber} ${address.buildingName}`
            : null,
          address.street,
          address.town ? `${address.town}.` : null,
          address.postcode
        ].filter(Boolean);

        addressStore.setFullAddress({
          index: 0,
          value: Number(addressParts.join(', ')),
          address: addressParts.join(', '),
          streetAddress: addressParts.join(', '),
          town: ObjectHelper.defaultIfUndefinedOrNull(address.town, null),
          manual: false,
          hideAddressLookup: true,
          isFromPrepop: true
        });
      }

      // Update employments
      if (data.employments?.length) {
        const employmentDetails = data.employments.map((employment, index) => ({
          statusId: employment.employmentStatusLookup?.id || null,
          occupation: ObjectHelper.defaultIfUndefinedOrNull(employment.occupation, null),
          employerName: ObjectHelper.defaultIfUndefinedOrNull(employment.employerName, null),
          employerTown: ObjectHelper.defaultIfUndefinedOrNull(employment.employerTown, null),
          MonthlyIncome: index === 0 ? this.netMonthlyIncome : null,
          yearsAtEmployer: ObjectHelper.defaultIfUndefinedOrNull(employment.yearsAtEmployer, null),
          monthsAtEmployer: ObjectHelper.defaultIfUndefinedOrNull(employment.monthsAtEmployer, null),
          employmentLevel: ObjectHelper.defaultIfUndefinedOrNull(employment.employmentLevel, null)
        }));

        this.updateFormValue([employmentDetails, 'employments']);
      }

    },

    /**
     * Fetches loan options and eligibility data for a given application
     * Retrieves detailed finance information including APR, monthly payments,
     * and representative examples
     * 
     * @param {string} applicationId - The application ID to fetch loan options for
     * @returns {Promise<LoanOption>} The loan option details
     * @throws {Error} If the fetch request fails
     */
    async fetchLoanOptions(applicationId: string) {
      const configStore = useConfigStore();
      const environment = configStore.isProduction ? 'production' : 'replica';
      const params = new URLSearchParams({ appId: applicationId });
      if (this.clickId) params.append('clickId', this.clickId);
      if (this.quoteId) params.append('quoteId', this.quoteId);

      console.log('Fetching loan options with params:', params);

      try {
        const response = await fetch(
          `${urlConfig[environment].apiBaseUrl}/LoanApplication/get-loan-option?${params}`,
          { method: 'GET' }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch loan options');
        }

        const loanOption = await response.json();
        this.loanOption = loanOption;
        console.log('Fetching loan options:', loanOption);
        return loanOption;
      } catch (error) {
        console.error('Error fetching loan options:', error);
        throw error;
      }
    },

    /**
   * Sets the click ID
   */
    setQuoteId() {
      const quoteid = RequestHelper.getQueryVariable('quoteid');
      this.quoteId = quoteid;
    },

    /**
     * Sets the click ID
     */
    setClickId() {
      const clickId = RequestHelper.getQueryVariable('clickId');
      this.clickId = clickId;
    },
    /**
     * Remove employment details
     */
    removeEmployment(index: number) {
      this.employments.splice(index, 1);
    },
    /**
     * Remove address details
     */
    removeAddress(index: number) {
      this.addresses.splice(index, 1);
    },
  },

  getters: {
    /**
     * Calculates the total bank history in years for a specific bank entry
     * @param {number} index - Index of the bank entry
     * @returns {number} Total years at bank (including fractional years from months)
     */
    bankHistory: (state) => (index: number) => {
      const years = state.banks[index]?.yearsAtBank || 0;
      const months = state.banks[index]?.monthsAtBank || 0;
      return years + (months / 12);
    },
    /**
     * Calculates the total address history in years
     * @param state - The form store state
     * @returns - The total address history in years
     */
    totalAddressHistory: (state) => {
      // Normal calculation for adding new addresses
      const total = state.addresses.reduce((acc, address) => {
        if (address.yearsAtAddress || address.monthsAtAddress) {
          const years = address.yearsAtAddress || 0;
          const months = address.monthsAtAddress || 0;
          return acc + years + (months / 12);
        }
        return acc;
      }, 0);

      return Math.round(total * 100) / 100;
    },
    /**
     * Calculates the total employment history in years
     * @param state - The form store state
     * @returns - The total employment history in years
     */
    totalEmploymentHistory: (state) => {
      const total = state.employments.reduce((acc, employment) => {
        const years = employment.yearsAtEmployer || 0;
        const months = employment.monthsAtEmployer || 0;
        return acc + years + (months / 12);
      }, 0);

      return Math.round(total * 100) / 100;
    }
  }
}); 