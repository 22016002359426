<!-- 
  @file ErrorBoundary.vue - Error Boundary Component
  @description
  A Vue component that catches and handles unexpected errors in its child components.
  Acts as a safety net for the application by preventing error propagation and
  providing a user-friendly fallback UI when errors occur.

  Features:
  - Catches runtime errors in child components
  - Prevents error propagation up the component tree
  - Displays a user-friendly error message
  - Provides a retry mechanism to recover from errors
  - Maintains application stability during unexpected errors

  Usage example:
  <ErrorBoundary>
    <YourComponent />
  </ErrorBoundary>
-->
<template>
  <div>
    <div v-if="error" class="error-boundary">
      <h2>Something went wrong</h2>
      <p>{{ error.message }}</p>
      <button @click="handleRetry">Try Again</button>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script lang="ts">
/**
 * @component ErrorBoundary
 */
import { defineComponent, ref, onErrorCaptured } from 'vue';
import * as Sentry from '@sentry/vue';

export default defineComponent({
  name: 'ErrorBoundary',
  
  setup() {
    /**
     * Stores the current error state
     * @type {Ref<Error | null>}
     */
    const error = ref<Error | null>(null);

    /**
     * Captures errors from child components
     * @param {Error} err - The error that was thrown
     * @returns {boolean} false to prevent error propagation
     */
    onErrorCaptured((err: Error) => {
      error.value = err;
      // Report the error to Sentry
      Sentry.captureException(err);
      return false; // Prevent error from propagating
    });

    /**
     * Resets the error state and reloads the page to recover
     */
    const handleRetry = () => {
      error.value = null;
      window.location.reload();
    };

    return {
      error,
      handleRetry
    };
  }
});
</script>

<style scoped>
/**
 * Styles for the error message container
 * Uses a light red background to indicate error state
 */
.error-boundary {
  padding: 2rem;
  text-align: center;
  background-color: #fee2e2;
  border-radius: 4px;
}

/**
 * Styles for the retry button
 * Uses a white background with red border to match error theme
 */
button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #ef4444;
  border-radius: 4px;
  cursor: pointer;
}
</style> 