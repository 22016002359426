export type RegExHelperType = {
    extract(string: string | null | undefined, pattern: RegExp): string | null;
    checkFieldWithoutHtmlTag(value: string): boolean;
};

export default {
    /**
     * Extracts the first capturing group from a string using a regex pattern
     * @param string The input string to search
     * @param pattern A RegExp pattern with at least one capturing group
     * @returns The first captured group or null if no match found
     */
    extract(string: string | null | undefined, pattern: RegExp): string | null {
        if (!string) return null;
        
        const matches = pattern.exec(string);
        return matches?.[1] ?? null;
    },
    /**
     * Checks if a field is safe to display without HTML tags
     * @param value The field value to check
     * @returns True if the field does not contain HTML tags, false otherwise
     */
    checkFieldWithoutHtmlTag (value: string): boolean {
      const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    
      return !regexForHTML.test(value);
    },
    checkMoneyFormat(value: string): boolean {
      // Regex to check integer values with commas
      const regexForMoney = /^\d{1,3}(,\d{3})*$/;
      return regexForMoney.test(value);
    },
    checkPhoneNumberFormat(value: string): boolean {
      // Accepts phone numbers in the format +447123456789, 447123456789, 07123456789, 7123456789
      const regexForPhoneNumber = /^(\+44|44|0)?7\d{9}$/;
      return regexForPhoneNumber.test(value);
    },
    checkValidEmail(email: string): boolean {
      if (email === undefined || email === null) return false;
      // Basic email regex for demonstration
      const rfc5322EmailRegex = /^((?:[a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~]+)*)|(?:"(?:\\[\x00-\x7F]|[^"])*"))@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))$/;

      return rfc5322EmailRegex.test(email);
   }
} as const;