/**
 * @file useStoreInit.ts - Store Initialization Composable
 * @description
 * A Vue composable that manages the initialization and cleanup of all application stores.
 * Provides centralized store management with fault tolerance and persistence handling.
 *
 * Key Features:
 * - Sequential store initialization with isolated error handling per store
 * - State persistence and restoration across sessions
 * - Device context and user agent management
 * - Host and affiliate configuration management
 * - UTM parameter tracking for marketing analytics
 * - Form state initialization and management
 * - Vehicle data handling and persistence
 * - Theme configuration and customization
 * - Language/localization initialization
 *
 * Initialization Sequence:
 * 1. Restore persisted state from previous sessions
 * 2. Setup persistence watchers for state changes
 * 3. Device store (IP address, user agent, device context)
 * 4. Configuration store (host, GUID, affiliate settings, subdomain)
 * 5. UTM parameters store (marketing tracking)
 * 6. Form store (loan details, application data, vehicle preferences)
 * 7. Vehicle store (selected vehicle data)
 * 8. Theme store (UI customization)
 * 9. Language initialization
 *
 * Error Handling:
 * - Each store initialization is wrapped in its own try-catch block
 * - Failures in one store won't affect the initialization of others
 * - Errors are logged and handled through the handleStoreError utility
 *
 * @example
 * ```typescript
 * // In a Vue component or setup function
 * import { useStoreInit } from '@/composables/useStoreInit';
 * 
 * // Initialize stores
 * const { initializeStores, cleanup } = useStoreInit();
 * 
 * // Start initialization
 * await initializeStores();
 * 
 * // Cleanup on component unmount
 * onUnmounted(() => cleanup());
 * ```
 */

import { useRootStore } from '@/stores/rootStore';
import { handleStoreError } from '@/utils/storeErrorHandling';
import { restorePersistedState, setupStorePersistence } from '@/utils/storePersistence';
import { ref } from 'vue';
import { RequestHelper } from "@/helpers";
import { useLanguageInit } from '@/composables/useLanguageInit';
import { useTealium  } from './useTealium';

/**
 * Manages the lifecycle of application stores including initialization and cleanup
 * @returns {Object} Store management methods
 * @returns {Function} initializeStores - Asynchronously initializes all stores in sequence
 * @returns {Function} cleanup - Removes persistence watchers and resets cleanup state
 */
export function useStoreInit() {
  /**
   * Destructures required stores from the root store
   */
  const { 
    config, 
    utm, 
    form, 
    vehicle,
    device,
    theme
  } = useRootStore();

  /**
   * Reference to the cleanup function for store persistence watchers
   * @type {Ref<(() => void) | null>}
   */
  const persistenceCleanup = ref<(() => void) | null>(null);

  // const { initializeLanguage } = useLanguageInit();

  /**
   * Initializes all application stores in sequence
   * Each store initialization is wrapped in its own try-catch block
   * to prevent failures in one store from affecting others
   * 
   * Initialization sequence:
   * 1. Device store (IP address, user agent, device context)
   * 2. Configuration store
   * 3. UTM parameters store
   * 4. Form store
   * 5. Vehicle store
   * 
   * @async
   * @throws {Error} If the overall initialization fails
   */
  const initializeStores = async () => {
    try {
      console.log(
        '%c You are using environment: ' + import.meta.env.MODE,
        'background: #222; color: #bada55; font-size: 20px; font-weight: bold; padding: 10px;'
      );

      // First restore any persisted state
      restorePersistedState();
      
      // Set up watchers to persist future state changes
      persistenceCleanup.value = setupStorePersistence();

      // Initialize device info
      try {
        await device.setDeviceInfo();
      } catch (error) {
        handleStoreError('device', 'initialization', error);
      }

      // Initialize config
      try {
        await config.prepareAffiliateConfig();
        config.setHost();
        config.setGUID();
        config.setAffiliateConfig();
        config.setNoTransition();
        config.setSubdomain();

        if (RequestHelper.getQueryVariable('did') && !RequestHelper.getQueryVariable('to-dealer-get-my-quote')) {
          config.initialiseDealer(RequestHelper.getQueryVariable('did') , RequestHelper.getQueryVariable('to-dealer-get-my-quote') );
        }
        config.setIsPoweringLender();
        config.setIsDealer();
      } catch (error) {
        handleStoreError('config', 'initialization', error);
      }

      // Initialize UTM parameters
      try {
        utm.setUTMParams();
      } catch (error) {
        handleStoreError('utm', 'initialization', error);
      }

      // Initialize Tealium
      try {
        if( config.affiliateConfig.systemName === 'comparethemarket' || config.affiliateConfig.systemName === 'comparethemarketcarfinance' ) {
          await useTealium().initalData();
          useTealium().getConsentState().then((consentState) => {
            if (consentState === useTealium().helper.getConsentState().NONE) {
              // Do Nothing - Consent Manager will be shown by default
            }
          });
        }
      } catch (error) {
        handleStoreError('tealium', 'initialization', error);
      }

      // Initialize form
      try {
        form.setAppliedDate();
        form.setLoanAmount();
        form.setApplicationId();
        form.setAffiliateUserId();
        form.setPrepopData();
        form.setLoanTypeId();
        form.setSubmissionTypeId();
        form.setMonthlyBudget();
        form.setVehicleType();
        form.setClickId();
        form.setQuoteId();
      } catch (error) {
        handleStoreError('form', 'initialization', error);
      }

      // Initialize vehicle
      try {
        await vehicle.setVehicle();

        if (vehicle.vehicleId && Number(vehicle.vehicleId) > 0) {
          await vehicle.loadVehicle();
        } else if ((vehicle.vehicleId === null || Number(vehicle.vehicleId) === 0) && RequestHelper.getQueryVariable('vrm') && !RequestHelper.getQueryVariable('to-dealer-get-my-quote')) {
          await vehicle.loadVehicleByVrm(RequestHelper.getQueryVariable('vrm'));
        }

        if (RequestHelper.getQueryVariable('did') &&  !RequestHelper.getQueryVariable('to-dealer-get-my-quote')) {
          await vehicle.loadDealerFeedVehicle(RequestHelper.getQueryVariable('did'));
        }
        
      } catch (error) {
        handleStoreError('vehicle', 'initialization', error);
      }

      // Initialize theme
      try {
        theme.initializeTheme();
      } catch (error) {
        handleStoreError('theme', 'initialization', error);
      }

      // Initialize affiliate language
      // try {
      //   await initializeLanguage(host);
      // } catch (error) {
      //   handleStoreError('i18n', 'initialization', error);
      // }
      
    } catch (error) {
      handleStoreError('root', 'initialization', error);
    }
  };

  /**
   * Cleans up store persistence watchers and resets the cleanup reference
   * Should be called when the application is unmounting or store persistence
   * is no longer needed
   */
  const cleanup = () => {
    persistenceCleanup.value?.();
    persistenceCleanup.value = null;
  };

  return {
    initializeStores,
    cleanup
  };
} 