/**
 * Main application entry point
 * This module initializes and configures the Vue 3 application with all necessary plugins and dependencies.
 * 
 * Key features:
 * - Application monitoring (Application Insights, Sentry)
 * - State management (Pinia)
 * - Routing (Vue Router)
 * - Internationalization (vue-i18n)
 * - Data fetching (Vue Query)
 * - Global component registration
 * - External script loading (GTM, Clarity, Webtrends)
 */

// Style imports
import '@cf247/web-component-library/src/index.css';
import '@cf247/web-component-library/dist/style.css';
import './assets/main.css';
import '@cf247/tailwind-config/tailwind.css';
// disable scss , not use at this moment
// import './main.scss';

// Core framework imports
import { createApp, ref } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { VueQueryPlugin } from '@tanstack/vue-query'

// Store plugins
import { analyticsPlugin, setAppInsights } from '@/plugins/storeAnalytics'
import { segmentPlugin } from '@/plugins/storeSegment'
import { partialSubmissionPlugin } from '@/plugins/storePartialSubmission'
import { AppInsightsPlugin, type AppInsightsPluginOptions } from 'vue3-application-insights'

// Application imports
import router from './router'
import App from './App.vue'
import { Button, Icon, SelectInput, Trustpilot, VehicleCard } from '@cf247/web-component-library'
import ButtonGroup from '@/components/ButtonGroup.vue'
import Modal from '@/components/ModalComponent.vue'

// Add Sentry import
import * as Sentry from "@sentry/vue";

import { 
  loadExternalScripts, 
  EXTERNAL_SCRIPTS, 
  removeFoucPrevention,
  loadScriptWithErrorHandling,
  addFoucPrevention
} from '@/utils/scriptLoader';

/**
 * Create Vue application instance and Pinia store
 */
const app = createApp(App)

// Initialize Application Insights first
const isProduction = import.meta.env.PROD;
const aiOptions: AppInsightsPluginOptions = {
  connectionString: isProduction 
        ? 'InstrumentationKey=2102820b-1b12-40a6-93c3-f3659c1f41fc'
        : 'InstrumentationKey=30f05636-a3a0-4437-a389-7578e221fe86',
  trackAppErrors: true,
  router,
  onLoaded: (appInsights) => {
    setAppInsights(appInsights);
  }
};

const vehicleSearch = ref({ data: {}});
const isVehicleSearchDataLoaded = ref(false);
const isVehicleSearchToped = ref(false);
app.provide( 'vehicleSearch' , vehicleSearch)
app.provide( 'isVehicleSearchDataLoaded' , isVehicleSearchDataLoaded)
app.provide( 'isVehicleSearchToped' , isVehicleSearchToped)

app.use(AppInsightsPlugin, aiOptions);

// Then initialize Pinia and its plugins
const pinia = createPinia();
app.use(pinia);
pinia.use(analyticsPlugin);

// Initialize Pinia plugins after both App Insights and Pinia are ready
pinia.use(segmentPlugin);

pinia.use(partialSubmissionPlugin);

// Initialize Sentry with enhanced configuration
if (import.meta.env.PROD) {
  console.log('🔍 Initializing Sentry for domain:', window.location.hostname);
  
  Sentry.init({
    app,
    dsn: "https://8b3547260f46b3778a69acb1a53cf531@o336652.ingest.us.sentry.io/4508682098769920",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  });
  
  console.log('✅ Sentry initialized successfully without session replay');
} else {
  console.log('ℹ️ Sentry initialization skipped for domain:', window.location.hostname);
}

/**
 * Initializes core application features and registers global components
 * This includes:
 * - Data management (Vue Query)
 * - Routing (Vue Router)
 * - Internationalization (vue-i18n)
 * - Global component registration
 */
const initializeAppFeatures = () => {
  // Data management and routing
  app.use(VueQueryPlugin)
  app.use(router)

  // Internationalization
  app.use(createI18n({
    locale: '',
    fallbackLocale: '',
    messages: {}
  }))

  // Global components
  app.component('Button', Button)
  app.component('Icon', Icon)
  app.component('SelectInput', SelectInput)
  app.component('Trustpilot', Trustpilot)
  app.component('ButtonGroup', ButtonGroup)
  app.component('Modal', Modal)
  app.component('VehicleCard', VehicleCard)
}

/**
 * Main application initialization function
 * Handles the complete bootstrap process including:
 * 1. Mounting the Vue application immediately
 * 2. Loading scripts in the background after mounting
 * 
 * This approach prioritizes fast initial render over script loading
 */
async function initializeApplication() {
  const startTime = Date.now();
  
  try {
    // Initialize features first
    initializeAppFeatures();
    
    // Mount the application immediately for fastest possible rendering
    app.mount('#app');
    console.info(`Application mounted in ${Date.now() - startTime}ms`);
    
    // Then load scripts in the background
    // Add FOUC prevention after mounting but before script loading
    addFoucPrevention();
    
    try {
      // Load GTM first (highest priority)
      const gtmScript = EXTERNAL_SCRIPTS.find(s => s.url.includes('gtm.js'));
      if (gtmScript) {
        await loadScriptWithErrorHandling(gtmScript, import.meta.env.PROD);
      }
      
      // Then load Clarity
      const clarityScript = EXTERNAL_SCRIPTS.find(s => s.url.includes('clarity.js') && s.status === 'pending');
      if (clarityScript) {
        await loadScriptWithErrorHandling(clarityScript, import.meta.env.PROD);
      }
      
      // Load all other scripts in parallel
      const otherScripts = EXTERNAL_SCRIPTS.filter(s => 
        !s.url.includes('gtm.js') && 
        !s.url.includes('clarity.js') && 
        s.status === 'pending'
      );
      
      if (otherScripts.length > 0) {
        await Promise.allSettled(
          otherScripts.map(script => loadScriptWithErrorHandling(script, import.meta.env.PROD))
        );
      }
    } catch (scriptError) {
      console.warn('Error loading scripts:', scriptError);
      if (import.meta.env.PROD) {
        Sentry.captureException(scriptError, {
          level: 'warning',
          extra: { phase: 'script-loading' }
        });
      }
    } finally {
      // Ensure FOUC prevention is removed
      removeFoucPrevention();
    }
  } catch (error) {
    // Handle critical initialization failures
    console.error('Critical application initialization failure:', error);
    if (import.meta.env.PROD) {
      Sentry.captureException(error, {
        level: 'fatal',
        extra: { phase: 'initialization' }
      });
    }
    
    // Try to mount app anyway as a fallback
    try {
      if (!app._container) { // Check if app is not already mounted
        initializeAppFeatures();
        app.mount('#app');
      }
    } catch (mountError) {
      console.error('Failed to mount application after error:', mountError);
    } finally {
      removeFoucPrevention(); // Ensure FOUC prevention is removed even in error case
    }
  }
}

// Start the application
initializeApplication();
