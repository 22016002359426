<template>
  <div class="relative mb-2 w-full">
    <label class="text-sm font-bold mb-1 block">{{ label }}</label>
    <div 
      class="button-group" 
      role="radiogroup" 
      :aria-label="label"
      :id="id"
      :class="{ 'error-state': displayError }"
    >
      <label
        v-for="(item, index) in items" 
        :key="item.id || index"
        class="button-group__label"
        :class="{
          'button-group__label--selected': selectedValue === item.value,
          'button-group__label--selected-hover': selectedValue === item.value && hoveredValue === item.value && !disabled,
          'disabled': disabled,
          'input-error': displayError,
          'shadow-sm': !showIcon
        }"
        :style="getBorderStyle(item)"
        :for="item.id"
        :aria-checked="selectedValue === item.value"
        @keydown.space.prevent="handleClick(item)"
        @keydown.enter="handleClick(item)"
        @click="handleClick(item)"
        :tabindex="disabled ? -1 : 0"
        role="radio"
        @mouseenter="!disabled && (hoveredValue = item.value)"
        @mouseleave="!disabled && (hoveredValue = null)"
      >
        <input 
          type="radio" 
          :value="item.value"
          v-model="selectedValue" 
          :id="item.id"
          :name="name"
          :disabled="disabled"
          class="button-group__input hidden"
          :aria-label="item.label"
        >
        <span 
          class="button-group__text text-button-large"
          :class="{ 
            'text-error': displayError,
            'text-black': !showIcon,
            'text-white': selectedValue === item.value && !showIcon
          }"
        >{{ item.label }}</span>
        <div 
          v-if="showIcon"
          :class="{ 'spinner': isSelectedAndDisabled(item.value), 'button-group__icon': !isSelectedAndDisabled(item.value) }"
        >
          <Icon
            v-if="!isSelectedAndDisabled(item.value)"
            :icon="getIconType(item.value)"
            :icon-color="getIconColor(item.value)"
            stroke="none"
            width="20"
            height="20"
          />
        </div>
      </label>
    </div>
    <p v-if="displayError" class="error mt-2 text-body1">{{ displayError }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { uniqueId } from 'lodash'
import { useField } from 'vee-validate'

/**
 * Interface for button group items
 * @typedef {Object} ButtonGroupItem
 * @property {string} id - Unique identifier for accessibility
 * @property {number} value - The value associated with this option
 * @property {string} label - Display text for the option
 */
interface ButtonGroupItem<T = number> {
  id: string
  value: T
  label: string
}

/**
 * Props interface for ButtonGroup component
 * @typedef {Object} ButtonGroupProps
 */
interface ButtonGroupProps<T = number> {
  id?: string
  label?: string
  name?: string
  items: ButtonGroupItem<T>[]
  modelValue: T
  disabled?: boolean
  showIcon?: boolean
  error?: string
}

const props = withDefaults(defineProps<ButtonGroupProps>(), {
  id: () => `input-radio-${uniqueId()}`,
  label: '',
  name: '',
  disabled: false,
  showIcon: true
})

/**
 * Emitted events
 */
const emit = defineEmits<{
  (e: 'update:modelValue', value: ButtonGroupProps['modelValue']): void
  (e: 'selected', value: ButtonGroupProps['modelValue']): void
}>()


const handleClick = (item: any) => {
  selectedValue.value = item.value;
  emit('update:modelValue', item.value);
}

/**
 * Local state for selected value
 */
const selectedValue = ref(props.modelValue)

/**
 * Watches for external value changes
 * Updates local state to match parent value
 */
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== selectedValue.value) {
      selectedValue.value = newValue
    }
  }
)

/**
 * Watches for local value changes
 * Emits update event to parent
 */
// move this to use handle click
// watch(
//   selectedValue,
//   (newValue) => {
//     if (newValue !== props.modelValue) {
//       emit('update:modelValue', newValue)
//     }
//   } 
// )

const hoveredValue = ref<any>(null)  // Track which button is being hovered

const getIconColor = (itemValue: any) => {
  const isSelected = selectedValue.value === itemValue;
  
  // If selected and disabled, keep icon white
  if (isSelected && props.disabled) {
    return 'hsl(var(--twc-white))';
  }
  
  // For selected items being hovered
  if (isSelected && hoveredValue.value === itemValue) {
    return 'hsl(var(--twc-primary))';
  }
  
  // Default colors
  return isSelected ? 'hsl(var(--twc-white))' : 'hsl(var(--twc-primary))';
}

/**
 * Field validation using vee-validate
 */
 const { value, errorMessage } = useField(props.name, undefined, {
  validateOnMount: false,
  validateOnValueUpdate: false
});

const displayError = computed(() => {
  // Only show error if there's an explicit prop error
  return props.error || undefined;
  // Remove errorMessage.value from the computation
});

const getBorderStyle = (item: any) => {
  if (displayError.value) {
    return { border: '2px solid rgb(220 38 38)' };
  }
  if (selectedValue.value === item.value && !props.showIcon) {
    return { border: '2px solid hsl(var(--twc-primary))' };
  }
  return { border: props.showIcon ? '1px solid hsl(var(--twc-primary))' : '1px solid #aaa' };
};

const isSelectedAndDisabled = (itemValue: any) => {
  return selectedValue.value === itemValue && props.disabled;
};

const getIconType = (itemValue: any) => {
  return 'arrow-right';
};
</script>

<style scoped lang="scss">
.button-group {
  @apply flex flex-col justify-evenly flex-wrap gap-2 mb-4;
  
  span.text-h2 {
    @apply font-bold;
  }
  
  &__label {
    @apply flex items-center justify-between px-5 h-14 text-lg rounded-lg
           transition-all duration-300 cursor-pointer w-full
           bg-transparent text-primary;
    border: 1px solid #aaa;

    // Only apply hover effects on devices that support hover
    @media (hover: hover) {
      &:hover {
        @apply bg-primary/10;
      }
    }

    @apply focus-visible:ring-2 focus-visible:ring-primary;

    &--selected {
      @apply border-2 border-primary text-white bg-primary;
      
      &.disabled {
        opacity: 0.5;
        @apply bg-primary text-white;  // Keep primary color when disabled
      }
    }

    // Only apply selected hover effects on devices that support hover
    &--selected-hover {
      @media (hover: hover) {
        @apply text-primary bg-primary/10;

        .button-group__icon {
          @apply text-primary;
        }
      }
    }

    &:focus-visible {
      outline: none;
    }

    &.disabled {
      opacity: 1;
      cursor: not-allowed;
      pointer-events: none;  // Prevent hover effects when disabled
    }

    &.error-border {
      border-color: #ec0000;
    }
  }

  &__input {
    @apply sr-only;
  }

  &__text {
    @apply select-none font-medium;
  }

  &__icon {
    transition: color 0.3s;
  }
}

.error {
  color: #ec0000;
  margin-top: -5px;
}

.error-state .button-group__label {
  border-color: #ec0000;
}

.input-error {
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.text-error {
  color: rgb(220 38 38);
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid hsl(var(--twc-white));
  border-top: 3px solid transparent;
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>