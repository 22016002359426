<!-- 
  @file LoadingOverlay.vue - Loading State Overlay Component
  @description
  A reusable overlay component that displays a spinning loader and optional message.
  Creates a semi-transparent overlay that covers the entire viewport and centers
  its contents both vertically and horizontally.

  Features:
  - Full viewport overlay with semi-transparent background
  - Animated spinning loader
  - Customizable loading message
  - High z-index to ensure visibility
  - Centered content layout

  Usage example:
  <LoadingOverlay message="Please wait..." />
-->
<template>
  <div class="loading-overlay">
    <div class="loading-spinner"></div>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script lang="ts">
/**
 * @component LoadingOverlay
 */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingOverlay',
  
  props: {
    /**
     * Optional message to display below the spinner
     * @default 'Loading...'
     */
    message: {
      type: String,
      default: 'Loading...'
    }
  }
});
</script>

<style scoped>
/**
 * Creates a fixed position overlay that covers the entire viewport
 * with a semi-transparent white background
 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/**
 * Creates a circular spinning animation using border properties
 * Size: 50x50px
 * Border: 3px with different colors for the spinning effect
 */
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid hsl(var(--twc-primary));
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/**
 * Styles for the optional message text
 */
p {
  margin-top: 1rem;
  color: #666;
}

/**
 * Defines the rotation animation for the spinner
 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style> 