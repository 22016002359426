<!--
  @component Header
  @description
  Main header component that adapts its display based on theme (default/affiliate) and current page.
  Handles logo display, trust indicators, progress tracking, and vehicle search functionality.

  Layout Structure:
  1. Top Section: Logo + Trust Indicators
  2. Progress Bar (conditional)
  3. Content Section with rotating messages
  4. Vehicle Search (conditional)

  @features
  - Theme-aware display (default/affiliate modes)
  - Rotating content messages with icons
  - Progress tracking
  - Trustpilot integration
  - Responsive design
  - Vehicle search integration
  - Dealer branding support

  @dependencies
  - progressStore: Manages application progress state
  - configStore: Handles theme and affiliate configuration
  - DealerService: Fetches dealer information
-->

<template>
  <div class="flex flex-col" ref="headerRef" >
    <!-- Logo Section -->
    <div v-if="!isVehicleSearch" class="flex justify-between items-center py-3 max-md:pl-5 max-md:pr-4">
      <div class="logo">
        <Icon
          icon="stacked-logo"
          icon-color="primary"
          stroke="none"
          width="70.15"
          height="60"
        />
      </div>
      <div>
        <Trustpilot 
          v-if="isDefaultTheme"
          widget="mini" 
          darkIcon="true"
          class="w-[140.74px] h-[63px]" 
        />
        <template v-else>
          <div v-if="isLogoLoading" class="flex items-center justify-center">
            <div class="loading-spinner"></div>
          </div>
          <img
            v-show="!isLogoLoading"
            :src="logoUrl"
            :width="logoSize.width || '100%'"
            :height="logoSize.height || '60'"
            loading="eager"
            fetchpriority="high"
            decoding="sync"
            @load="isLogoLoading = false"
          />
        </template>
      </div>
    </div>

    <!-- Vehicle Search Header -->
    <div v-else class="flex justify-between items-center py-3 max-md:pl-5 max-md:pr-4">
      <div class="logo">
        <div v-if="isLogoLoading" class="flex items-center justify-center">
          <div class="loading-spinner"></div>
        </div>
        <div class="font-bold" v-else>{{ dealerName }}</div>
      </div>
      <div>
        <Icon
          icon="powered-by-logo"
          icon-color="black"
          stroke="none"
          width="120"
          height="60"
        />
      </div>
    </div>

    <!-- Content Section -->
    <div 
      v-if="progressStore.pageName !== 'prepop-form'"
      :class="[
        !isDefaultTheme && (progressStore.pageName === 'finance-details' || progressStore.pageName === 'submission-type' ) || progressStore.pageName === 'dealer-search'
          ? ( progressStore.pageName !== 'dealer-search' ? 'mb-4 p-5' : ' px-5 pt-5 pb-4 ') 
          : 'p-[14px]'
      ]"
      class="text-dark-1 text-center bg-usp"
      role="status"
      aria-live="polite"
    >
      <!-- Default Theme Content -->
      <transition v-if="isDefaultTheme && progressStore.pageName !== 'dealer-search'" name="fade" mode="out-in">
        <p :key="currentContent.text" class="flex justify-center items-center gap-3">
          <Icon
            v-bind="contentIconProps"
            :class="{ 'opacity-50': isIconLoading }"
            @loading="isIconLoading = true"
            @loaded="isIconLoading = false"
          />
          <span class="text-body3 text-dark-1">{{ currentContent.text }}</span>
        </p>
      </transition>

      <!-- Affiliate Theme Content -->
      <div v-else>
        <div v-if="progressStore.pageName === 'finance-details' || progressStore.pageName === 'submission-type' || progressStore.pageName === 'dealer-search' || (progressStore.pageName === 'driving-licence' && progressStore.formType === 'partnerWithVehicleType')">
          <p :class="progressStore.pageName !== 'dealer-search' ? 'text-h5 text-dark-1 text-left pb-5 sm:max-w-none max-w-[270px]' : 'text-h2 text-dark-1 text-left pb-5 ' ">
            Get your finance quote with Car Finance 247
          </p>
          <Benefits />
          <Trustpilot widget="mini" darkIcon="true" class="w-[140.74px] h-[63px] mt-2" />
        </div>
        <transition v-else name="fade" mode="out-in">
          <p :key="currentContent.text" class="flex justify-center items-center gap-3">
            <Icon
              v-bind="contentIconProps"
              :class="{ 'opacity-50': isIconLoading }"
              @loading="isIconLoading = true"
              @loaded="isIconLoading = false"
            />
            <span class="text-body3 text-dark-1">{{ currentContent.text }}</span>
          </p>
        </transition>
      </div>
    </div>

    <!-- Vehicle Search Input -->
    <div v-if="isVehicleSearch" ref="vehicleSearchRef" >
        <VehicleSearchInput 
          class="px-5 pb-5"
          id="dealer-search-page-input"
          name="vehicleSearchStr"
          label="Search for a vehicle"
          type="text"
          :model-value="values.vehicleSearchStr"
          @update:model-value="setFieldValue('vehicleSearchStr', values.vehicleSearchStr)"
          :sensitive-data="true"
          :is-data-loaded="vehicleStore.isFullVehicleSearchDataLoaded"
          placeholder="e.g. Ford Fiesta"
         
          :class="isScrolled ? 'pt-5' : ''"
          :isScrolled="isScrolled"
          :headerWidth="headerWidth"
        />
    </div>

    <!-- Progress Bar -->
    <ProgressBar 
      v-if="( (isDefaultTheme && !isVehicleSearch ) || (!isDefaultTheme && progressStore.pageName !== 'finance-details') && progressStore.pageName !== 'dealer-search') && progressStore.pageName !== 'prepop-form' && progressStore.pageName !== 'submission-type'"
      :progress="progressStore.currentProgress" 
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, inject } from 'vue'
import { useProgressStore } from '@/stores/modules/progressStore'
import { useConfigStore } from '@/stores/modules/configStore'
import { useForm } from 'vee-validate'
import { URLS } from '../factories'
import ProgressBar from '@/components/ProgressBar.vue'
import Benefits from '@/components/Benefits.vue'
import VehicleSearchInput from '@/components/VehicleSearchInput.vue'
import { useVehicleStore } from '@/stores/modules/vehicleStore'

// Stores
const progressStore = useProgressStore()
const configStore = useConfigStore()
const vehicleStore = useVehicleStore()
const headerRef = ref(null);
const vehicleSearchRef = ref(null);

const isVehicleSearchToped = inject<boolean>('isVehicleSearchToped');

// Constants
const ROTATION_INTERVAL = 4000
const dotCMSUrl = configStore.isProduction ? URLS.production.dotCMSApiUrl : URLS.replica.dotCMSApiUrl

// State
const currentIndex = ref(0)
const isIconLoading = ref(false)
const isLogoLoading = ref(true)
let rotationInterval: number

// Form handling
const { values, setFieldValue } = useForm({
  initialValues: {
    vehicleSearchStr: null,
  },
  validationSchema: {
    vehicleSearchStr: null
  }
})

// Content configuration
interface Contents {
  PRIVACY_SECTION_CONTENT?: Record<string, any>
  HEADER_CONTENT?: Array<any>
}

const { PRIVACY_SECTION_CONTENT = {}, HEADER_CONTENT = [] } = 
  (configStore.affiliateConfig.contents || {}) as Contents

// Computed properties
const currentContent = computed(() => HEADER_CONTENT[currentIndex.value])
const isDefaultTheme = computed(() => !configStore.isDefaultTheme)
const isVehicleSearch = computed(() => 
  progressStore.pageName === 'dealer-search' && configStore.did
)
const dealerName = computed(() => configStore.dealerConfig.name)

const logoUrl = computed(() => {
    return dotCMSUrl + configStore.affiliateConfig.logo
})

const logoSize = computed(() => {
    return { 'width': configStore.affiliateConfig.logoWidth, 'height': configStore.affiliateConfig.logoHeight }
})

const contentIconProps = computed(() => ({
  icon: currentContent.value.icon,
  'icon-color': 'none',
  stroke: 'dark-1',
  width: '20',
  height: '20'
}))

// Methods
const rotateContent = () => {
  currentIndex.value = (currentIndex.value + 1) % HEADER_CONTENT.length
}

const isScrolled = ref(false);
const headerWidth = ref(0);

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};
const handleScroll = () => {
  const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
  isScrolled.value = scrollPosition >= 420;
  isVehicleSearchToped.value = isScrolled.value;
};

const handleResize = () => {
  headerWidth.value = headerRef.value.getBoundingClientRect().width;
};

const debouncedResize = debounce(handleResize, 50);

// Lifecycle hooks
onMounted(() => {
  rotationInterval = setInterval(rotateContent, ROTATION_INTERVAL)
  isLogoLoading.value = false
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', debouncedResize);

  headerWidth.value = headerRef.value.getBoundingClientRect().width;
})

onUnmounted(() => {
  clearInterval(rotationInterval)
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:deep(#trustpilot-light) {
  fill: #000;
}

:deep(.trustpilot-widget-mini) {
  .tp-widget-businessinfo__trustscore,
  .tp-widget-businessinfo__total {
    font-size: 9px;
  }
  
  .tp-widget-stars {
    margin: 0 0 0px;
  }
  
  .tp-widget-logo {
    margin: 0px 0px 4px!important;
  }

  .tp-widget-profile-link {
    padding: 0;
  }
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid hsl(var(--twc-primary));
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>