import * as Sentry from '@sentry/browser';
import type { AffiliateConfig } from '@/types/configTypes';
import { RequestHelper, LocationHelper } from '@/helpers';
import { DEFAULT_VALUE } from '@/constants';
import { useConfigStore } from '@/stores/modules/configStore';
import {
  HEADER_CONTENT,
  PRIVACY_SECTION_CONTENT,
  DOTCMS_CONTENT_ID,
  TERMS_SECTION_CONTENT,
  ADDRESS_SECTION_CONTENT,
  ADDRESS_LENGTH_CONTENT,
  ADDRESS_STATUS_CONTENT,
  AMOUNT_TO_FINANCE_CONTENT,
  CONTACT_DETAILS_CONTENT,
  CONTACT_DETAILS_PART2_CONTENT,
  DATE_OF_BIRTH_CONTENT,
  DRIVING_LICENCE_CONTENT,
  EMPLOYMENT_INCOME_CONTENT,
  EMPLOYMENT_LENGTH_CONTENT,
  EMPLOYMENT_LOCATION_CONTENT,
  EMPLOYMENT_STATUS_CONTENT,
  FINANCE_DETAILS_CONTENT,
  GET_MY_QUOTE_CONTENT,
  MARITAL_STATUS_CONTENT,
  THANK_YOU_CONTENT,
  NAVIGATION_CONTENT,
  PRE_POP_CONTENT,
  FINANCE_DISCLAIMER
} from '../constants/content';

// Create a consolidated content object
const defaultContent = {
  HEADER_CONTENT,
  PRIVACY_SECTION_CONTENT,
  DOTCMS_CONTENT_ID,
  TERMS_SECTION_CONTENT,
  ADDRESS_SECTION_CONTENT,
  ADDRESS_LENGTH_CONTENT,
  ADDRESS_STATUS_CONTENT,
  AMOUNT_TO_FINANCE_CONTENT,
  CONTACT_DETAILS_CONTENT,
  CONTACT_DETAILS_PART2_CONTENT,
  DATE_OF_BIRTH_CONTENT,
  DRIVING_LICENCE_CONTENT,
  EMPLOYMENT_INCOME_CONTENT,
  EMPLOYMENT_LENGTH_CONTENT,
  EMPLOYMENT_LOCATION_CONTENT,
  EMPLOYMENT_STATUS_CONTENT,
  FINANCE_DETAILS_CONTENT,
  GET_MY_QUOTE_CONTENT,
  MARITAL_STATUS_CONTENT,
  THANK_YOU_CONTENT,
  NAVIGATION_CONTENT,
  PRE_POP_CONTENT,
  FINANCE_DISCLAIMER
};

// Define a type for the contents
type ContentKeys = keyof typeof defaultContent;
type ContentsType = Partial<Record<ContentKeys, any>>;

/**
 * Class responsible for managing affiliate configurations and mappings.
 */
export class AffiliateMapper {
  private static affiliateMap: Record<string, AffiliateConfig> = {
    'default': {
      affiliateUserId: 0,
      companyName: "default",
      loadVehicleFromQuote: false,
      loanAmount: 10000,
      logo: "/dA/ddfa4d712c/carfinance247-logo.svg?t=20250121115512",
      logoHeight: 60,
      logoWidth: 70.15,
      prePopulateForm: true,
      systemName: "default",
      theme: "default",
      useDefaultLang: true,
      contents: defaultContent,
      themeColors: {}
    },
    'comparethemarket': {
      companyName: "Compare the Market",
      prePopulateForm: false,
      affiliateUserId: 42416,
      theme: 'comparethemarket',
      systemName: 'comparethemarket',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'admiral': {
      companyName: "Admiral",
      prePopulateForm: true,
      affiliateUserId: 19773,
      theme: 'admiral',
      systemName: 'admiral',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'autovillage': {
      companyName: "AutoVillage",
      prePopulateForm: false,
      affiliateUserId: 1664,
      theme: 'autovillage',
      systemName: 'autovillage',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'autovolo': {
      companyName: "Autovolo",
      prePopulateForm: false,
      affiliateUserId: 16588,
      theme: 'autovolo',
      systemName: 'autovolo',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'allpurposeloans': {
      companyName: "All Purpose Loans",
      prePopulateForm: true,
      affiliateUserId: 21723,
      theme: 'allpurposeloans',
      systemName: 'allpurposeloans',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'drivescore': {
      companyName: "DriveScore",
      prePopulateForm: false,
      affiliateUserId: 42294,
      theme: 'drivescore',
      systemName: 'drivescore',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'mycarcheck': {
      companyName: "MyCarCheck",
      prePopulateForm: false,
      affiliateUserId: 42399,
      theme: 'mycarcheck',
      systemName: 'mycarcheck',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'comparethemarketcarfinance': {
      companyName: "Compare the Market",
      prePopulateForm: false,
      affiliateUserId: 42416,
      theme: 'comparethemarket',
      systemName: 'comparethemarket',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'ckdirect': {
      companyName: "Credit Karma",
      prePopulateForm: false,
      affiliateUserId: 21746,
      theme: 'ckdirect',
      systemName: 'ckdirect',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'carcredible': {
      companyName: "Car Credible",
      prePopulateForm: false,
      affiliateUserId: 40588,
      theme: 'carcredible',
      systemName: 'carcredible',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'carsnitch': {
      companyName: "CarSnitch",
      prePopulateForm: false,
      affiliateUserId: 40643,
      theme: 'carsnitch',
      systemName: 'carsnitch',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'carcraft': {
      companyName: "Carcraft",
      prePopulateForm: false,
      affiliateUserId: 2655,
      theme: 'carcraft',
      systemName: 'carcraft',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'ckdirect-submission': {
      companyName: "Credit Karma",
      prePopulateForm: false,
      affiliateUserId: 41183,
      theme: 'ckdirect',
      systemName: 'ckdirect-submission',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'ckdirect-whitelabel': {
      companyName: "Credit Karma",
      prePopulateForm: false,
      affiliateUserId: 21746,
      theme: 'ckdirect',
      systemName: 'ckdirect-whitelabel',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'ckdirect-submission-whitelabel': {
      companyName: "Credit Karma",
      prePopulateForm: false,
      affiliateUserId: 41183,
      theme: 'ckdirect',
      systemName: 'ckdirect-submission-whitelabel',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'carfinderscotland': {
      companyName: "Car Finder Scotland",
      prePopulateForm: false,
      affiliateUserId: 16568,
      theme: 'carfinderscotland',
      systemName: 'carfinderscotland',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'cargurus': {
      companyName: "CarGurus",
      prePopulateForm: false,
      affiliateUserId: 21617,
      theme: 'cargurus',
      systemName: 'cargurus',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'cargurus-submission': {
      companyName: "CarGurus",
      prePopulateForm: false,
      affiliateUserId: 21617,
      theme: 'cargurus-submission',
      systemName: 'cargurus-submission',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'carsite': {
      companyName: "CarSite",
      prePopulateForm: false,
      affiliateUserId: 2515,
      theme: 'carsite',
      systemName: 'carsite',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'cazana': {
      companyName: "Cazana",
      prePopulateForm: false,
      affiliateUserId: 18796,
      theme: 'cazana',
      systemName: 'cazana',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'clearscore': {
      companyName: "ClearScore",
      prePopulateForm: true,
      affiliateUserId: 17253,
      theme: 'clearscore',
      systemName: 'clearscore',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'confused': {
      companyName: "Confused.com",
      prePopulateForm: true,
      affiliateUserId: 19249,
      theme: 'confused',
      systemName: 'confused',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'credability': {
      companyName: "Credability",
      prePopulateForm: true,
      affiliateUserId: 20123,
      theme: 'credability',
      systemName: 'credability',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'credapp': {
      companyName: "CredApp",
      prePopulateForm: false,
      affiliateUserId: 21737,
      theme: 'credapp',
      systemName: 'credapp',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'desperateseller': {
      companyName: "Desperate Seller",
      prePopulateForm: false,
      affiliateUserId: 18782,
      theme: 'desperateseller',
      systemName: 'desperateseller',
      loadVehicleFromQuote: true,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'equifax': {
      companyName: "Equifax",
      prePopulateForm: false,
      affiliateUserId: 41999,
      theme: 'equifax',
      systemName: 'equifax',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'experian': {
      companyName: "Experian",
      prePopulateForm: true,
      affiliateUserId: 1798,
      theme: 'experian',
      systemName: 'experian',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'experianlimited': {
      companyName: "Experian",
      prePopulateForm: true,
      affiliateUserId: 42465,
      theme: 'experianlimited',
      systemName: 'experianlimited',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'totallymoney': {
      companyName: 'Totally Money',
      prePopulateForm: true,
      affiliateUserId: 2439,
      theme: 'totallymoney',
      systemName: 'totallymoney',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'gocompare': {
      companyName: 'Go Compare',
      prePopulateForm: true,
      affiliateUserId: 21175,
      theme: 'gocompare',
      systemName: 'gocompare',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'facebook': {
      companyName: "Facebook",
      prePopulateForm: false,
      affiliateUserId: null,
      theme: 'facebook',
      systemName: 'facebook',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'freedomfinance': {
      companyName: "Freedom Finance",
      prePopulateForm: true,
      affiliateUserId: 3550,
      theme: 'freedomfinance',
      systemName: 'freedomfinance',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'gowizard': {
      companyName: "Go Wizard",
      prePopulateForm: false,
      affiliateUserId: 20383,
      theme: 'gowizard',
      systemName: 'gowizard',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'honestjohn': {
      companyName: "HonestJohn",
      prePopulateForm: false,
      affiliateUserId: 17094,
      theme: 'honestjohn',
      systemName: 'honestjohn',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'knowyourmoney': {
      companyName: "Know Your Money",
      prePopulateForm: false,
      affiliateUserId: 18781,
      theme: 'knowyourmoney',
      systemName: 'knowyourmoney',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'money': {
      companyName: "Money",
      prePopulateForm: false,
      affiliateUserId: 2428,
      theme: 'money',
      systemName: 'money',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'moneyguru': {
      companyName: "Money Guru",
      prePopulateForm: false,
      affiliateUserId: 16758,
      theme: 'moneyguru',
      systemName: 'moneyguru',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'moneysupermarket': {
      companyName: "MoneySuperMarket",
      prePopulateForm: true,
      affiliateUserId: 20351,
      theme: 'moneysupermarket',
      systemName: 'moneysupermarket',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'moneysavingexpert': {
      companyName: "MoneySavingExpert",
      prePopulateForm: true,
      affiliateUserId: 20564,
      theme: 'moneysavingexpert',
      systemName: 'moneysavingexpert',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'monevo': {
      companyName: "Monevo",
      prePopulateForm: true,
      affiliateUserId: 17137,
      theme: 'monevo',
      systemName: 'monevo',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'motors': {
      companyName: "Motors.co.uk Limited",
      prePopulateForm: false,
      affiliateUserId: 2775,
      theme: 'motors',
      systemName: 'motors',
      loadVehicleFromQuote: true,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'oceancar': {
      companyName: "Ocean Car",
      prePopulateForm: false,
      affiliateUserId: 21884,
      theme: 'oceancar',
      systemName: 'oceancar',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'oceancar-submission': {
      companyName: "Ocean Car",
      prePopulateForm: false,
      affiliateUserId: 21884,
      theme: 'oceancar',
      systemName: 'oceancar-submission',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'oceanfinance': {
      companyName: "Ocean Finance",
      prePopulateForm: false,
      affiliateUserId: 20124,
      theme: 'oceanfinance',
      systemName: 'oceanfinance',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'parkers': {
      companyName: "Parkers",
      prePopulateForm: false,
      affiliateUserId: 17447,
      theme: 'parkers',
      systemName: 'parkers',
      loadVehicleFromQuote: true,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'parkers-submission': {
      companyName: "Parkers.co.uk Limited",
      prePopulateForm: false,
      affiliateUserId: 17447,
      theme: 'parkers',
      systemName: 'parkers-submission',
      loadVehicleFromQuote: true,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'pistonheads': {
      companyName: "PistonHeads",
      prePopulateForm: false,
      affiliateUserId: 43118,
      theme: 'pistonheads',
      systemName: 'pistonheads',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'raccars': {
      companyName: "RAC Cars",
      prePopulateForm: false,
      affiliateUserId: 17177,
      theme: 'raccars',
      systemName: 'raccars',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'usedcarsni': {
      companyName: "Used Cars NI",
      prePopulateForm: false,
      affiliateUserId: 1327,
      theme: 'usedcarsni',
      systemName: 'usedcarsni',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'uswitch': {
      companyName: "uSwitch",
      prePopulateForm: false,
      affiliateUserId: 2411,
      theme: 'uswitch',
      systemName: 'uswitch',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'time4leasing': {
      companyName: "Time4Leasing",
      prePopulateForm: false,
      affiliateUserId: null,
      theme: 'time4leasing',
      systemName: 'time4leasing',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'thinkmoney': {
      companyName: "Think Money",
      prePopulateForm: false,
      affiliateUserId: 2319,
      theme: 'thinkmoney',
      systemName: 'thinkmoney',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'thinkmoneycar': {
      companyName: "Think Money Car",
      prePopulateForm: false,
      affiliateUserId: 21886,
      theme: 'thinkmoneycar',
      systemName: 'thinkmoneycar',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'youngcardriver': {
      companyName: "Young Car Driver",
      prePopulateForm: false,
      affiliateUserId: 2400,
      theme: 'youngcardriver',
      systemName: 'youngcardriver',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'dragonfinance': {
      companyName: "Dragon Finance",
      prePopulateForm: false,
      affiliateUserId: 42157,
      theme: 'dragonfinance',
      systemName: 'dragonfinance',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'firstcentralinsurance': {
      companyName: "1st Central Insurance",
      prePopulateForm: false,
      affiliateUserId: 21341,
      theme: 'firstcentralinsurance',
      systemName: 'firstcentralinsurance',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    '1stcentral': {
      companyName: "1st Central Insurance",
      prePopulateForm: false,
      affiliateUserId: 21341,
      theme: '1stcentral',
      systemName: '1stcentral',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'cheapcarsforsale': {
      companyName: "Cheap Cars For Sale",
      prePopulateForm: false,
      affiliateUserId: 42171,
      theme: 'cheapcarsforsale',
      systemName: 'cheapcarsforsale',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'loaneo': {
      companyName: "Loaneo",
      prePopulateForm: false,
      affiliateUserId: 42170,
      theme: 'loaneo',
      systemName: 'loaneo',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'prefinance': {
      companyName: "Pre Finance",
      prePopulateForm: false,
      affiliateUserId: 43424,
      theme: 'prefinance',
      systemName: 'prefinance',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    },
    'regit': {
      companyName: "Regit",
      prePopulateForm: false,
      affiliateUserId: 43745,
      theme: 'regit',
      systemName: 'regit',
      loadVehicleFromQuote: false,
      useDefaultLang: true,
      loanAmount: 10000,
      logo: null,
      logoWidth: 120,
      logoHeight: 60,
      contents: {},
      themeColors: {}
    }
  };

  private static readonly aidToThemeMap: Record<string, string> = {
    '19773': 'admiral',
    '1664': 'autovillage',
    '16588': 'autovolo',
    '21723': 'allpurposeloans',
    '42294': 'drivescore',
    '42399': 'mycarcheck',
    '42416': 'comparethemarket',
    '21746': 'ckdirect',
    '40588': 'carcredible',
    '40643': 'carsnitch',
    '2655': 'carcraft',
    '41183': 'ckdirect-submission',
    '16568': 'carfinderscotland',
    '21617': 'cargurus',
    '2515': 'carsite',
    '18796': 'cazana',
    '17253': 'clearscore',
    '19249': 'confused',
    '20123': 'credability',
    '21737': 'credapp',
    '18782': 'desperateseller',
    '41999': 'equifax',
    '1798': 'experian',
    '42465': 'experianlimited',
    '2439': 'totallymoney',
    '21175': 'gocompare',
    '3550': 'freedomfinance',
    '20383': 'gowizard',
    '17094': 'honestjohn',
    '18781': 'knowyourmoney',
    '2428': 'money',
    '16758': 'moneyguru',
    '20351': 'moneysupermarket',
    '20564': 'moneysavingexpert',
    '17137': 'monevo',
    '2775': 'motors',
    '21884': 'oceancar',
    '20124': 'oceanfinance',
    '17447': 'parkers',
    '43118': 'pistonheads',
    '17177': 'raccars',
    '1327': 'usedcarsni',
    '2411': 'uswitch',
    '2319': 'thinkmoney',
    '21886': 'thinkmoneycar',
    '2400': 'youngcardriver',
    '42157': 'dragonfinance',
    '21341': 'firstcentralinsurance',
    '42171': 'cheapcarsforsale',
    '42170': 'loaneo',
    '43424': 'prefinance',
    '43745': 'regit'
  };

  private static readonly THEME_MAPPING: Record<string, string> = {
    'comparethemarketcarfinance': 'comparethemarket',
    'comparethemarketfinance': 'comparethemarket'
  };

  /**
   * Maps a theme name to its standardized version
   * @param theme - The theme name to map
   * @returns The mapped theme name
   */
  private static mapTheme(theme: string | null): string | null {
    if (!theme) return null;
    return this.THEME_MAPPING[theme] || theme;
  }

  /**
   * Retrieves the theme based on URL parameters.
   * @param urlParams - The URLSearchParams object containing query parameters.
   * @returns The theme name or null if not found.
   */
  static getThemeFromUrl(urlParams: URLSearchParams): string | null {
    const theme = this.mapTheme(urlParams.get('theme'));

    if (theme && this.affiliateMap[theme]) {
      return this.affiliateMap[theme].theme || theme;
    }

    const aid = urlParams.get('aid');
    if (aid) {
      for (const key in this.affiliateMap) {
        if (this.affiliateMap[key].affiliateUserId === parseInt(aid)) {
          return this.affiliateMap[key].theme;
        }
      }
    }

    return null;
  }

  /**
   * Retrieves the affiliate configuration based on an identifier.
   * @param identifier - The affiliate identifier, which can be a theme or affiliateUserId.
   * @returns The AffiliateConfig object or null if not found.
   */
  static getAffiliateConfig(identifier: string): AffiliateConfig | null {
    // Map theme aliases
    identifier = this.mapTheme(identifier) || identifier;

    let config = null;

    if (this.affiliateMap[identifier]) {
      config = { ...this.affiliateMap[identifier] };
    } else {
      const affiliateUserId = parseInt(identifier);
      if (!isNaN(affiliateUserId)) {
        for (const key in this.affiliateMap) {
          if (this.affiliateMap[key].affiliateUserId === affiliateUserId) {
            config = { ...this.affiliateMap[key] };
            break;
          }
        }
      }
    }

    if (!config) {
      console.warn(`No specific config found for identifier: ${identifier}, using default config.`);
      config = { ...this.affiliateMap['default'] };
    }

    if (!config.contents) {
      config.contents = { ...defaultContent } as ContentsType;
    }

    for (const key in defaultContent) {
      const contentKey = key as ContentKeys;
      if (!(config.contents as ContentsType)[contentKey]) {
        (config.contents as ContentsType)[contentKey] = defaultContent[contentKey];
      }
    }

    return config;
  }

  /**
   * Retrieves the company name based on an identifier.
   * @param identifier - The affiliate identifier.
   * @returns The company name or null if not found.
   */
  static getCompanyName(identifier: string): string | null {
    const config = this.getAffiliateConfig(identifier);
    return config?.companyName || null;
  }

  /**
   * Retrieves the affiliate user ID based on an identifier.
   * @param identifier - The affiliate identifier.
   * @returns The affiliate user ID or null if not found.
   */
  static getAffiliateUserId(identifier: string): number | null {
    const config = this.getAffiliateConfig(identifier);
    return config?.affiliateUserId || null;
  }

  /**
   * Initializes the affiliate configuration by fetching data from an API.
   * Applies default content if fetching fails.
   */
  static async initAffiliateConfig() {
    try {
      const url = this.constructAffiliateUrl();
      if (!url) {
        this.applyDefaultContent();
        return;
      }

      const theme = new URL(url).searchParams.get('host');
      if (theme && !this.affiliateMap[theme]) {
        const warningMessage = `Unrecognized theme: ${theme}, using default config.`;
        this.logWarning(warningMessage);
        this.applyDefaultContent();
        return;
      }

      const data = await this.fetchAffiliateData(url);
      if (!data) {
        this.applyDefaultContent();
        return;
      }

      const affiliateKey = Object.keys(data)[0];
      if (!affiliateKey) {
        throw new Error('Invalid response data structure');
      }

      if (data[affiliateKey] && Object.keys(data[affiliateKey]).length > 0) {
        console.log('Successfully fetched affiliate data for:', affiliateKey);

        this.setAffiliateConfig(data);

        await this.handleThemeColors(affiliateKey, data);

        const affiliateConfig = this.getAffiliateConfig(affiliateKey);
        if (affiliateConfig && affiliateConfig.loanAmount) {
          DEFAULT_VALUE.setLoanAmount(affiliateConfig.loanAmount);
        }
      } else {
        const warningMessage = `No affiliate data found for key: ${affiliateKey}, using local content as fallback.`;
        this.logWarning(warningMessage);
        this.applyDefaultContent();
      }
    } catch (error) {
      this.logError('Fatal error in initAffiliateConfig', error);
      this.applyDefaultContent();
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  /**
   * Constructs the URL for fetching affiliate data.
   * @returns The constructed URL or null if invalid.
   */
  private static constructAffiliateUrl(): string | null {
    const configStore = useConfigStore();
    const aid = RequestHelper.getQueryVariable('aid');
    const theme = this.mapTheme(RequestHelper.getQueryVariable('theme'));
    const basicUrl = `https://apim.247${configStore.isProduction ? 'prod' : 'dev'}.io/api/apply/v1/dotcms_affiliate`;

    if (aid) {
      return `${basicUrl}?aid=${aid}&type=content`;
    } 
    
    if (!LocationHelper.isLocalHost() || theme) {
      const host = theme ?? LocationHelper.getHost();
      return `${basicUrl}?host=${host}&type=content`;
    } 
    
    return `${basicUrl}?host=default&type=content`;
  }

  /**
   * Fetches affiliate data from the given URL.
   * @param url - The URL to fetch data from.
   * @returns The fetched data or null if an error occurs.
   */
  private static async fetchAffiliateData(url: string): Promise<any | null> {
    try {
      console.log('Fetching affiliate data from:', url);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }

      const responseData = await response.json();
      return responseData.data || responseData;
    } catch (fetchError) {
      this.logError('Error fetching affiliate data', fetchError);
      return null;
    }
  }

  /**
   * Applies default content to all affiliates in case of errors or empty data.
   */
  private static applyDefaultContent() {
    console.warn('Applying default content due to previous errors or empty data.');
    const existingConfig = { ...this.affiliateMap };
    Object.keys(existingConfig).forEach((key) => {
      existingConfig[key] = {
        ...existingConfig[key],
        contents: defaultContent
      };
    });

    this.affiliateMap = existingConfig;

    if (!this.affiliateMap['default'].contents) {
      this.affiliateMap['default'].contents = defaultContent;
    }

    Object.keys(this.affiliateMap).forEach(key => {
      if (!this.affiliateMap[key].contents) {
        this.affiliateMap[key].contents = defaultContent;
      }
    });
  }

  /**
   * Handles theme colors by applying them to the document's style.
   * @param affiliateKey - The key of the affiliate.
   * @param data - The affiliate data containing theme colors.
   */
  private static async handleThemeColors(affiliateKey: string, data: any) {
    try {
      const themeColors = this.getAffiliateConfig(affiliateKey)?.themeColors;
      if (!themeColors) return;

      const affiliateColorObj = typeof themeColors === 'string'
        ? JSON.parse(themeColors)
        : themeColors;

      if (affiliateColorObj && Object.keys(affiliateColorObj).length > 0) {
        const styleArr = Object.entries(affiliateColorObj)
          .filter(([key]) => key.includes('_hsl'))
          .map(([key]) => {
            const colorName = key.slice(0, key.indexOf('_hsl'));
            return `--twc-${colorName}: ${affiliateColorObj[key]};`;
          });

        if (styleArr.length > 0) {
          const styleElement = document.createElement('style');
          styleElement.textContent = `
            [data-theme=${data[affiliateKey].theme}] {
              ${styleArr.join(' ')}
            }
          `;
          document.head.appendChild(styleElement);
        }
      }
    } catch (colorError) {
      this.logError('Error processing theme colors', colorError);
    }
  }

  /**
   * Sets the affiliate configuration with new data.
   * @param newAffiliateObj - The new affiliate configuration object.
   */
  static setAffiliateConfig(newAffiliateObj: Record<string, AffiliateConfig>) {
    // Map any aliased themes to their standard names
    Object.entries(this.THEME_MAPPING).forEach(([alias, standard]) => {
      if (newAffiliateObj[alias]) {
        newAffiliateObj[standard] = {
          ...newAffiliateObj[alias],
          systemName: standard
        };
        delete newAffiliateObj[alias];
      }
    });

    this.affiliateMap = newAffiliateObj;

    try {
      const firstKey = Object.keys(newAffiliateObj)[0];
      const contents = newAffiliateObj[firstKey].contents;

      if (typeof contents === 'string') {
        this.affiliateMap[firstKey].contents = JSON.parse(contents);
      } else {
        this.logWarning('Contents is not a string');
        this.affiliateMap[firstKey].contents = defaultContent;
      }
    } catch (error) {
      this.logError('Error parsing contents', error);
      Object.keys(this.affiliateMap).forEach((key) => {
        this.affiliateMap[key].contents = defaultContent;
      });
    }
  }

  /**
   * Logs an error message and captures the exception with Sentry.
   * @param message - The error message.
   * @param error - The error object.
   */
  private static logError(message: string, error: any) {
    console.error(message, error);
    Sentry.captureException(error); // Capture error in Sentry
  }

  /**
   * Logs a warning message and captures it with Sentry.
   * @param message - The warning message.
   */
  private static logWarning(message: string) {
    console.warn(message);
    Sentry.captureMessage(message, 'warning'); // Capture warning in Sentry
  }
} 