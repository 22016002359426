<template>
  <ErrorBoundary>
    <div id="app" class="md:max-w-[600px] mx-auto pb-4">
      <ErrorAlert
        v-if="error"
        :message="error.message"
        :showRetry="true"
      />
      <div v-if="isInitialized">
        <Header />
        <div class="max-md:px-5">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
      <LoadingOverlay v-else-if="!error" />
    </div>
  </ErrorBoundary>
</template>

<script setup lang="ts">
/**
 * @file App.vue - Root component of the Vue application
 * @description
 * This component serves as the application's entry point and manages the initialization
 * state of the application, including store initialization and error handling. It
 * implements a loading state while the application initializes and provides error
 * recovery mechanisms.
 * 
 * Features:
 * - Application initialization state management
 * - Error boundary implementation
 * - Loading state handling
 * - Store initialization
 * - Error recovery through retry mechanism
 * 
 * Components used:
 * - ErrorBoundary: Catches and handles unhandled errors in child components
 * - ErrorAlert: Displays error messages with retry functionality
 * - LoadingOverlay: Shows a loading indicator during initialization
 */

import { ref, onMounted, onUnmounted } from 'vue';
import { useStoreInit } from '@/composables/useStoreInit';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import Header from '@/components/Header.vue';
import { useRouter } from 'vue-router';

/**
 * Tracks whether the application has completed initialization
 */
const isInitialized = ref(false);

/**
 * Stores any initialization errors that occur
 */
const error = ref<Error | null>(null);

const { initializeStores, cleanup } = useStoreInit();

const router = useRouter();

/**
 * Initializes the application by setting up required stores and handling any errors
 * that occur during the process.
 * 
 * @async
 * @throws {Error} Initialization errors are caught and stored in error ref
 */
const initializeApp = async () => {
  try {
    error.value = null;
    await initializeStores();
    isInitialized.value = true;
  } catch (err) {
    error.value = err as Error;
    console.error('Failed to initialize app:', err);
  }
};

/**
 * Triggers application initialization on component mount
 */
onMounted(() => {
  initializeApp();
});

/**
 * Cleans up store subscriptions and event listeners when the component is unmounted.
 * This prevents memory leaks and ensures proper cleanup of application resources.
 */
onUnmounted(() => {
  cleanup();
});

// Add navigation guard to reset scroll
router.beforeEach(() => {
  window.scrollTo(0, 0);
});

</script>