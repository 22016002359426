/**
 * @fileoverview Address store module for managing residential and employment addresses
 * Handles address validation, formatting, and manual/lookup toggle functionality
 */

import { defineStore } from 'pinia';
import { handleStoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import type { Address, EmploymentAddress, AddressState } from '@/types/addressTypes';

/**
 * Pinia store for address management
 * Provides centralized state management for:
 * - Residential and employment addresses
 * - Address lookup functionality
 * - Manual address entry
 * - Address formatting and validation
 * 
 * @store address
 */
export const useAddressStore = defineStore('address', {
  /**
   * Initial state of the address store
   * Initializes with empty address templates
   * @returns {AddressState} The address store's initial state
   */
  state: (): AddressState => ({
    savedAddresses: [
      {
        manual: false,
        streetAddress: null,
        value: 0,
      }
    ],
    savedEmploymentAddresses: [
      {
        manual: false,
        streetAddress: null,
        value: 0,
      }
    ],
    fullAddresses: [
      {
          index: 0,
          address: null,
          town: null,
          hideAddressLookup: false,
          manual: false,
          streetAddress: null,
          value: 0
      }
    ],
    fullEmploymentAddresses: [
      {
          index: 0,
          address: null,
          town: null,
          hideAddressLookup: false,
          manual: false,
          streetAddress: null,
          value: 0
      }
    ],
  }),

  actions: {
    /**
     * Toggles address lookup visibility for residential addresses
     * 
     * @param {number} index - Index of the address to toggle
     * @throws {Error} When address index is not found
     */
    setIsAddressConfirmed(index: number) {
      try {
        const idx = this.fullAddresses.findIndex((i) => i.index === index);
        if (idx === -1) {
          throw new Error(`Address with index ${index} not found`);
        }
        this.fullAddresses[idx].hideAddressLookup = !this.fullAddresses[idx].hideAddressLookup;
      } catch (error) {
        handleStoreError('address', 'setIsAddressConfirmed', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Toggles address lookup visibility for employment addresses
     * 
     * @param {number} index - Index of the employment address to toggle
     */
    setIsEmploymentAddressConfirmed(index: number) {
      const idx = this.fullEmploymentAddresses.findIndex((i) => i.index === index);
      this.fullEmploymentAddresses[idx].hideAddressLookup = !this.fullEmploymentAddresses[idx].hideAddressLookup;
    },

    /**
     * Toggles between manual and lookup address entry for residential addresses
     * 
     * @param {number} index - Index of the address to toggle
     * @throws {Error} When address index is not found
     */
    toggleManualAddress(index: number) {
      try {
        if (!this.savedAddresses[index]) {
          throw new Error(`Address with index ${index} not found`);
        }
        this.savedAddresses[index].manual = !this.savedAddresses[index].manual;
      } catch (error) {
        handleStoreError('address', 'toggleManualAddress', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Toggles between manual and lookup address entry for employment addresses
     * 
     * @param {number} index - Index of the employment address to toggle
     */
    toggleManualEmploymentAddress(index: number) {
      this.savedEmploymentAddresses[index].manual = !this.savedEmploymentAddresses[index].manual;
    },

    /**
     * Sets and formats a full residential address
     * Handles address formatting including town and postcode placement
     * Supports pre-populated addresses without formatting
     * 
     * @param {Address} data - Address data to format and save
     * @throws {Error} When address data is missing or invalid
     */
    setFullAddress(data: Address) {
      try {
        if (!data.isFromPrepop) {
          const currentAddress = data;
          const town = currentAddress.town;
          let fullAddress = currentAddress.address;

          if (!fullAddress) {
            throw new Error('Address data is missing');
          }

          const addressSplit = fullAddress.split(' ');
          const unFormattedTown = addressSplit.splice(addressSplit.length - 1, 1).join('');

          const regex = new RegExp(unFormattedTown, 'gi');
          fullAddress = fullAddress.replace(regex, `${town}.`);

          const postcode = addressSplit.splice(0, 2).join(' ');
          fullAddress = `${fullAddress.replace(postcode, '')} ${postcode}`;

          data.address = fullAddress;
        }

        const index = this.fullAddresses.findIndex((i) => i.index === data.index);
        if (index !== -1) {
          this.fullAddresses[index] = data;
        } else {
          this.fullAddresses.push(data);
        }
      } catch (error) {
        handleStoreError('address', 'setFullAddress', error, StoreErrorType.VALIDATION);
      }
    },

    /**
     * Sets and formats a full employment address
     * Handles address formatting including town and postcode placement
     * Supports pre-populated addresses without formatting
     * 
     * @param {EmploymentAddress} data - Employment address data to format and save
     */
    setEmploymentAddress(data: EmploymentAddress) {
      if (!data.isFromPrepop) {
        const currentAddress = data;
        const town = currentAddress.town;
        let fullAddress = currentAddress.address;

        const addressSplit = fullAddress.split(' ');
        const unFormattedTown = addressSplit.splice(addressSplit.length - 1, 1).join('');

        const regex = new RegExp(unFormattedTown, 'gi');
        fullAddress = fullAddress.replace(regex, `${town}.`);

        const postcode = addressSplit.splice(0, 2).join(' ');
        fullAddress = `${fullAddress.replace(postcode, '')} ${postcode}`;

        data.address = fullAddress;
      }

      const index = this.fullEmploymentAddresses.findIndex((i) => i.index === data.index);
      if (index !== -1) {
        this.fullEmploymentAddresses[index] = data;
      } else {
        this.fullEmploymentAddresses.push(data);
      }
    }
  }
}); 