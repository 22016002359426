import type {
  HeaderContent,
  PrivacySectionContent,
  DotCMSContentId,
  TermsSectionContent,
  AddressSectionContent,
  AddressLengthContent,
  AddressStatusContent,
  AmountToFinanceContent,
  ContactDetailsContent,
  ContactDetailsPart2Content,
  DateOfBirthContent,
  DrivingLicenceContent,
  EmploymentIncomeContent,
  EmploymentLengthContent,
  EmploymentLocationContent,
  EmploymentStatusContent,
  FinanceDetailsContent,
  GetMyQuoteContent,
  MaritalStatusContent,
  ThankYouContent,
  NavigationContent,
  PrePopContent,
  RefinanceFormContent
} from '@/types/content';

import contentObj from './contents.json';

/**
 * Content for the application header section.
 * Contains information about service benefits displayed at the top of the application.
 * Each item includes text and an associated icon.
 */
export const HEADER_CONTENT: HeaderContent[] = contentObj['HEADER_CONTENT'];
// [
//   { text: 'Get your personalised quote in minutes', icon: 'timer' },
//   { text: 'No impact to your credit score', icon: 'gauge' },
//   { text: 'We compare over 300 finance products', icon: 'search-check' },
// ];

/**
 * Content for the privacy policy section.
 * Contains benefits list, privacy policy text, and modal-related content.
 * Includes loading and error states for dynamic content.
 */
export const PRIVACY_SECTION_CONTENT: PrivacySectionContent = contentObj['PRIVACY_SECTION_CONTENT'];
// {
//   BENEFITS: [
//     {
//       text: "No impact on your credit score - see if you'll be accepted before choosing to proceed.",
//       icon: 'tick-circle'
//     },
//     {
//       text: 'Your no-obligation quote is just 1 minute away!',
//       icon: 'tick-circle'
//     }
//   ],
//   PRIVACY_TEXT: 'By starting your quote, you agree to our',
//   PRIVACY_LINK_TEXT: 'Privacy Policy',
//   PRIVACY_MODAL_TITLE: 'Privacy Statement',
//   LOADING_TEXT: 'Loading...',
//   ERROR_TEXT: 'Error loading privacy policy'
// }

/**
 * DotCMS content identifiers for retrieving dynamic content.
 * Contains IDs for terms & conditions and privacy policy content.
 */
export const DOTCMS_CONTENT_ID: DotCMSContentId = contentObj['DOTCMS_CONTENT_ID'];
// {
//   TERMS_AND_CONDITIONS: [
//     '1ef2cfeb00b1608ee66b96c66b36764a',
//     'b0aca1412b7df859a0ee5bb2dbbfc5fd'
//   ],
//   PRIVACY_POLICY: [
//     'd84f475d3daf323195b47149473581c7',
//     'd0fd804dd3a36b32607492322245dd54'
//   ]
// }

/**
 * Content for the terms and conditions section.
 * Contains section titles and loading/error states for dynamic content.
 */
export const TERMS_SECTION_CONTENT: TermsSectionContent = contentObj['TERMS_SECTION_CONTENT'];
// {
//   TITLES: [
//     'Terms and Conditions Part 1',
//     'Part 2: Terms and conditions for use of CarFinance 247'
//   ],
//   LOADING_TEXT: 'Loading...',
//   ERROR_TEXT: 'Error loading terms and conditions'
// }

/**
 * Common navigation content used throughout the application.
 * Contains text for previous and continue buttons.
 */
export const NAVIGATION_CONTENT: NavigationContent = contentObj['NAVIGATION_CONTENT'];
// {
//   PREVIOUS_STEP: 'Previous Step',
//   CONTINUE: 'Continue',
//   FINISH: 'Get my quote'
// };

/**
 * Content for the address section.
 * Includes headers, manual entry options, form labels, validation messages,
 * and button text for both default and previous address flows.
 */
export const ADDRESS_SECTION_CONTENT: AddressSectionContent = contentObj['ADDRESS_SECTION_CONTENT'];
// {
//   HEADERS: {
//     DEFAULT: 'Next, where do you live?',
//     PREVIOUS: 'And what was your previous address?',
//     PROMPT: 'Why do we ask this?',
//     INFO: 'Providing your last 3 years of address history helps lenders securely check your details.'
//   },
//   MANUAL_ENTRY: {
//     TITLE: 'Add Address Manually',
//     PROMPT: "Can't see your address?",
//     LINK_TEXT: 'Enter address manually',
//     MANUAL_LINK: 'Manually enter address details',
//     SEARCH_LINK: 'Search for an address',
//   },
//   BUTTONS: {
//     EDIT: 'Change Address',
//   },
//   LABELS: {
//     ADDRESS_LOOKUP: 'Search for your address',
//     FLAT_NUMBER: 'Flat or Unit Number',
//     BUILDING_NAME: 'Building Name',
//     BUILDING_NUMBER: 'Building Number',
//     STREET: 'Street',
//     LOCALITY: 'Locality',
//     TOWN: 'Town/City',
//     POSTCODE: 'Postcode'
//   },
//   VALIDATION: {
//     STREET_REQUIRED: 'Please enter street.',
//     STREET_INVALID: 'Please enter a street address no html tag',
//     POSTCODE_REQUIRED: 'Please enter post code.',
//     POSTCODE_INVALID: 'Please enter a valid postcode',
//     TOWN_REQUIRED: 'Please enter town.',
//     BUILDING_INVALID: 'Please enter a street address no html tag',
//     BUILDING_REQUIRED: 'Please enter a flat number, building name or number.',
//     FLAT_INVALID: 'Please enter a valid flat number'
//   },
//   ERROR_MESSAGE: 'Failed to add address'
// };

/**
 * Content for the address length section.
 * Contains headers, year/month labels, and validation messages
 * for capturing duration at current address.
 */
export const ADDRESS_LENGTH_CONTENT: AddressLengthContent = contentObj['ADDRESS_LENGTH_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'How long have you lived at this address?',
//     PREVIOUS_ADDRESS_TITLE: 'How long did you live at this address?',
//     HISTORY_NOTE: "Lenders ask for 3 years' address history."
//   },
//   LABELS: {
//     YEARS: 'Years',
//     MONTHS: 'Months'
//   },
//   VALIDATION: {
//     BOTH_REQUIRED: "Both 'yearsAtAddress' and 'monthsAtAddress' must be provided together.",
//     LENGTH_REQUIRED: "Please tell us how long you've lived here.",
//     MONTHS_RANGE: "Please enter a number between 0 and 11 months."
//   },
//   ERROR_MESSAGE: 'Failed to update address length'
// };

/**
 * Content for the address status section.
 * Includes title and options for residential status (e.g., tenant, homeowner).
 * Contains validation messages for option selection.
 */
export const ADDRESS_STATUS_CONTENT: AddressStatusContent = contentObj['ADDRESS_STATUS_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'What is your residential status?',
//     PREVIOUS_ADDRESS_TITLE: 'What was your residential status?',
//   },
//   OPTIONS: [
//     {
//       label: 'Private Tenant',
//       value: 31
//     },
//     {
//       label: 'Home Owner',
//       value: 32
//     },
//     {
//       label: 'Council Tenant',
//       value: 33
//     },
//     {
//       label: 'Living with Parents',
//       value: 34
//     }
//   ],
//   VALIDATION: {
//     OPTION_REQUIRED: 'Please select an option'
//   },
//   ERROR_MESSAGE: 'Failed to update address status'
// };

/**
 * Content for the finance amount section.
 * Contains headers (default and personalized), input labels,
 * and validation messages for loan amount entry.
 */
export const AMOUNT_TO_FINANCE_CONTENT: AmountToFinanceContent = contentObj['AMOUNT_TO_FINANCE_CONTENT'];
// {
//   HEADERS: {
//     DEFAULT: 'How much would you like to borrow?',
//     PERSONALIZED: 'Roughly how much would you like to borrow {name}?',
//     CHANGE_LATER: 'Don\'t worry, you can change this later and it won\'t impact whether you\'re approved'
//   },
//   LABELS: {
//     PLACEHOLDER: 'Amount',
//     SMALL_PRINT: 'You can change this later on.',
//     NOT_SURE: 'I\'m not sure',
//   },
//   VALIDATION: {
//     AMOUNT_INVALID: 'Please enter a valid amount'
//   },
//   ERROR_MESSAGE: 'Failed to update loan amount'
// };

/**
 * Content for the initial contact details section.
 * Contains headers, name fields, title selection options,
 * and associated validation messages.
 */
export const CONTACT_DETAILS_CONTENT: ContactDetailsContent = contentObj['CONTACT_DETAILS_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'Almost done, let us know who you are',
//     PROMPT: 'My title isn\'t listed',
//     INFO: 'Currently, lenders don\'t accept all formal titles, so please select the closest match to continue'
//   },
//   LABELS: {
//     TITLE: 'Your Title',
//     FIRST_NAME: 'First Name',
//     LAST_NAME: 'Last Name'
//   },
//   TITLES: [
//     { label: 'Mr', value: 1 },
//     { label: 'Mrs', value: 2 },
//     { label: 'Miss', value: 4 },
//     { label: 'Ms', value: 3 }
//   ],
//   VALIDATION: {
//     TITLE_REQUIRED: 'Please select a title',
//     FIRST_NAME_REQUIRED: 'Please enter your first name',
//     FIRST_NAME_INVALID: 'Please enter a valid first name',
//     LAST_NAME_REQUIRED: 'Please enter your last name',
//     LAST_NAME_INVALID: 'Please enter a valid last name'
//   },
//   ERROR_MESSAGE: 'Failed to update contact details',
//   FOOTER: 'The personal information we have collected from you will be shared with fraud prevention agencies who will use it to prevent fraud and money laundering and to verify your identity. If fraud is detected, you could be refused finance. Further details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, can be found under our privacy-policy.'
// };

/**
 * Content for the secondary contact details section.
 * Includes email/phone fields, terms acceptance, privacy notice,
 * and related validation messages.
 */
export const CONTACT_DETAILS_PART2_CONTENT: ContactDetailsPart2Content = contentObj['CONTACT_DETAILS_PART2_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'And finally, where should we send your quote?',
//     PERSONALIZED: 'Lastly, we just need your contact details {name}.',
//     TERMS: 'Terms and conditions'
//   },
//   LABELS: {
//     EMAIL: 'Email address',
//     PHONE: 'Phone number'
//   },
//   VALIDATION: {
//     EMAIL_REQUIRED: 'Please enter an email address.',
//     EMAIL_INVALID: 'Please enter a valid email address',
//     PHONE_REQUIRED: 'Please enter a phone number.',
//     PHONE_INVALID: 'Please enter a valid phone number',
//     TERMS_REQUIRED: 'Please agree to the T&Cs to get your quote.',
//     TERMS_ACCEPT: 'Please agree to the terms and conditions'
//   },
//   SUBMITTING: "Please wait while we process your application...",
//   TERMS: {
//     CHECKBOX_TEXT: "I have read and agree to ",
//     PRIVACY_TEXT: "The personal information we have collected from you will be shared with fraud prevention agencies who will use it to prevent fraud and money laundering and to verify your identity. If fraud is detected, you could be refused finance. Further details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, can be found under our "
//   },
//   ERROR_MESSAGE: 'Failed to complete application'
// };

/**
 * Content for the date of birth section.
 * Contains header and comprehensive validation messages
 * for age verification and date format validation.
 */
export const DATE_OF_BIRTH_CONTENT: DateOfBirthContent = contentObj['DATE_OF_BIRTH_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'What is your date of birth?'
//   },
//   VALIDATION: {
//     REQUIRED: 'Date of birth is required',
//     INVALID_DATE: 'Please enter a valid date',
//     FUTURE_DATE: "Please try again. We can't arrange finance for people from the future.",
//     UNDER_18: "We can only arrange finance for over 18s. Come back in a few years - we'll be happy to help!",
//     OVER_100: 'You must be no older than 100 years old',
//     MISSING_DAY: 'What day were you born?',
//     MISSING_MONTH: 'What month were you born?',
//     MISSING_YEAR: 'What year were you born?'
//   },
//   ERROR_MESSAGE: 'Failed to update date of birth'
// };

/**
 * Content for the driving licence section.
 * Includes headers and options for different licence types,
 * with validation messages for selection.
 */
export const DRIVING_LICENCE_CONTENT: DrivingLicenceContent = contentObj['DRIVING_LICENCE_CONTENT'];
// {
//   HEADERS: {
//     MAIN: 'What type of driving licence do you have?',
//     SECONDARY: 'What licence do you have?'
//   },
//   OPTIONS: [
//     { label: 'Full UK', value: 35 },
//     { label: 'Provisional UK', value: 36 },
//     { label: 'EU', value: 37 },
//     { label: 'International', value: 39 },
//     { label: 'I don\'t have a licence', value: 38 },
//   ],
//   VALIDATION: {
//     OPTION_REQUIRED: 'Please select an option'
//   },
//   ERROR_MESSAGE: 'Failed to update driving licence selection'
// };

/**
 * Content for the employment income section.
 * Contains headers (default and personalized), input labels,
 * and validation messages for income entry.
 */
export const EMPLOYMENT_INCOME_CONTENT: EmploymentIncomeContent = contentObj['EMPLOYMENT_INCOME_CONTENT'];
// {
//   HEADERS: {
//     DEFAULT: 'How much do you earn each month after tax?',
//     PERSONALIZED: 'Employment Income {name}',
//     INFO: 'Lenders ask for your monthly income to check affordability'
//   },
//   LABELS: {
//     PLACEHOLDER: 'Amount',
//     SMALL_PRINT: 'This can be an estimate if your monthly income varies'
//   },
//   VALIDATION: {
//     AMOUNT_INVALID: 'Please enter a valid amount'
//   },
//   ERROR_MESSAGE: 'Failed to update employment income'
// };

/**
 * Content for the employment length section.
 * Includes headers for current/previous job duration,
 * year/month labels, and validation messages.
 */
export const EMPLOYMENT_LENGTH_CONTENT: EmploymentLengthContent = contentObj['EMPLOYMENT_LENGTH_CONTENT'];
// {
//   HEADERS: {
//     CURRENT_JOB: 'How long have you worked here?',
//     PREVIOUS_JOB: 'How long were you in this job?',
//     DISABILITY_JOB: 'How long have you been on disability living allowance?',
//     RETIRED_JOB: 'How long have you been retired?',
//     STUDENT_JOB: 'How long have you been a student?',
//     FAMILY_CARER_JOB: 'How long have you been a non professional carer?',
//     HOMEMAKER_JOB: 'How long have you been a homemaker?',
//     UNEMPLOYED_JOB: 'How long have you been unemployed?'
//   },
//   LABELS: {
//     YEARS: 'Years',
//     MONTHS: 'Months'
//   },
//   VALIDATION: {
//     FIELDS_REQUIRED: "Please tell us how long you've been here.",
//     LENGTH_REQUIRED: "Please tell us how long you've been here.",
//     MONTHS_RANGE: 'Please enter a number between 0 and 11 months.'
//   },
//   ERROR_MESSAGE: 'Failed to update employment length'
// };

/**
 * Content for the employment location section.
 * Contains headers, form labels for job details,
 * employer disclaimer, and validation messages.
 */
export const EMPLOYMENT_LOCATION_CONTENT: EmploymentLocationContent = contentObj['EMPLOYMENT_LOCATION_CONTENT'];
// {
//   HEADERS: {
//     CURRENT_JOB: "Tell us about your current employment",
//     CURRENT_JOB_NO_NAME: "What's your current job title?",
//     PREVIOUS_JOB: 'And where did you work previously?',
//     PROMPT: 'Why do we ask this?',
//     INFO: 'Lenders ask for 1 year employment history.',
//     NO_CONTACT: "We won't contact your employer."
//   },
//   LABELS: {
//     JOB_TITLE: 'Job Title',
//     EMPLOYER: 'Employer Name',
//     TOWN: 'City/Town of Workplace',
//     WORKPLACE: 'Where is your workplace based?',
//     SMALL_PRINT: 'e.g. Manchester'
//   },
//   DISCLAIMER: "(We won't contact your employer)",
//   VALIDATION: {
//     JOB_TITLE_REQUIRED: 'Please tell us what you do for a job.',
//     JOB_TITLE_INVALID: 'Please enter a valid job title.',
//     EMPLOYER_REQUIRED: 'Please tell us who you work for.',
//     EMPLOYER_INVALID: 'Please enter a valid employer name.',
//     TOWN_REQUIRED: 'Please tell us where you work.',
//     TOWN_INVALID: 'Please enter a valid employer town.'
//   },
//   ERROR_MESSAGE: 'Failed to update employment details'
// };

/**
 * Content for the employment status section.
 * Includes headers for current/previous status,
 * employment type options, and validation messages.
 */
export const EMPLOYMENT_STATUS_CONTENT: EmploymentStatusContent = contentObj['EMPLOYMENT_STATUS_CONTENT'];
// {
//   HEADERS: {
//     CURRENT: 'What is your employment status?',
//     PREVIOUS: 'What was your previous employment status?'
//   },
//   OPTIONS: [
//     { label: 'Full-Time', value: 9 },
//     { label: 'Self-Employed', value: 10 },
//     { label: 'Part-Time', value: 11 },
//     { label: 'Disability', value: 12 },
//     { label: 'Retired', value: 13 },
//     { label: 'Student', value: 19 },
//     { label: 'Family Carer', value: 21 },
//     { label: 'Agency Worker', value: 15 },
//     { label: 'Sub-Contractor', value: 14 },
//     { label: 'Homemaker', value: 16 },
//     { label: 'Armed Forces', value: 18 },
//     { label: 'Not Employed', value: 17 }
//   ],
//   VALIDATION: {
//     OPTION_REQUIRED: 'Please select an option'
//   },
//   ERROR_MESSAGE: 'Failed to update employment status'
// };

/**
 * Content for the finance details section.
 * Contains header and vehicle type options (car, van, bike)
 * with validation messages for selection.
 */
export const FINANCE_DETAILS_CONTENT: FinanceDetailsContent = contentObj['FINANCE_DETAILS_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'What would you like a finance quote for?'
//   },
//   OPTIONS: [
//     { label: 'Car', value: 40 },
//     { label: 'Van', value: 41 },
//     { label: 'Bike', value: 42 }
//   ],
//   VALIDATION: {
//     OPTION_REQUIRED: 'Please select a vehicle type'
//   },
//   ERROR_MESSAGE: 'Failed to update vehicle selection'
// };

/**
 * Content for the quote initiation section.
 * Contains header and error message for quote generation flow.
 */
export const GET_MY_QUOTE_CONTENT: GetMyQuoteContent = contentObj['GET_MY_QUOTE_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'Get your finance quote',
//     NO_VEHICLE: 'Get a quote on this vehicle with Car Finance 247'
//   },
//   ERROR_MESSAGE: 'Failed to navigate to next page'
// };

/**
 * Content for the marital status section.
 * Includes header, status options, and validation messages
 * for status selection.
 */
export const MARITAL_STATUS_CONTENT: MaritalStatusContent = contentObj['MARITAL_STATUS_CONTENT'];
// {
//   HEADERS: {
//     TITLE: 'What is your marital status?'
//   },
//   OPTIONS: [
//     { label: 'Married', value: 23 },
//     { label: 'Single', value: 24 },
//     { label: 'Cohabiting', value: 25 },
//     { label: 'Divorced', value: 26 },
//     { label: 'Separated', value: 28 },
//     { label: 'Widowed', value: 29 },
//     { label: 'Civil partnership', value: 30 }
//   ],
//   VALIDATION: {
//     OPTION_REQUIRED: 'Please select an option'
//   },
//   ERROR_MESSAGE: 'Failed to update marital status'
// };

/**
 * Content for the application completion section.
 * Contains default and personalized thank you messages
 * displayed after successful submission.
 */
export const THANK_YOU_CONTENT: ThankYouContent = contentObj['THANK_YOU_CONTENT'];
// {
//   HEADERS: {
//     DEFAULT: 'Thank you for applying. Check your email now for what happens next.',
//     PERSONALIZED: 'Lastly, we just need your contact details {name}.'
//   }
// };

export const FINANCE_DISCLAIMER = contentObj['FINANCE_DISCLAIMER'];
// {
//     APR_TEXT: 'Rates from 9.9% APR. Representative APR 19.9%. Car Finance 247 is a credit broker, not a lender.',
//     FINANCE_247_STYLED: '<span class="text-body3">Car Finance 247</span>',
//     COMMISSION_TEXT: 'will pay us a commission for introducing a customer who takes out a motor finance agreement. The commission paid is a <span class="text-body3">fixed amount</span> per agreement that is directly linked to the amount you borrow.'
// }; 

/*
* Content for when the form is prepopulated
* with validation messages for selection.
*/
export const PRE_POP_CONTENT: PrePopContent = contentObj['PRE_POP_CONTENT'];

export const REFINANCE_FORM_CONTENT: RefinanceFormContent = contentObj['REFINANCE_FORM_CONTENT'];