import { LocationHelper, RequestHelper } from '@/helpers/index';
import { Page } from '@/utils/elementIds';
import { useProgressStore } from '@/stores/modules/progressStore'
import { useConfigStore } from '@/stores/modules/configStore'
import * as Sentry from '@sentry/vue';

/**
 * Mapping of page identifiers to their corresponding Vue component imports.
 * Uses lazy loading to improve initial load performance.
 */
const PAGE_COMPONENTS = {
    [Page.GET_MY_QUOTE]: () => import('@/views/GetMyQuote.vue'),
    [Page.FINANCE_DETAILS]: () => import('@/views/FinanceDetails.vue'),
    [Page.DRIVING_LICENCE]: () => import('@/views/DrivingLicence.vue'),
    [Page.CONTACT_DETAILS_1]: () => import('@/views/ContactDetailsPart1.vue'),
    [Page.PRE_POP_FORM]: () => import('@/views/PrePopForm.vue'),
    [Page.SUBMISSION_TYPE]: () => import('@/views/SubmissionType.vue'),
    [Page.DEALER_GET_MY_QUOTE]: () => import('@/views/DealerGetMyQuote.vue'),
    [Page.DEALER_SEARCH]: () => import('@/views/DealerSearch.vue'),
    [Page.REFINANCE_FORM]: () => import('@/views/RefinanceForm.vue'),
} as const;

type StartPage = keyof typeof PAGE_COMPONENTS;

interface QueryParams {
    vehicleType: string | null;
    vehicleId: string | null;
    vrm: string | null;
    advertId: string | null;
    personalised: string | null;
    isCarCraft: boolean;
    isCKDirect: boolean;
    isCompareTheMarket: boolean;
    isSocial: boolean;
    appId: string | null;
    aid: string | null;
    daid: string | null;
    noPx: string | null;
    did: string | null;
    submissionTypeId: string | null;
    toDealerGetMyQuote: string | null;
}

/**
 * Retrieves and normalizes query parameters from the URL.
 */
const getQueryParams = (): QueryParams => {
    try {
        const host = LocationHelper.getHost();
        return {
            vehicleType: RequestHelper.getQueryVariable(['vehicle_type', 'vehicleType']),
            vehicleId: RequestHelper.getQueryVariable(['vehicleid', 'vehicleId']),
            vrm: RequestHelper.getQueryVariable('vrm'),
            advertId: RequestHelper.getQueryVariable(['advertid', 'advertId']),
            personalised: RequestHelper.getQueryVariable('personalised'),
            isCarCraft: host.startsWith('carcraft'),
            isCKDirect: host.startsWith('ckdirect'),
            isCompareTheMarket: host.startsWith('comparethemarket') || 
                            host.startsWith('comparethemarketcarfinance'),
            isSocial: ['social', 'ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit']
                    .includes(host),
            appId: RequestHelper.getQueryVariable(['appid', 'appId']),
            aid: RequestHelper.getQueryVariable(['aid', 'aId']),
            daid: RequestHelper.getQueryVariable(['daid', 'daId']),
            noPx: RequestHelper.getQueryVariable(['noPx', 'noPX', 'nopx']),
            did: RequestHelper.getQueryVariable(['did', 'dId']),
            submissionTypeId: RequestHelper.getQueryVariable(['submissionTypeId', 'submissionTypeid']),
            toDealerGetMyQuote: RequestHelper.getQueryVariable('to-dealer-get-my-quote')
        };
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                location: 'startpage-helper.getQueryParams'
            }
        });
        // Return default values if there's an error
        return {
            vehicleType: null,
            vehicleId: null,
            vrm: null,
            advertId: null,
            personalised: null,
            isCarCraft: false,
            isCKDirect: false,
            isCompareTheMarket: false,
            isSocial: false,
            appId: null,
            aid: null,
            daid: null,
            noPx: null,
            did: null,
            submissionTypeId: null,
            toDealerGetMyQuote: null
        };
    }
};

/**
 * Page resolution rules in order of priority
 */
const pageResolutionRules: Array<(params: QueryParams, stores: { progressStore: any, configStore: any }) => StartPage | null> = [
    // Refinance form rule
    ({ submissionTypeId }, { progressStore }) => {
        if (submissionTypeId === '1') {
            progressStore.setFormType('refinance');
            return Page.REFINANCE_FORM;
        }
        return null;
    },
    
    // Pre-populated form rule
    ({ appId }, { configStore, progressStore }) => {
        if (appId && configStore.affiliateConfig?.prePopulateForm) {
            progressStore.setFormType('prePopulated');
            return Page.PRE_POP_FORM;
        }
        return null;
    },

    // Dealer rules (highest priority for vehicle-related flows)
    (params, { progressStore }) => {
        const { daid, vrm, did, toDealerGetMyQuote } = params;
        // First check if we have a valid dealer ID
        if ( (daid && typeof daid === 'string' && daid.trim() !== '') || toDealerGetMyQuote) {
            // If we have both daid and vrm, return dealer quote
            if (vrm && typeof vrm === 'string' && vrm.trim() !== '') {
                progressStore.setFormType('dealerGetMyQuote');
                return Page.DEALER_GET_MY_QUOTE;
            }
            // If we only have daid, return finance details
            progressStore.setFormType('direct');
            return Page.FINANCE_DETAILS;
        }
        // Then check dealer search
        if (did && typeof did === 'string' && did.trim() !== '') {
            progressStore.setFormType('dealerSearch');
            return Page.DEALER_SEARCH;
        }
        return null;
    },
    
    // Submission type rule
    (params, { progressStore }) => {
        const { isCompareTheMarket, isSocial, isCarCraft } = params;
        if (isCompareTheMarket || isSocial || isCarCraft || (LocationHelper.getHost() !== 'default' && !hasVehicleIdentifier(params))) {
            progressStore.setFormType('submissionType');
            return Page.SUBMISSION_TYPE;
        }
        return null;
    },

    // Contact details rule
    (params, _) => {
        if (params.personalised && !hasVehicleIdentifier(params)) {
            return Page.CONTACT_DETAILS_1;
        }
        return null;
    },

    // Partner with vehicle ID rule
    (params, { progressStore }) => {
        const { vehicleId, vrm, advertId, isCarCraft, daid } = params;
        // Don't run this rule if we have a dealer ID
        if (daid && typeof daid === 'string' && daid.trim() !== '') {
            return null;
        }
        if ((vehicleId || vrm || advertId) && !isCarCraft) {
            progressStore.setFormType('partnerWithVehicleId');
            return Page.GET_MY_QUOTE;
        }
        return null;
    },

    // Vehicle type rule
    (params, { progressStore }) => {
        if (params.vehicleType) {
            progressStore.setFormType('partnerWithVehicleType');
            return Page.DRIVING_LICENCE;
        }
        return null;
    }
];

/**
 * Determines the starting page based on URL parameters and host information.
 */
function determineStartPage(params: QueryParams): StartPage {
    try {
        const progressStore = useProgressStore();
        const configStore = useConfigStore();
        const stores = { progressStore, configStore };

        // Try each rule in order until one matches
        for (const rule of pageResolutionRules) {
            try {
                const result = rule(params, stores);
                if (result) {
                    return result;
                }
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        location: 'startpage-helper.pageResolutionRule'
                    },
                    extra: {
                        params,
                        rule: rule.name || 'anonymous'
                    }
                });
                continue;
            }
        }

        // Default fallback
        try {
            progressStore.setFormType('direct');
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    location: 'startpage-helper.setFormType'
                }
            });
        }
        return Page.FINANCE_DETAILS;
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                location: 'startpage-helper.determineStartPage'
            },
            extra: {
                params
            }
        });
        return Page.FINANCE_DETAILS;
    }
}

/**
 * Checks if any vehicle identifier is present in the parameters.
 */
function hasVehicleIdentifier(params: QueryParams): boolean {
    return Boolean(params.vehicleId || params.vrm || params.advertId);
}

const PAGE_ROUTE_NAMES = {
    [Page.GET_MY_QUOTE]: 'get-my-quote',
    [Page.DEALER_GET_MY_QUOTE]: 'dealer-get-my-quote',
    [Page.FINANCE_DETAILS]: 'finance-details',
    [Page.DRIVING_LICENCE]: 'driving-licence',
    [Page.CONTACT_DETAILS_1]: 'contact-details-p1',
    [Page.PRE_POP_FORM]: 'pre-pop-form',
    [Page.SUBMISSION_TYPE]: 'submission-type',
    [Page.DEALER_SEARCH]: 'dealer-search',
    [Page.REFINANCE_FORM]: 'refinance-form'
} as const;

/**
 * Helper module for determining and loading the appropriate start page component
 * based on URL parameters and host information.
 */
export default {
    /**
     * Gets the appropriate Vue component for the start page.
     */
    getStartPageComponent() {
        const params = getQueryParams();
        const startPage = determineStartPage(params);
        return PAGE_COMPONENTS[startPage]();
    },

    /**
     * Gets the name of the start page route.
     */
    getStartPageName() {
        const params = getQueryParams();
        const startPage = determineStartPage(params);
        return PAGE_ROUTE_NAMES[startPage];
    }
};