<!-- 
  @file ErrorAlert.vue - Error Alert Component
  @description
  A reusable component for displaying error messages to users with an optional retry button.
  Designed to show detailed error messages in development/testing environments while
  displaying user-friendly messages in production.

  Features:
  - Displays detailed error messages on non-production environments
  - Shows user-friendly error message with support link on production
  - Optional retry button that can be shown/hidden
  - Consistent error styling with red theme
  - Emits retry events for error recovery

  Production domains (show friendly message):
  - https://new-apply.carfinance247.co.uk
  - https://apply.carfinance247.co.uk

  Usage example:
  <ErrorAlert 
    message="Failed to save data"
    :showRetry="true"
    @retry="handleRetry"
  />
-->
<template>
  <div class="error-alert">
    <p v-if="shouldShowMessage">{{ message }}</p>
    <p v-else>Sorry, something went wrong. Please try again.</p>
    <button v-if="showRetry" @click="handleRetry">
      Try Again
    </button>
  </div>
</template>

<script lang="ts">
/**
 * @component ErrorAlert
 */
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ErrorAlert',
  
  props: {
    /**
     * The error message to display
     * @required
     */
    message: {
      type: String,
      required: true
    },
    /**
     * Whether to show the retry button
     * @default true
     */
    showRetry: {
      type: Boolean,
      default: true
    },
    // Add new prop for VPN IPs
    vpnIps: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },

  computed: {
    shouldShowMessage(): boolean {
      const productionDomain = 'carfinance247.co.uk';
      return !window.location.hostname.endsWith(productionDomain);
    }
  },

  methods: {
    handleRetry() {
      window.location.reload();
    }
  },
});
</script>

<style scoped>
/**
 * Main container styles
 * Uses a light red background with matching border for error state
 */
.error-alert {
  padding: 1rem;
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  border-radius: 4px;
  margin: 1rem;
}

/**
 * Retry button styles
 * Uses a white background with red border to match error theme
 */
button {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #ef4444;
  border-radius: 4px;
  cursor: pointer;
}

</style> 