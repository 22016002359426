function getDomainName(): string {
    try {
        const match = window.location.hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/);
        return match?.[1] ?? '';
    } catch (e) {
        console.warn('Error getting domain name:', e);
        return '';
    }
}

export type CookieHelperType = {
    get(name: string): string;
    set(name: string, value: string, days?: number): void;
    getCookieValue(cookieString: string, name: string): string;
};

export default {
    /**
     * Gets a cookie value by name
     */
    get(name: string): string {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        return parts.length === 2 
            ? decodeURIComponent(parts.pop()?.split(';').shift() ?? '')
            : '';
    },

    /**
     * Sets a cookie with the given name and value
     */
    set(name: string, value: string, days?: number): void {
        const expires = days
            ? `; expires=${new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()}`
            : '';

        const domain = getDomainName();
        const domainPart = domain ? `; domain=.${domain}` : '';
        
        document.cookie = `${name}=${encodeURIComponent(value)}${expires}${domainPart}; path=/; SameSite=Lax; Secure`;
    },

    /**
     * Gets a cookie value from a specific cookie string
     */
    getCookieValue(cookieString: string, name: string): string {
        const value = `; ${cookieString}`;
        const parts = value.split(`; ${name}=`);
        return parts.length === 2 
            ? decodeURIComponent(parts.pop()?.split(';').shift() ?? '')
            : '';
    }
} as const;