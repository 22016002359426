import { createRouter, createWebHistory, type RouteLocationNormalizedGeneric } from 'vue-router'
import StartPageHelper from '@/helpers/startpage-helper'
import { useApplicationStore } from '@/stores/modules/applicationStore'

/**
 * Vue Router configuration for the application.
 * Defines all available routes and their corresponding components.
 * Uses lazy loading for all components to improve initial load performance.
 */
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    /**
     * Main application routes
     */
    {
      path: '/',
      name: 'home',
      component: () => StartPageHelper.getStartPageComponent() // Landing page determining the starting page
    },
    {
      path: '/finance-details',
      name: 'finance-details',
      component: () => import('@/views/FinanceDetails.vue') // Detailed financial information page
    },
    {
      path: '/driving-licence',
      name: 'driving-licence',
      component: () => import('@/views/DrivingLicence.vue') // Driving license information page
    },
    {
      path: '/marital-status', 
      name: 'marital-status',
      component: () => import('@/views/MaritalStatus.vue') // Marital status selection page
    },
    {
      path: '/date-of-birth',
      name: 'date-of-birth',
      component: () => import('@/views/DateOfBirth.vue') // Date of birth input page
    },

    /**
     * Address-related routes
     * :number parameter represents the address entry index
     */
    {
      path: '/address-add/:number',
      name: 'address-add',
      component: () => import('@/views/AddressAdd.vue') // Add new address page
    },
    {
      path: '/address-status/:number',
      name: 'address-status',
      component: () => import('@/views/AddressStatus.vue') // Address status page
    },
    {
      path: '/address-length/:number',
      name: 'address-length',
      component: () => import('@/views/AddressLength.vue') // Address duration page
    },

    /**
     * Employment-related routes
     * :number parameter represents the employment entry index
     */
    {
      path: '/employment/:number',
      name: 'employment',
      component: () => import('@/views/EmploymentStatus.vue') // Employment status page
    },
    {
      path: '/employment-location/:number',
      name: 'employment-location',
      component: () => import('@/views/EmploymentLocation.vue') // Employment location page
    },
    {
      path: '/employment-length/:number',
      name: 'employment-length',
      component: () => import('@/views/EmploymentLength.vue') // Employment duration page
    },
    {
      path: '/employment-income/:number',
      name: 'employment-income',
      component: () => import('@/views/EmploymentIncome.vue') // Employment income page
    },
    {
      path: '/amount-to-finance',
      name: 'amount-to-finance',
      component: () => import('@/views/AmountToFinance.vue') // Amount to finance page
    },
    {
      path: '/contact-details-p1',
      name: 'contact-details-p1',
      component: () => import('@/views/ContactDetailsPart1.vue') // Contact details page part 1
    },
    {
      path: '/contact-details-p2',
      name: 'contact-details-p2',
      component: () => import('@/views/ContactDetailsPart2.vue') // Contact details page part 2
    },
    {
      path: '/thank-you',
      name: 'thank-you',
      component: () => import('@/views/ThankYou.vue') // Thank you page
    },
    {
      path: '/get-my-quote',
      name: 'get-my-quote',
      component: () => import('@/views/GetMyQuote.vue') // Thank you page
    },
    /**
     * isDealer
     */
    {
      path: '/car-finance-detail',
      name: 'car-finance-detail',
      component: () => import('@/views/CarFinanceDetail.vue') 
    },
    {
      path: '/bank-detail',
      name: 'bank-detail',
      component: () => import('@/views/BankDetail.vue') 
    },
    {
      path: '/bank-length',
      name: 'bank-length',
      component: () => import('@/views//BankLength.vue') 
    },
    {
      path: '/dealer-search',
      name: 'dealer-search',
      component: () => import('@/views/DealerSearch.vue') 
    },
    /**
     * Pre-Pop routes
     */
    {
      path: '/pre-pop-form',
      name: 'pre-pop-form',
      component: () => import('@/views/PrePopForm.vue') // Pre-populated form page
    },
    {
      path: '/loan-details',
      name: 'loan-details',
      component: () => import('@/views/PrePopLoanDetails.vue') // Pre-populated loan details page
    },
    {
      path: '/personal-details',
      name: 'personal-details',
      component: () => import('@/views/PrePopPersonalDetails.vue') // Pre-populated personal details page
    },
    {
      path: '/address-details/:number',
      name: 'address-details',
      component: () => import('@/views/PrePopAddressDetails.vue') // Pre-populated address details page
    },
    {
      path: '/employment-details/:number',
      name: 'employment-details',
      component: () => import('@/views/PrePopEmploymentDetails.vue') // Pre-populated employment details page
    },
    {
      path: '/refinance-form',
      name: 'refinance-form',
      component: () => import('@/views/RefinanceForm.vue') // Refinance form page
    },
    {
      path: '/submission-type',
      name: 'submission-type',
      component: () => import('@/views/SubmissionType.vue') // Submission type selection page
    },
    {
      path: '/dealer-get-my-quote',
      name: 'dealer-get-my-quote',
      component: () => import('@/views/DealerGetMyQuote.vue') // Dealer get my quote page
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

// Global navigation guard
router.beforeEach((to, from, next) => {
  const applicationStore = useApplicationStore();
  
  // Capture referrer on first page load
  if (from.name === undefined) {
    applicationStore.captureReferrer();
  }

  // Existing query parameter logic
  function hasQueryParams(route: RouteLocationNormalizedGeneric) {
    return !!Object.keys(route.query).length
  }
  if(!hasQueryParams(to) && hasQueryParams(from) ){
    const targetPage = Object.assign({}, to);
    targetPage.query = from.query;
    next(targetPage);
  } else {
    next()
  }
});

export default router
