export const APP_PREFIX = 'CarFinance_' as const;

/**
 * Enum for page identifiers used in element ID creation
 * @enum {string}
 */
export enum Page {
    FINANCE_DETAILS = 'finance',
    PERSONAL_DETAILS = 'personal',
    DRIVING_LICENCE = 'driving',
    MARITAL_STATUS = 'marital-status',
    DATE_OF_BIRTH = 'date-of-birth',
    ADDRESS_ADD = 'address-add',
    ADDRESS_STATUS = 'address-status',
    ADDRESS_LENGTH = 'address-length',
    EMPLOYMENT_STATUS = 'employment-status',
    EMPLOYMENT_LOCATION = 'employment-location',
    EMPLOYMENT_LENGTH = 'employment-length',
    EMPLOYMENT_INCOME = 'employment-income',
    AMOUNT_TO_FINANCE = 'amount-to-finance',
    CONTACT_DETAILS_1 = 'contact-details-1',
    CONTACT_DETAILS_2 = 'contact-details-2',
    GET_MY_QUOTE = 'get-my-quote',
    PRE_POP_FORM = 'pre-pop-form',
    CAR_FINANCE_DETAIL = 'car-finance-detail',
    BANK_DETAIL = 'bank-detail',
    BANK_LENGTH = 'bank-length',
    SUBMISSION_TYPE = 'submission-type',
    DEALER_GET_MY_QUOTE = 'dealer-get-my-quote',
    DEALER_SEARCH = 'dealer-search',
    REFINANCE_FORM = 'refinance-form',
    // Add other pages as needed
}

/**
 * Enum for element type identifiers used in element ID creation
 * @enum {string}
 */
export enum ElementType {
    
    // Vehicle types
    CAR = 'car',
    VAN = 'van',
    BIKE = 'bike',
    
    // Navigation
    CONTINUE = 'continue',
    
    // License types
    FULL_LICENCE = 'full',
    NO_LICENCE = 'no',
    PROVISIONAL_LICENCE = 'provisional',
    EU_LICENCE = 'eu',
    INTERNATIONAL_LICENCE = 'international',
    NONE_LICENCE = 'none',
    
    // Marital status
    SINGLE = 'single',
    MARRIED = 'married',
    COHABITING = 'cohabiting',
    SEPARATED = 'separated',
    DIVORCED = 'divorced',
    WIDOWED = 'widowed',
    CIVIL_PARTNERSHIP = 'civil',

    // Address Add
    ADDRESS_LOOKUP = 'address-lookup',
    FLAT_NUMBER = 'flat-number',
    BUILDING_NAME = 'building-name',
    BUILDING_NUMBER = 'building-number',
    STREET = 'street',
    TOWN = 'town',
    POSTCODE = 'postcode',

    // Address Length
    YEARS_AT_ADDRESS = 'years-at-address',
    MONTHS_AT_ADDRESS = 'months-at-address',
    
    // Residence status
    PRIVATE_TENANT = 'private',
    HOME_OWNER = 'homeowner',
    COUNCIL_TENANT = 'council',
    LIVING_WITH_PARENTS = 'parents',
    
    // Employment status
    FULLTIME = 'fulltime',
    SELF_EMPLOYED = 'selfemployed',
    PARTTIME = 'parttime',
    DISABILITY = 'disability',
    RETIRED = 'retired',
    STUDENT = 'student',
    FAMILY_CARER = 'family-carer',
    AGENCY_WORKER = 'agency-worker',
    SUBCONTRACTOR = 'subcontractor',
    HOMEMAKER = 'homemaker',
    ARMED_FORCES = 'armed-forces',
    NOT_EMPLOYED = 'not-employed',
    MONTHLY_INCOME = 'monthly-income',
    
    // Employment details
    JOB_TITLE = 'job-title',
    EMPLOYER_NAME = 'employer-name',
    EMPLOYER_TOWN = 'employer-town',
    YEARS_AT_EMPLOYER = 'years-at-employer',
    MONTHS_AT_EMPLOYER = 'months-at-employer',
    
    // Finance details
    LOAN_AMOUNT = 'loan-amount',
    NOT_SURE = 'not-sure',
    
    // Personal details
    DATE_OF_BIRTH = 'date-of-birth',
    FIRST_NAME = 'first-name',
    LAST_NAME = 'last-name',
    MR = 'mr',
    MRS = 'mrs',
    MISS = 'miss',
    MS = 'ms',
    
    // Contact details
    EMAIL = 'email',
    PHONE_NUMBER = 'phone-number',
    TERMS = 'terms',

    // car finance detail
    TERM_24 = '24',
    TERM_36 = '36',
    TERM_48 = '48',
    TERM_60 = '60',
    DEPOSIT = 'deposit',
    PARTEXCHANGEVALUE = 'part-exchange-value',
    PARTEXCHANGESETTLEMENT = 'part-exchange-settlement',
    ESTIMATEDANNUALMILEAGE = 'estimated-annual-mileage',

    // Submission types
    FINANCE_MY_NEXT_CAR = 'finance-next-car',
    REFINANCE_MY_CURRENT_CAR = 'refinance-current-car',

    // Refinance form
    MILEAGE = 'mileage',
    SETTLEMENT_FIGURE = 'settlement-figure',
}

/**
 * Creates a unique element ID by combining the page and element type
 * @param {Page} page - The page identifier
 * @param {ElementType} element - The element type identifier
 * @returns {string} The combined element ID with prefix
 */
export const createElementId = (page: Page, element: ElementType): string => {
    return `${APP_PREFIX}${page}_${element}`;
}; 