/**
 * Pinia plugin for tracking user interactions and application events in Azure Application Insights
 * 
 * This plugin automatically tracks key form interactions including:
 * - Form lifecycle events (start, completion, partial saves)
 * - Navigation between form pages
 * - Error events (validation, network, submission)
 * 
 * Each event is tracked with relevant metadata including:
 * - Event category and action
 * - Form GUID for session tracking
 * - Page URLs for navigation events
 * - Detailed error information (type, source, message)
 * 
 * @module storeAnalytics
 */

import type { PiniaPluginContext } from 'pinia';
import type { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useConfigStore } from '@/stores/modules/configStore';
import { useProgressStore } from '@/stores/modules/progressStore';
import { StoreError, StoreErrorType } from '@/utils/storeErrorHandling';
import { useApplicationStore } from '@/stores/modules/applicationStore';
import { useFormStore } from '@/stores/modules/formStore';
import { RequestHelper } from '@/helpers';
import { useTealium } from '@/composables/useTealium';

/**
 * Application Insights instance used for event tracking
 * Initialized via setAppInsights before plugin usage
 */
let appInsights: ApplicationInsights | null = null;

/**
 * Configures the Application Insights instance for analytics tracking
 * Must be called before using the analytics plugin
 * 
 * @param instance - Configured Application Insights instance
 */
export function setAppInsights(instance: ApplicationInsights) {
  appInsights = instance;
}

let isStart = false;

/**
 * Pinia plugin that adds automatic analytics tracking to stores
 * 
 * @param context - The Pinia plugin context
 * @param context.store - The store being enhanced with analytics
 * 
 * Tracked Events:
 * 1. Form Start
 *    - Triggered on first page visit
 *    - Includes form GUID
 * 
 * 2. Page Navigation
 *    - Tracks movement between form pages
 *    - Includes source and destination URLs
 * 
 * 3. Form Errors
 *    - Validation errors (frontend)
 *    - Network errors (API calls)
 *    - Submission errors
 *    - Includes error details and source
 * 
 * 4. Form Completion
 *    - Successful form submission
 *    - Includes form GUID
 * 
 * 5. Partial Saves
 *    - Intermediate form saves
 *    - Includes form GUID
 */
export function analyticsPlugin({ store }: PiniaPluginContext) {
  const configStore = useConfigStore();
  const progressStore = useProgressStore();
  const tealium = useTealium();

  // Get the singleton instance
  const aiInstance = appInsights;

  // Return early if appInsights is still not available
  if (!aiInstance) {
    console.warn('⚠️ Application Insights not initialised');
    return;
  }

  store.$onAction(({
    name,
    after,
    onError,
    args
  }) => {
    // Track form start and page navigation when progress store updates
    if (store.$id === 'progress' && name === 'setPageName') {
      // Track form start before state changes
      if (!isStart) {
        isStart = true;
        const applicationStore = useApplicationStore();
        const formStore = useFormStore();
        const option = RequestHelper.getQueryVariable('option');

        console.log('📊 Tracking form start event:', {
          GUID: configStore.GUID,
          referrer: applicationStore.referrer,
          option: option,
          submissionTypeId: formStore.submissionTypeId
        });
        
        aiInstance?.trackEvent({
          name: 'Application Form - Started',
          properties: {
            event_category: 'Application Form',
            event_action: 'Started',
            event_label: configStore.GUID,
            referrer_url: applicationStore.referrer.url,
            referrer_source: applicationStore.referrer.source,
            referrer_timestamp: applicationStore.referrer.timestamp,
            option_type: option || 'none',
            submission_type_id: formStore.submissionTypeId
          }
        });

        if ( tealium.isTealiumEnabled() ) {
          tealium.trackEvent({
            event: 'form_started',
            form: formStore.submissionTypeId,
            option: option || 'none',
            GUID: configStore.GUID
          });
        }
      }

      // Track page navigation after state changes
      after(() => {
        // Only track navigation when there's a previous page AND it's different from the current page
        if (!progressStore.previousPage || progressStore.previousPage === progressStore.pageName) return;

        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const searchParams = window.location.search;
        
        const fromPage = `${baseUrl}/${progressStore.previousPage}${searchParams}`;
        const toPage = `${baseUrl}/${progressStore.pageName}${searchParams}`;
        const page_from_to = `${fromPage} -> ${toPage}`;

        console.log('📊 Tracking page navigation:', { 
          fromPage: fromPage,
          toPage: toPage 
        });
        aiInstance?.trackEvent({
          name: 'Application Form - Navigation',
          properties: {
            event_category: 'Application Form',
            event_action: 'Navigation',
            event_label: page_from_to
          }
        });
        // alert("uncomment below : " + progressStore.previousPage + " , " + progressStore.pageName)
        tealium.trackPageView({
            page_name: progressStore.pageName
        });
      });
    }

    // Track store errors
    onError((error: unknown) => {
      if (!(error instanceof StoreError)) return;
      
      const storeError: StoreError = error;
      console.log('❌ Tracking store error:', {
        type: storeError.type,
        message: storeError.message,
        store: storeError.store,
        action: storeError.action
      });

      const isValidationError = storeError.type === StoreErrorType.VALIDATION;
      const isNetworkError = storeError.type === StoreErrorType.NETWORK;

      aiInstance?.trackEvent({
        name: isValidationError 
          ? 'Application Form - Validation Error'
          : isNetworkError
            ? 'Application Form - Network Error'
            : 'Application Form - Submit Error',
        properties: {
          event_category: 'Application Form',
          event_action: isValidationError 
            ? 'Validation Error' 
            : isNetworkError
              ? 'Network Error'
              : 'Submit Error',
          event_label: storeError.message,
          store: storeError.store,
          action: storeError.action,
          errorType: storeError.type,
          source: isValidationError ? 'frontend' : 'backend'
        }
      });
    });

    // Add tracking for form completion
    if (store.$id === 'progress' && name === 'completeForm') {
      after(() => {
        const applicationStore = useApplicationStore();
        const formStore = useFormStore();
        const option = RequestHelper.getQueryVariable('option');
        
        console.log('📊 Tracking form completion:', {
          GUID: configStore.GUID,
          referrer: applicationStore.referrer,
          option: option,
          submissionTypeId: formStore.submissionTypeId
        });
        
        
        aiInstance?.trackEvent({
          name: 'Application Form - Completed',
          properties: {
            event_category: 'Application Form',
            event_action: 'Completed',
            event_label: configStore.GUID,
            referrer_url: applicationStore.referrer.url,
            referrer_source: applicationStore.referrer.source,
            referrer_timestamp: applicationStore.referrer.timestamp,
            option_type: option || 'none',
            submission_type_id: formStore.submissionTypeId
          }
        });
      });
    }

    // Add tracking for partial completion
    if (store.$id === 'application' && name === 'submitPartialApplication') {
      after(() => {
        const applicationStore = useApplicationStore();
        
        console.log('📊 Tracking partial application submission:', {
          GUID: configStore.GUID,
          referrer: applicationStore.referrer
        });
        
        aiInstance?.trackEvent({
          name: 'Application Form - Partial Submission',
          properties: {
            event_category: 'Application Form',
            event_action: 'Partial Submission',
            event_label: configStore.GUID,
            referrer_url: applicationStore.referrer.url,
            referrer_source: applicationStore.referrer.source,
            referrer_timestamp: applicationStore.referrer.timestamp
          }
        });
      });
    }
  });
} 